<template>
  <b-row>
    <b-col v-bind="container" class="panel-wrapper">
      <section>
        <b-row>
          <b-col>
            <slot name="header"> </slot>
          </b-col>
        </b-row>
      </section>
      <slot></slot>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'BaseContainer',
  data() {
    return {
      container: {
        cols: 12,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 4,
        offsetMd: 3,
        offsetLg: 3,
        offsetXl: 4,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@docupet/component-library/src/assets/styles/_variables';
.panel-wrapper {
  background-color: $dp-white;
  padding: 3rem;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .panel-wrapper {
    border-radius: 8px;
    box-shadow: 1px 2px 3px $dp-shadow-1;
  }
}
</style>
