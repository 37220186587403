import BaseModel from './BaseModel';
import JurisdictionBoundary from '../models/JurisdictionBoundary';

export default class JurisdictionBoundaries extends BaseModel {
  constructor(includedBoundaries, excludedBoundaries) {
    super();

    /** @type {JurisdictionBoundary[]} **/
    this.includedBoundaries = includedBoundaries;

    /** @type {JurisdictionBoundary[]} **/
    this.excludedBoundaries = excludedBoundaries;
  }

  static fromGQL(incoming) {
    if (typeof incoming !== 'object' || incoming === null) {
      return;
    }

    let includedBoundaries = [];
    let excludedBoundaries = [];

    if (Array.isArray(incoming.includedBoundaries)) {
      includedBoundaries = incoming.includedBoundaries.map(b => JurisdictionBoundary.fromGQL(b));
    }

    if (Array.isArray(incoming.excludedBoundaries)) {
      excludedBoundaries = incoming.excludedBoundaries.map(b => JurisdictionBoundary.fromGQL(b));
    }

    return new this(
      includedBoundaries,
      excludedBoundaries
    );
  }

  /** @returns {JurisdictionBoundary[]|null} */
  getIncludedBoundaries() {
    return this.includedBoundaries;
  }

  /** @param {JurisdictionBoundary[]} includedBoundaries **/
  setIncludedBoundaries(includedBoundaries) {
    this.validateBoundariesInput(includedBoundaries);

    this.includedBoundaries = includedBoundaries;
  }

  /** @returns {JurisdictionBoundary[]|null} */
  getExcludedBoundaries() {
    return this.excludedBoundaries;
  }

  /** @param {JurisdictionBoundary[]} excludedBoundaries **/
  setExcludedBoundaries(excludedBoundaries) {
    this.validateBoundariesInput(excludedBoundaries);

    this.excludedBoundaries = excludedBoundaries;
  }

  /** @returns {JurisdictionBoundary[]} **/
  getBoundaries() {
    return this.includedBoundaries.concat(this.excludedBoundaries);
  }

  /**
   * @private
   * @param {JurisdictionBoundary[]} input
   **/
  validateBoundariesInput(input) {
    if (!Array.isArray(input)) {
      throw new TypeError('Excluded Jurisdictions must be an array');
    }

    input.forEach(jurisdiction => {
      if (!(jurisdiction instanceof JurisdictionBoundary)) {
        throw new TypeError('Included Jurisdiction must be an instance of JurisdictionBoundary');
      }
    });
  }
}
