<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <BaseFetchMyRecord current-screen="ByAccessCode">
        <DpBaseInput
          id="email-input"
          v-model.trim="email"
          name="email"
          type="email"
          :autofocus="true"
          autocomplete="username"
          :label="'input.email.label' | translate"
          :validation-state="!hasErrors"
          :validation-rules="validationRulesToApply"
          :validation-errors="hasErrors ? getErrorMessage : null"
          :placeholder="'input.email.placeholder' | translate"
        />

        <template #footerMessage>
          <div v-if="hasErrors && getErrors != 'access_code_used'" class="bg-info mt-n4 mb-4 p-3 py-4">
            <p>
              We can't find that email address in our records. This information has likely not been provided to us previously. Please ensure
              that you have entered the information correctly.
            </p>
            <p>
              You may <strong>try using your pet's current license tag number</strong> to access your record
              <b-link :to="{ name: 'FetchMyRecordByTagNumber', query: $router.currentRoute.query }" class="text-decoration-underline">here</b-link>.
            </p>
            <p v-html="getCustomCareSupportText" />

            <p>
              Learn more about how we source existing records <b-link v-b-modal.modal-how-we-source-existing-records-info class="text-decoration-underline">here</b-link>.
            </p>
          </div>
          <div v-else-if="isSuccess" class="bg-info mt-n4 mb-4 p-3">
            <p class="m-0">
              We have sent you an email with your access code. You can now fetch your record using your access code.
            </p>
          </div>
        </template>

        <template #footer>
          <b-button class="w-100" type="submit" variant="primary" :disabled="!valid || isLoading || validationRulesToApply.emailAddressNotFound && email === lastAttemptedEmail">
            {{ isSuccess ? 'Fetch my Record by Access Code' : 'requestAccessCode.buttonText' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>

          <b-row v-if="!isSuccess" align-h="center" class="mt-4">
            <b-link :to="{ name: 'FetchMyRecordByAccessCode' }" v-text="'Fetch my Record by Access Code'" class="text-decoration-underline" />
          </b-row>
        </template>
      </BaseFetchMyRecord>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput } from '@docupet/component-library';

export default {
  name: 'RequestAccessCode',
  components: {
    DpBaseInput,
  },
  data: () => ({
    email: '',
    lastAttemptedEmail: '',
  }),
  computed: {
    ...mapGetters('city', ['getCityCustomerSupportContext']),
    ...mapGetters('accessCode', ['isLoading', 'isSuccess', 'getErrors', 'hasErrors']),
    contentsBlank: (self = this) => self.email == '',
    getErrorMessage: function () {
      switch (this.getErrors) {
        case 'invalid_email':
          return '';
        case 'access_code_used':
          return {
            path: 'requestAccessCode.errorMessages.access_code_used',
            action: {
              to: this.contentsBlank ? { name: 'LogIn' } : { name: 'LogIn', query: { email: this.email } },
              text: this.$t('requestAccessCode.errorMessages.action.access_code_used'),
            },
          };
        default:
          return this.$t('requestAccessCode.errorMessages.default');
      }
    },
    validationRulesToApply() {
      let ruleObject = { required: true, email: true };
      if (this.lastAttemptedEmail && this.hasErrors && this.getErrors != 'access_code_used') {
        ruleObject.emailAddressNotFound = [this.lastAttemptedEmail, "We can't find that email address in our records."];
      }
      return ruleObject;
    },
    getCustomCareSupportText: function () {
      return this.getCityCustomerSupportContext?.customCareSupportText?.text;
    },
  },
  mounted: function () {
    this.$store.commit('accessCode/CLEAR');
  },
  methods: {
    ...mapActions('accessCode', ['sendAccessCodeEmail']),
    async onSubmit() {
      this.lastAttemptedEmail = this.email;
      if (this.isSuccess) {
        await this.$router.push({ name: 'FetchMyRecord', query: this.$router.currentRoute.query });
      } else {
        await this.sendAccessCodeEmail(this.email);
      }
    },
  },
};
</script>

<style scoped></style>
