import { pluginFactory } from 'bootstrap-vue/src/utils/plugins';
export { installFactory } from 'bootstrap-vue/src/utils/plugins';

import {
  ButtonPlugin,
  CardPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  ImagePlugin,
  LayoutPlugin,
  LinkPlugin,
  ModalPlugin,
  TabsPlugin,
  NavbarPlugin,
  FormGroupPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  SpinnerPlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue';

export const componentsPlugin = pluginFactory({
  plugins: {
    ButtonPlugin,
    CardPlugin,
    FormPlugin,
    FormCheckboxPlugin,
    FormInputPlugin,
    ImagePlugin,
    LayoutPlugin,
    LinkPlugin,
    ModalPlugin,
    TabsPlugin,
    NavbarPlugin,
    FormGroupPlugin,
    FormRadioPlugin,
    FormSelectPlugin,
    FormTextareaPlugin,
    SpinnerPlugin,
    VBTooltipPlugin,
  },
});
