import axios from 'axios';
export const BASE_API_URL = global.API_URL;
export const DEFAULT_TIMEOUT = 3 * 6000; // 3 minutes

export const DEFAULT_CONFIG = {
  baseURL: BASE_API_URL,
  timeout: DEFAULT_TIMEOUT,
};
export const DEFAULT_STORE_OPTIONS = {
  tokenState: 'token', // the name of the vuex state property that holds the auth token
};

export const REST = axios.create({ ...DEFAULT_CONFIG });

// Configure axios to use the $store for auth tokens or dispatching actions
export function init($store = null, config = DEFAULT_CONFIG, $storeOptions = DEFAULT_STORE_OPTIONS) {
  REST.defaults = Object.assign(REST.defaults, { ...DEFAULT_CONFIG, ...config });

  REST.interceptors.request.use(config => {
    if (!$store || !$store.state || !$storeOptions || !$storeOptions.tokenState || !$store.state[$storeOptions.tokenState]) {
      return config;
    }

    const authToken = $store.state[$storeOptions.tokenState];

    if (authToken) {
      config.headers['Authorization'] = authToken;
    }

    return config;
  });
}
