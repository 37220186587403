<template>
  <div style="background-color: #F7F7F7;" class="language-header d-flex justify-content-end align-items-center mx-4" :class="{'min-height' : showHeader}">
    <!--    COUNTRY TOGGLE    -->
    <div v-if="selectedCountry && areaType === 'Country'" class="mr-3">
      <div id="countrySelect" class="d-flex align-items-center">
        <img v-if="selectedCountry === 'US'" style="width: 2.3em;" src="./assets/us.svg" alt="" />
        <img v-else style="width: 2.3em;" src="./assets/ca.svg" alt="" />
        <div class="ml-2">
          <fa-icon :icon="['fas', 'chevron-down']" />
        </div>
      </div>
      <b-popover ref="countryPopover" custom-class="country-popover" target="countrySelect" delay="5" trigger="click blur" placement="bottomRight" html="true">
        <b-form-group class="my-0 py-3 pr-4">
          <b-form-radio v-model="selectedCountry" class="mb-2" size="lg" value="US">
            <h2>United States</h2>
          </b-form-radio>
          <b-form-radio v-model="selectedCountry" size="lg" value="CA">
            <h2>Canada</h2>
          </b-form-radio>
        </b-form-group>
        <div class="mx-4 mb-3">Changing the country region may change pricing and availability.</div>
      </b-popover>
    </div>

    <!--    LANGUAGE SELECT    -->
    <h3 v-if="locales.length > 1" class="ml-5 mr-3 my-0 font-weight-bold" style="cursor: pointer;" @click="toggleLanguage">
      {{ language }}
    </h3>

    <!--    GOOGLE TRANSLATE    -->
    <div id="google_translate_element" class="ml-5 mr-3" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { icons } from './assets/fontawesome.js';
library.add(icons);

export default {
  name: 'DpLanguageSelect',
  data: function () {
    return {
      testingEnv: window.location.origin.includes('localhost'),
      mockData: {
        windowOrigin: 'https://us.docupet.com',
        windowHREF: 'https://us.docupet.com/en_US/homesafe',
      },
      selectedCountry: null,
      googleTranslateSrc: '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
      googleWidgetDisplayed: false,
    }
  },
  props: {
    areaType: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    }
  },
  computed: {
    language() {
      switch (this.currentLocale()) {
        case 'en_US':
          return 'English';
        case 'es_US':
          return 'Español';
        case 'fr_CA':
          return 'Français';
        default:
          return 'English';
      }
    },
    showHeader() {
      return this.locales.length > 1 || (this.selectedCountry && this.areaType === 'Country') || this.googleWidgetDisplayed
    }
  },
  methods: {
    displayGoogleTranslate() {
      if (window.google && !this.googleWidgetDisplayed) {
        this.googleWidgetDisplayed = true;
        window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
      }
    },
    isGoogleTranslateScriptAppended() {
      let scripts = document.getElementsByTagName('script');
      let includesScript = false;
      for (let i = scripts.length; i--; ) {
        if (scripts[i].src.includes(this.googleTranslateSrc)) {
          includesScript = true;
        }
      }
      return includesScript;
    },
    appendGoogleTranslateScript() {
      if (!this.isGoogleTranslateScriptAppended()) {
        let googleTranslateScript = document.createElement('script');
        googleTranslateScript.setAttribute('src', this.googleTranslateSrc);
        document.head.appendChild(googleTranslateScript);
      }
    },
    checkCountryInURL() {
      let url = this.testingEnv ? this.mockData.windowOrigin : window.location.origin;
      if (url.includes('us.docupet.com')) {
        this.selectedCountry = 'US';
      } else {
        this.selectedCountry = 'CA';
      }
    },
    currentLocale() {
      if (this.locale) {
        return this.locale;
      }
      let url = this.testingEnv ? this.mockData.windowHREF : window.location.href;
      if (url.includes('en_CA')) return 'en_CA';
      if (url.includes('en_US')) return 'en_US';
      if (url.includes('es_US')) return 'es_US';
      if (url.includes('fr_CA')) return 'fr_CA';
    },
    toggleLanguage() {
      let origin = this.testingEnv ? this.mockData.windowOrigin : window.location.origin;
      let href = this.testingEnv ? this.mockData.windowHREF : window.location.href;
      let extension = href.split(this.currentLocale())[1];
      let indexOfCurrent = this.locales.indexOf(this.currentLocale());
      let nextLocale = this.locales[indexOfCurrent + 1]
          ? this.locales[indexOfCurrent + 1]
          : this.locales[0];
      window.location.href = `${origin}/${nextLocale}${extension}`;
    }
  },
  watch: {
    selectedCountry: function(val, old) {
      if (!old) return;
      this.$refs.countryPopover.$emit('close')
      let href = this.testingEnv ? this.mockData.windowHREF : window.location.href;
      let extension = href.split(this.currentLocale())[1];
      if (val === 'US') {
        window.location.href = 'https://us.docupet.com/en_US' + extension;
      } else {
        window.location.href = 'https://ca.docupet.com/en_CA' + extension;
      }
    },
  },
  mounted() {
    this.checkCountryInURL();
    this.appendGoogleTranslateScript();
    this.intervalFunction = window.setInterval(() => {
      if (window.google) {
        this.displayGoogleTranslate();
        window.clearInterval(this.intervalFunction);
      }
    }, 250);
  }
};
</script>

<style lang="scss">
  .country-popover .arrow {
    display: none;
  }
  .language-header.min-height {
    min-height: 3.5em;
  }
</style>
