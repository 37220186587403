<template>
  <ValidationProvider :name="name" :rules="rules">
    <template #default="{touched, invalid, errors}">
      <b-form-group :id="`${name}_textarea_group`" class="text-dark" :invalid-feedback="errors[0]" :label="label" :label-for="`${name}_textarea`">
        <b-form-textarea
          :id="`${name}_textarea`"
          v-model="input"
          :class="touched && invalid ? 'is-invalid' : ''"
          :rows="rows"
          :max-rows="maxRows"
          @keydown="preventSpecificCharacters($event)"
        ></b-form-textarea>
      </b-form-group>
    </template>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseTextArea',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: String,
      default: () => '',
    },
    rows: {
      type: Number,
      default: () => 5,
    },
    maxRows: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      rules: this.required ? 'required' : '',
    };
  },
  methods: {
    preventSpecificCharacters(e) {
      if (e.key === '/') {
        e.preventDefault();
      }
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(input) {
        this.$emit('input', input);
      },
    },
  },
};
</script>

<style></style>
