<template>
  <b-row class="custom-form-element">
    <b-col class="panel-content">
      <div v-if="label" class="text-dark mb-2">
        {{ label }}
      </div>

      <b-row class="d-flex">
        <b-col class="d-flex flex-wrap justify-content-center">
          <div v-for="(c, key) in colors" :key="key" class="mx-1 text-center color-picker-custom-class">
            <color-bubble :key="key" :ref="c.name" :name="c.name" :hex="c.hex" class="bubble-container no-halo" @input="colorClicked(c)" />
            <p
              class="text-dark"
              data-toggle="tooltip"
              data-placement="auto"
              :title="`${c.name} colour`"
              style="cursor: pointer; "
              @click="$refs[c.name][0].$el.click()"
            >
              <small>{{ c.name }}</small>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ColorBubble from './ColorBubble';

export default {
  name: 'ColorPicker',
  components: {
    ColorBubble,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data: function() {
    return {
      selectedColors: [],
    };
  },
  methods: {
    colorClicked(c) {
      let i = this.selectedColors.indexOf(c.name);

      if (i >= 0) {
        this.selectedColors.splice(i, 1);
      } else {
        this.selectedColors.push(c.name);
      }

      this.$emit('input', this.selectedColors);
    },
  },
};
</script>

<style scoped>
.bubble-container {
  margin: 5px;
  padding: 0;
  text-align: center;
}
</style>
