<template>
  <div class="d-inline-block avatar-wrapper">
    <div v-if="loading" class="avatar-background" role="status">
      <b-spinner></b-spinner>
      <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
    </div>
    <b-button
      v-else-if="avatar"
      class="avatar-background"
      variant="outline"
      :style="`background-image: url(${avatar})`"
      :aria-label="`Choose Avatar ${$vnode.key}`"
      @click="selectAvatar(image)"
      @mouseenter="showRemoveButton = true"
      @mouseleave="showRemoveButton = false"
    >
      <fa-icon
        v-if="removable"
        size="lg"
        class="fa-lg remove-bubble"
        :icon="['fad', 'times-circle']"
        :class="{ hidden: !showRemoveButton }"
        @click="removeAvatar(image)"
      />
    </b-button>
    <b-img v-else :src="pawAvatar" class="avatar-image" alt="" />
  </div>
</template>

<script>
import { getImageUtil } from '../../../utils/getImageUtil';
import pawAvatar from '../../../assets/images/paw-avatar.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';

library.add(faTimesCircle);

export default {
  name: 'BaseAvatarImage',
  props: {
    image: {
      type: Object,
      default: null,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    removable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showRemoveButton: false,
      pawAvatar: pawAvatar,
    };
  },
  computed: {
    avatar() {
      return this.image?.original ? getImageUtil(this.image.original) : null;
    },
  },
  methods: {
    removeAvatar(image) {
      this.$emit('removeAvatar', image);
    },
    selectAvatar(image) {
      this.$emit('selectAvatar', image);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/_variables';
.avatar-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid $dp-gray-9;
}
.avatar-background {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 1px solid $dp-gray-9;
  position: relative;
  cursor: pointer;
}
.spinner-border {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.remove-bubble {
  position: absolute;
  color: $dp-black;
  cursor: pointer;
  top: 0;
  left: 0;
  &:hover {
    color: $dp-red;
    border-radius: 50%;
    border: $dp-black;
  }
  &.hidden {
    display: none;
  }
}
</style>
