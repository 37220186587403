export function getInitialState() {
  return {
    loading: false,
    neverLoaded: true,
    data: null,
    error: null,
  };
}

function resolveAfterDelay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function fetchData(data, id) {
  const delay = 3000 * Math.random();

  await resolveAfterDelay(delay);

  if (id) {
    const found = data.filter((item) => item.id.toString() === id.toString());

    if (found.length) {
      return found[0];
    } else {
      throw Error('Not Found');
    }
  } else {
    return data;
  }
}

export async function updateData(data, id, mutation) {
  const delay = 1000 * Math.random();
  const found = await fetchData(id);

  mutation(found);

  await resolveAfterDelay(delay);

  return found;
}

export const prepareQuery = (query) => {
  return query
    .replace(/[\t]/gmu, '') // Remove tabs
    .replace(/ {2,}/gmu, '') // Remove spaces
    .replace(/\n/gmu, ' '); // Change newlines into spaces
};

export function gql(strings, ...values) {
  const raw = strings.reduce((result, current, index) => {
    return `${result}${current}${values[index] || ''}`;
  }, '');

  return prepareQuery(raw);
}

export function recursiveSearch(state, term, keys = []) {
  for (let key in state) {
    if ((!keys.length || keys.find((k) => k === key)) && Object.prototype.hasOwnProperty.call(state, key) && key === term)
      return state[key];
  }

  for (let key in state) {
    if (
      (!keys.length || keys.find((k) => k === key)) &&
      Object.prototype.hasOwnProperty.call(state, key) &&
      typeof state[key] === 'object'
    ) {
      return recursiveSearch(state[key], term);
    }
  }
}
