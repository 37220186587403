import { isEmpty } from 'lodash';
import AccessCodeService from '@/api/services/AccessCodeService';

export const namespaced = true;

export const state = {
  error: null,
  loading: false,
  response: null,
  _attempts: {
    count: 1,
  },
};

export const getters = {
  getErrors: ({ error }) => error,
  isLoading: ({ loading }) => loading,
  _attemptCount: (state) => state._attempts.count,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isSuccess: ({ hasErrors, response }) => !hasErrors && !isEmpty(response),
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  RESET_ERROR(state) {
    state.error = null;
    state._attempts.count = 1;
  },
  SUCCESS(state, response) {
    state.error = null;
    state.loading = false;
    state.response = response;
    state._attempts.count = 1;
  },
  FAILURE(state, error) {
    state.error = error;
    state.response = null;
    state.loading = false;
    state._attempts.count++;
  },
  CLEAR(state) {
    state.error = null;
    state.response = null;
    state.loading = false;
    state._attempts.count = 1;
  },
};

export const actions = {
  sendAccessCodeEmail: ({ commit }, email) => {
    commit('LOADING', true);
    AccessCodeService.sendAccessCodeEmail(email)
      .then((response) => {
        commit('SUCCESS', response);
      })
      .catch(({ error }) => {
        commit('FAILURE', error.message?.[0]?.message || error.message);
      });
  },
};
