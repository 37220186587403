<template>
  <ValidationProvider ref="validationProvider" v-slot="validationContext" name="file" :rules="`document|size:${maxSize}`" :vid="vid">
    <b-form-group
      id="document-upload-input-group"
      :label="label"
      :state="getValidationState(validationContext)"
      label-for="document-upload-button"
      aria-describedby="file-invalid-feedback"
    >
      <b-button
        id="document-upload-button"
        block
        :variant="disabled ? 'primary' : 'outline-primary'"
        :disabled="disabled"
        @click="$refs.file.click()"
      >
        <slot name="button">
          {{ button }}
        </slot>
      </b-button>
      <input
        id="document-upload-input"
        ref="file"
        type="file"
        accept=".gif,.png,.jpg,.jpeg,.bmp,.webp,.tiff,.tif,.pdf,.heic"
        class="is-invalid d-none"
        :disabled="disabled"
        aria-hidden="true"
        @click="$event.target.value = null"
        @change="handleFileChange($event, validationContext)"
      />
      <i18n
        v-if="hasErrorProperty(validationContext.errors[0], 'path')"
        id="file-invalid-feedback"
        :path="validationContext.errors[0].path"
        tag="div"
        class="invalid-feedback"
        role="alert"
      >
        <template #br>
          <br />
        </template>

        <template v-if="hasErrorProperty(validationContext.errors[0], 'action')" #action>
          <b-link :to="getActionMessage(validationContext.errors[0].action).to" class="font-weight-bold">
            {{ getActionMessage(validationContext.errors[0].action).text }}
          </b-link>
        </template>
      </i18n>
      <b-form-invalid-feedback v-else id="file-invalid-feedback" role="alert">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseFileUpload',
  props: {
    vid: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      required: false,
      default: 8192,
    },
    button: {
      type: String,
      default: 'Upload a file',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: [String, Object],
      default: '',
    },
  },
  watch: {
    validationErrors: function(value) {
      if (value) this.$refs.validationProvider.errors.push(value);
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    hasErrorProperty(context, property) {
      return context?.[property] ?? false;
    },
    getActionMessage(action) {
      if (action) {
        return {
          to: action?.to || null,
          text: action?.text || '',
        };
      }
      return null;
    },
    async handleFileChange(e) {
      const response = await this.$refs.validationProvider?.validate(e);

      if (!response?.valid) {
        return;
      }

      const files = e.target.files;

      if (files && files.length) {
        this.$emit('upload', files[0]);
      }
    },
  },
};
</script>

<style></style>
