<template>
  <b-form-group :id="`radio-input-${id}-group`" :label="label" :label-for="`radio-input-${id}`">
    <validation-provider v-slot="validationContext" name="radio-input" :rules="validationRules">
      <b-form-radio-group
        :id="`radio-input-${id}`"
        v-bind="$attrs"
        :aria-describedby="`radio-input-${id}-invalid-feedback`"
        v-on="$listeners"
      >
        <b-form-radio v-for="option in options" :key="option.text" v-model="selected" name="radio-input" :value="option.value">
          <span v-if="option.html" v-html="option.html"></span>
          <span v-else>{{ option.text }}</span>
        </b-form-radio>
      </b-form-radio-group>
      <b-form-invalid-feedback :id="`radio-input-${id}-invalid-feedback`" :state="getValidationState(validationContext)" role="alert">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </validation-provider>
  </b-form-group>
</template>

<script>
export default {
  name: 'BaseRadioInput',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [Boolean, String],
      default: false,
    },
    validationRules: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    selected: {
      get: ({ value } = this) => value,
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<style scoped></style>
