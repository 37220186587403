import { isEmpty } from 'lodash';
import CompleteSignupService from '@/api/services/CompleteSignupService';

export const namespaced = true;

export const state = {
  error: null,
  loading: false,
  response: null,
  petOwner: null,
  redirect_uri: null,
  utm_query_params: null,
  _attempts: {
    count: 1,
  },
};

export const getters = {
  getErrors: ({ error }) => error,
  isLoading: ({ loading }) => loading,
  _attemptCount: ({ _attempts }) => _attempts.count,
  getPOEmailAddress: ({ petOwner }) => petOwner?.email,
  getPOFirstName: ({ petOwner }) => petOwner?.firstName,
  getRedirectUri: ({ redirect_uri }) => redirect_uri,
  getUtmQueryParams: ({ utm_query_params }) => utm_query_params,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isLoaded: ({ loading, petOwner }) => !loading && !isEmpty(petOwner),
  isSuccess: ({ response, hasErrors }) => !hasErrors && !isEmpty(response),
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  RESET_ERROR(state) {
    state.error = null;
  },
  SUCCESS(state, { petOwner = null, response = null }) {
    state.error = null;
    state.loading = false;
    state._attempts.count = 1;
    if (petOwner) state.petOwner = petOwner;
    if (response) state.response = response;
  },
  FAILURE(state, error) {
    state.error = error;
    state.petOwner = null;
    state.loading = false;
    state._attempts.count++;
  },
  CLEAR(state) {
    state.error = null;
    state.petOwner = null;
    state.loading = false;
    state._attempts.count = 1;
  },
  SET_REDIRECT(state, redirect_uri = null) {
    state.redirect_uri = redirect_uri;
  },
  CLEAR_REDIRECT(state) {
    state.redirect_uri = null;
  },
  SET_REDIRECT_QUERY_PARAMS(state, utm_query_params = null) {
    state.utm_query_params = utm_query_params;
  },
  CLEAR_REDIRECT_QUERY_PARAMS(state) {
    state.utm_query_params = null;
  },
};

export const actions = {
  fetchPetOwner: ({ commit }) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      CompleteSignupService.fetchPetOwner()
        .then((petOwner) => {
          if (petOwner) {
            commit('SUCCESS', { petOwner });
            if (petOwner.locale) commit('SET_LOCALE', petOwner.locale, { root: true });
            commit('SET_CITY_LOGO_URL', petOwner.city?.logo?.original, { root: true });
            resolve(petOwner);
          } else reject();
        })
        .catch(({ error }) => {
          commit('FAILURE', error.message?.[0]?.message || error.message);
          reject();
        });
    }),
  completeSignup: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      CompleteSignupService.completeSignup(payload)
        .then((token) => {
          if (token) {
            commit('SUCCESS', { petOwner: null, response: token });
            commit('SET_TOKEN', token, { root: true });
            resolve(token);
          } else reject();
        })
        .catch(({ error }) => {
          commit('FAILURE', error.message?.[0]?.message || error.message);
          reject();
        });
    }),
};
