<template>
  <div v-if="temperaments">
    <ValidationProvider v-slot="{ errors, valid }" ref="validationProvider" name="pet-temperaments" :rules="validationRules">
      <b-form-group label-for="pet-temperament-group">
        <label class="h3 text-left" for="pet-temperament-group">
          <template v-if="selected.length < max">
            <span class="text-dark ">{{ $t('dp.petTemperament.counter', { counter: max - selected.length }) }}</span>
          </template>
          <template v-else>
            <span :class="!valid || errors[0] ? 'text-danger' : 'text-success'">
              {{ $t('dp.petTemperament.selection', { max: max, selected: selected.length }) }}
            </span>
          </template>
        </label>
        <b-form-checkbox-group
          id="pet-temperament-group"
          v-model="selected"
          name="pet-temperament-group"
          stacked
          class="text-left"
          :size="size"
          @input="selectTemperaments"
        >
          <b-row class="flex-column flex-md-row justify-content-start">
            <b-col v-for="(temperaments, group) in groupedTemperaments" :key="group" cols="12" sm="6">
              <b-form-checkbox
                v-for="(temperament, key) in temperaments"
                :key="key"
                :value="temperament.value"
                :disabled="disableCheckbox(temperament.value)"
                class="mb-3"
              >
                <span :class="selectedTemperamentClass(temperament.value)">{{ temperament.text }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'PetTemperament',
  props: {
    temperaments: {
      type: Array,
      required: false,
      default: null,
    },
    max: {
      type: Number,
      required: false,
      default: 6,
    },
    size: {
      type: String,
      default: 'lg',
      required: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    maxSelection() {
      return this.selected.length <= this.max;
    },
    validationRules() {
      return { length: this.maxSelection ? false : this.max };
    },
    groupedTemperaments() {
      const size = this.temperaments.length > 10 ? Math.round(this.temperaments.length / 2) : this.temperaments.length;
      // Create array into 2 chunks for column layout
      return this.temperaments.reduce((arr, item, idx) => {
        return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
      }, []);
    },
  },
  methods: {
    selectedTemperamentClass(temperament) {
      return this.selected.includes(temperament) ? 'text-primary' : 'text-dark';
    },
    disableCheckbox(temperament) {
      return this.selected.length > this.max && !this.selected.includes(temperament);
    },
    selectTemperaments(val) {
      if (this.maxSelection) {
        this.$emit('selectedTemperaments', val.sort());
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
