export default {
  dp: {
    footer: {
      copyright: 'DocuPet Corp. All rights reserved.',
      tos: 'Terms of Service',
      privacy: 'Privacy Policy',
    },
    links: {
      opensInNewTab: '(Opens in a new tab)',
    },
    navbar: {
      toggle: {
        close: 'Close',
        default: 'Menu',
      },
      tagStore: 'Tag Store',
      shop: 'Shop',
      tagGallery: 'Tag Gallery',
      safeAndHappyFund: 'Safe&Happy Fund',
      licensing: 'Licensing',
      store: 'Store',
      about: 'About DocuPet',
      licensingInfo: 'About New Licenses',
      faq: 'FAQ',
      aboutRenewing: 'About Renewing Licenses',
      signup: 'Sign Up',
      login: 'Log In',
      dashboard: 'My Dashboard',
      myDetails: 'My Details',
      myAccount: 'My Account',
      logOut: 'Log Out',
      yourAccount: 'Account',
      myPets: 'My Pets',
      profileSettings: 'Profile Settings',
      billingDetails: 'Payment and Subscription',
      orderHistory: 'Order History',
      helpDesk: 'Help Center',
      logoDescription: 'DocuPet - A Safe and Happy Home for every pet',
      skipToMainContent: 'Skip to main content',
      basket: {
        ariaLabel: 'Shopping Basket ({basketCount} items)',
      },
      petGuardians: 'Pet Guardians',
    },
    addAddress: {
      manualAddressHeader: 'Add Address',
      button: 'Add Address',
    },
    errorMessages: {
      poBoxOnly:
        'We require a physical address for your profile in the event that your pet becomes lost and needs to be returned to you. You may add an additional mailing address by unchecking the box below.',
      invalidCharacters: 'The {field} has invalid characters',
    },
    input: {
      firstName: {
        label: 'First Name',
        placeholder: 'Enter first name',
        customErrorMessage: { isEmpty: 'Your first name is required' },
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'Enter last name',
        customErrorMessage: { isEmpty: 'Your last name is required' },
      },
      email: {
        label: 'Email address',
        placeholder: 'Enter email address',
        customErrorMessage: {
          isEmpty: 'Please enter your email address',
          hasValue: 'Please enter a valid email address',
        },
        asyncErrorMessage: {
          emailAlreadyExists: `<strong>This email address already exists in our system.</strong><br/>If this is your account, please click <a class="text-decoration-underline" href="{loginLink}">here</a> to log in. Otherwise, please double check you're entering the correct email address.`,
          accountAlreadyExists: `<strong>This email address already exists in our system.</strong><br/>If you have previously registered a pet, we likely have your record on file. Visit the <a class="text-decoration-underline" href="{loginLink}">Fetch my record</a> page to continue.`,
        },
      },
      password: {
        label: 'Create password',
        placeholder: 'Create password',
        customErrorMessage: {
          isEmpty: 'Please enter a password',
          hasValue: 'Your password does not meet the requirements listed below',
        },
        show: 'Hide Password',
        hide: 'Show Password',
      },
      agreeToTermsConditions: {
        label: 'I agree',
        customErrorMessage: 'Please read and agree to the Terms of Service to proceed',
      },
      agreeToMovingJurisdiction: {
        customErrorMessage: 'Please select this field to continue with moving the pet owner to a new jurisdiction',
      },
      addressSearch: {
        label: 'Search for your {selectedType}',
        placeholder: 'Start typing to search for {selectedType}',
        placeholderAddress: 'address',
        customErrorMessage: 'Please provide your address',
      },
      unit: {
        label: 'Unit / Apt / Suite',
        placeholder: '',
        customErrorMessage:
          'The unit / apt / suite field may only contain alpha-numeric characters, spaces and periods (e.g. Ste. 4, Apt. B).',
      },
      civic: {
        label: 'Number',
        placeholder: '',
        customErrorMessage: 'Please enter your address number',
      },
      street: {
        label: 'Street',
        placeholder: 'Enter street name',
        customErrorMessage: 'Please enter your street name',
      },
      additional: {
        label: '',
        placeholder: 'Additional / PO Box',
      },
      city: {
        label: 'City',
        placeholder: 'Enter city name',
        customErrorMessage: 'Please enter your city name',
      },
      state: {
        label: 'State',
        placeholder: '',
        customErrorMessage: 'Please select your state',
      },
      province: {
        label: 'Province',
        placeholder: '',
        customErrorMessage: 'Please select your province',
      },
      postalCode: {
        label: 'Postal Code',
        placeholder: 'Enter postal code',
        customErrorMessage: {
          required: 'Please enter your postal code',
          invalid: 'Please enter a Postal Code with 6 letters and numbers',
        },
      },
      zipCode: {
        label: 'Zip Code',
        placeholder: 'Enter zip code',
        customErrorMessage: {
          required: 'Please enter your ZIP code',
          invalid: 'Please enter a ZIP Code with 5 numbers.',
        },
      },
      phoneNumber: {
        label: 'Phone Number',
        placeholder: 'Enter {phoneNumberType} phone number',
        mobilePhone: 'Mobile',
        homePhone: 'Home',
        workPhone: 'Work',
        customErrorMessage: {
          hasValue: 'Please enter your ten digit phone number',
          isEmpty: 'Please enter your phone number',
        },
      },
      birthday: {
        customErrorMessage: {
          month: {
            isEmpty: 'Please select a month',
          },
          day: {
            hasValue: 'Please enter a number between 1 and 31',
            isEmpty: 'Please enter a day',
          },
          year: {
            hasValue: 'Please enter 4 numbers for the year',
            isEmpty: 'Please enter a year',
          },
        },
      },
      dates: {
        monthOptions: {
          default: 'Select',
          jan: 'Jan',
          feb: 'Feb',
          mar: 'Mar',
          apr: 'Apr',
          may: 'May',
          jun: 'Jun',
          jul: 'Jul',
          aug: 'Aug',
          sept: 'Sept',
          oct: 'Oct',
          nov: 'Nov',
          dec: 'Dec',
        },
        errors: {
          birthdateMustBeInPast: 'Birthdates must be in the past',
          mustBeEighteen: 'You must be 18 years or older to purchase a license',
          rabiesVaccineExpirMustBeFuture: 'Rabies vaccination expiration dates must be in the future',
          twentyYears: "The rabies vaccination expiration date can't be more than 20 years in the future",
          between: 'Please enter a number between {minNum} and {maxNum}',
          minNumeric: 'Please enter at least {numLimit} numbers',
          maxNumeric: 'Please limit your input to {numLimit} numbers',
          min: 'Please enter at least {numLimit} characters',
          max: 'Please limit your input to {numLimit} characters',
        },
      },
    },
    petPanel: {
      aboutYourPet: 'Tell us about your pet',
      dontHaveAPet: "Don't have a pet?",
      skipThisStep: 'Skip this step',
      whatKindOfPet: 'What kind of pet do you have?',
      chooseOne: 'Choose one',
      petsName: "What is your pet's name?",
      petNameEditWarning:"Update your pet’s name if the spelling is incorrect or if you are changing the name of your pet. Don’t use this record for another pet as all of your pet’s information needs to match the details that are on official documents that may need to be provided for licensing.",
      whatBreed: 'What breed are they?',
      whatGender: 'What sex are they?',
      knowBirthDate: 'Do you know their date of birth?',
      approximateAge: 'Approximate Age',
      whatColor: 'What color are they? Select all that apply.',
      selectAll: 'Select all that apply',
      indoorCat: 'Indoor Cat',
      outdoorCat: 'Outdoor Cat',
      petCatOptionsRequired: 'Please let us know if {catName} is an indoor cat or an outdoor cat.',
      petSpeciesRequired: "Please select your pet's species",
      petNameRequired: "Please enter your pet's name",
      petBreedRequired: "Please enter your pet's breed",
      petSexRequired: "Please select your pet's sex",
      petAgeRequired: "Please provide your pet's age",
      petApproximateBirthdateRequired: "Please approximate your pet's age",
      petColorRequired: 'Please pick at least one color',
      petWithNameExists: 'A pet with that name already exists',
      namePlaceholder: 'Enter a name',
      breedPlaceholder: 'Enter breed',
      noMorePets: 'No more pets to add?',
      birthdate: 'Birthdate',
    },
    ageOptions: {
      underSixMonths: 'Under 6 months',
      sixMonthsToOneYear: '6 months - 1 year',
      oneYear: '1 year',
      timesYears: '{yearNumber} years',
      seventeenPlusYears: '17+ years',
    },
    colors: {
      white: 'White',
      lightGrey: 'Light-grey',
      darkGrey: 'Dark-grey',
      black: 'Black',
      orange: 'Orange',
      red: 'Red',
      cream: 'Cream',
      apricot: 'Apricot',
      golden: 'Golden',
      tan: 'Tan',
      brown: 'Brown',
      darkBrown: 'Dark-brown',
    },
    generic: {
      optional: 'Optional',
      yes: 'Yes',
      no: 'No',
      male: 'Male',
      female: 'Female',
      dog: 'Dog',
      cat: 'Cat',
      continue: 'Continue',
    },
    stripeForm: {
      cvcHeader: 'CVV/CVC Code Location Guide',
      cvcHelper:
        'The CVV/CVC code (Card Verification Value/Code) is located on the back of your credit/debit card on the right side of the white signature strip. It is always the last 3 digits in case of VISA and MasterCard. Please copy your CVV/CVC code from the back of your card and continue with your payment.',
      cvcHelperImageAlt: 'look for the 3-digit security code on the back of your card and the 4-digit security code on the front.',
      cardNumber: 'Card Number',
      expiryDate: 'Expiry',
      cvcNumber: 'CVC',
      country: 'Country',
      postalCode: 'ZIP',
      postalCodePlaceholder: '90210',
    },
    jurisdictionChange: {
      pleaseSelectFromDropdown:
        "We couldn't automatically select the licensing jurisdiction for your updated address. Please select a jurisdiction from the list below.",
      deactivateAccount: 'Deactivate Account',
    },
    imageCropper: {
      title: 'Crop Your Photo',
      cancel: 'Cancel',
      back: 'Back',
      applyCrop: 'Apply Crop',
      addToCard: 'Add to Card',
      error: 'Something went wrong, please try again',
    },
    petTemperament: {
      counter: 'Select up to {counter} more from the list below',
      selection: '{selected} out of {max} selected! Deselect 1 option to choose another.',
    },
    rabiesProofUploader: {
      label: 'Please provide the rabies vaccination proof for {petName}',
      pronoun: 'When does {pronoun}',
      expire: 'rabies vaccination expire?',
      subLabel: '@:dp.rabiesProofUploader.pronoun @:dp.rabiesProofUploader.expire',
      subLabelNew: '@:dp.rabiesProofUploader.pronoun new @:dp.rabiesProofUploader.expire',
      helpText: 'Please upload a JPG, BMP, PNG, TIF, HEIC, WEBP or PDF file that is smaller than 8MB.',
      notRequired: 'No proof required',
    },
    buttons: {
      remove: 'Remove',
      loading: 'Loading...',
      uploadProof: 'Upload Proof',
    },
    productNotFound: {
      backToStore: 'Back to Store',
      notFound: 'Ooops... product not found!',
      error: 'Ooops... something went wrong!',
      buttons: {
        viewAll: 'View All Products',
        refresh: 'Click to Refresh',
      },
    },
    requestAccessCode: {
      errorMessages: {
        access_code_used: "Looks like you've already signed up! {action}",
        default: 'There was a problem with your complete sign-up.{br}Please try again.',
        action: {
          access_code_used: 'Click here to Log In',
        },
      },
    },
    imageConversion: {
      errors: {
        default: 'Something went wrong, please try again',
        invalidType: 'Please upload a JPG, BMP, PNG, TIF, HEIC or WEBP file',
        tooLarge: 'Please upload a file smaller than 10MB',
        inconvertible: 'Something went wrong, please try different image',
      },
    },
    suggestedJurisdictions: {
      title: 'Tell us which community you live in',
      fallbackJurisdictionOptionLabel: 'None of the above',
    },
    securePasswordCheckList: {
      isSecure: 'Your password is secure!',
      oneLowerChar: 'One lowercase character (ex. "j", "p", "a")',
      oneUpperChar: 'One uppercase character (ex. "J", "P", "A")',
      oneDigitChar: 'One number (ex. "1", "5", "8")',
      oneSpecialChar: 'One special character (ex. "&", "#", "$")',
      min: '12 characters minimum',
    },
    error: {
      title: 'Ruh-roh!',
      body: 'Looks like something went wrong! If you keep getting this message, please let us know.',
    },
  },
};
