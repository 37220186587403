<template>
  <BasePanel :title="'login.title' | translate">
    <template #subheading>
      {{ 'login.subheading' | translate }}
      <router-link
        :to="{ name: 'SyfSignUp', query: { targetPath: getTargetPath, redirectPath: getRedirectPath, noLicensing: !!noLicensing } }"
        class="text-decoration-underline"
        v-text="$t('link.signUp')"
      />
    </template>

    <template #errors>
      <BaseDisplayMessage v-if="getErrorMessage" :message="getErrorMessage" class="text-danger" role="alert" />
    </template>

    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit">
        <DpBaseInput
          id="email-input"
          v-model.trim="email"
          name="email"
          type="email"
          autocomplete="username"
          validation-rules="required|email"
          :autofocus="true"
          :validation-state="!hasErrors"
          :force-error-state="forceFailedValidation"
          :label="'input.email.label' | translate"
          :placeholder="'input.email.placeholder' | translate"
        />

        <DpBaseInput
          id="password-input"
          v-model.trim="password"
          name="password"
          type="password"
          validation-rules="required"
          autocomplete="current-password"
          class="sentry-mask"
          :validation-state="!hasErrors"
          :force-error-state="forceFailedValidation"
          :label="'input.password.label' | translate"
          :placeholder="'input.password.placeholder' | translate"
        />

        <b-button type="submit" variant="primary" class="w-100 mt-2" :disabled="isLoading">
          {{ 'login.buttonText' | translate }}
          <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
            <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
            <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
          </div>
        </b-button>
      </form>
    </ValidationObserver>

    <template #footer>
      <b-row align-h="center">
        <b-link :to="{ name: 'ForgotPassword', query: { redirect_path: getRedirectPath } }" v-text="$t('link.forgotPassword')" />
      </b-row>
    </template>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput, DpFocusableElementMixins } from '@docupet/component-library';

export default {
  name: 'Login',
  components: {
    DpBaseInput,
  },
  mixins: [DpFocusableElementMixins],
  beforeRouteLeave(to, from, next) {
    to.meta.isStoreLogin = from.meta.isStoreLogin;
    next();
  },
  data: () => ({
    email: '',
    password: '',
    isStoreLogin: false,
    forceFailedValidation: false,
    redirectErrorMessage: null,
  }),
  mounted() {
    if (this.$route.query?.error_message) {
      this.redirectErrorMessage = decodeURIComponent(this.$route.query.error_message.replace(/\+/g, ' '));
    }

    if (this.$route.query?.error === 'session_expired') {
      this.redirectErrorMessage = { path: 'login.errorMessages.session_expired' };
    }

    this.clearErrorQueryParams();
  },
  computed: {
    ...mapGetters('login', ['isLoading', 'isSuccess', 'hasErrors', '_attemptCount']),
    contentsBlank: ({ email, password } = this) => email == '' || password == '',
    getErrorMessage: function () {
      if (this.redirectErrorMessage) {
        return this.redirectErrorMessage;
      }

      if ((this.hasErrors || (this._attemptCount > 1 && !this.isLoading)) && this._attemptCount <= 3) {
        return { path: 'login.errorMessages.default' };
      }
      if ((this.hasErrors || this._attemptCount > 3) && this._attemptCount > 3) {
        return 'contactCustomerCare';
      }
      return null;
    },
    getTargetPath: function () {
      return this.$router?.currentRoute?.query?._target_path || '';
    },
    getRedirectPath: function () {
      return this.$router?.currentRoute?.query?.redirect_path || '';
    },
    noLicensing() {
      return this.$store.getters['city/noLicensing'];
    },
  },
  watch: {
    email: function (oldValue, newValue) {
      this.forceFailedValidation = false;
      if (oldValue !== newValue && this.hasErrors) {
        this.$store.commit('login/RESET_ERROR');
      }
    },
    password: function () {
      this.forceFailedValidation = false;
    },
    _attemptCount: function () {
      if (this.getErrorMessage) {
        this.forceFailedValidation = true;
      }
    },
  },
  created() {
    this.email = this.$route.query.email ? this.$route.query.email : '';
  },
  methods: {
    ...mapActions('login', ['tryLogin', 'resetError']),
    clearRedirectErrorMessage() {
      this.redirectErrorMessage = null;
    },
    clearErrorQueryParams() {
      if ((this.$route.query?.error ?? null) === null && (this.$route.query?.error_message ?? null) === null) {
        return;
      }

      let query = Object.assign({}, this.$route.query);
      delete query.error;
      delete query.error_message;
      this.$router.replace({ query }).finally();
    },
    async onSubmit() {
      this.clearRedirectErrorMessage();
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.tryLogin({ email: this.email, password: this.password }).then((token) => {
              window?.dataLayer?.push({ event: 'login' });
              this.$router.push({
                name: 'SyfLoginSuccessRedirect',
                query: { token, targetPath: this.$router.query?._target_path || '' },
              });
            });
          } else {
            this.scrollToError(this.$refs.form);
          }
        })
        .catch(() => {
          this.scrollToError(this.$refs.form);
        });
    },
  },
};
</script>

<style scoped>
ul li {
  background: url('~@/assets/Paw.png') no-repeat left center;
  padding-left: 2.5em;
  padding-top: 2px;
  padding-bottom: 3px;
}
</style>
