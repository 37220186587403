<template>
  <div v-if="!isSuccess">
    <BasePanel :title="$t('forgotPassword.title')" :info="$t('forgotPassword.info')">
      <ValidationObserver ref="forgotPasswordForm" v-slot="{ handleSubmit, valid }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <DpBaseInput
            id="email-input"
            v-model.trim="email"
            name="email"
            type="email"
            :autofocus="true"
            autocomplete="username"
            validation-rules="required|email"
            :label="'input.email.label' | translate"
            :placeholder="'input.email.placeholder' | translate"
          />

          <b-button id="submit" type="submit" variant="primary" class="w-100 mt-2" :disabled="submitDisabled || !valid">
            {{ 'forgotPassword.buttonText' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>
        </form>
      </ValidationObserver>

      <template #footer>
        <b-row align-h="center">
          <b-link :to="nextUrl" v-text="$t('link.login.return')" />
        </b-row>
      </template>
    </BasePanel>
  </div>
  <div v-else>
    <BasePanel :title="$t('forgotPassword.onSuccess.title')" :info="$t('forgotPassword.onSuccess.info')">
      <b-link :to="nextUrl" class="btn btn-primary mt-n1 w-100" :disabled="isLoading" v-text="$t('link.login.return')" />
    </BasePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput } from '@docupet/component-library';

export default {
  name: 'ForgotPassword',
  components: {
    DpBaseInput,
  },
  beforeRouteLeave(to, from, next) {
    to.meta.isStoreLogin = from.meta.isStoreLogin;
    next();
  },
  data: () => ({
    email: '',
    submitDisabled: false,
  }),
  computed: {
    ...mapGetters('password', ['isLoading', 'isSuccess', 'getErrors', 'hasErrors']),
    contentsBlank: (self = this) => self.email == '',
    redirect_path: function () {
      return this.$router?.currentRoute?.query?.redirect_path || '';
    },
    nextUrl() {
      return { name: this.$router.currentRoute?.meta?.isStoreLogin ? 'StoreLogIn' : 'LogIn', query: { redirect_path: this.redirect_path } };
    },
  },
  mounted: function () {
    this.$store.commit('password/CLEAR');
  },
  methods: {
    ...mapActions('password', ['doForgotPassword']),
    async onSubmit() {
      this.submitDisabled = true;
      try {
        await this.doForgotPassword(this.email);
      } finally {
        this.submitDisabled = false;
      }
    },
  },
};
</script>

<style scoped></style>
