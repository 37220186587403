<template>
  <div>
    <a :href="`#${skipToMainContent}`" class="skip-link">{{ $t('dp.navbar.skipToMainContent') }}</a>
    <b-navbar class="mb-0 pt-1 pb-0" toggleable="lg">
      <div class="container">
        <b-navbar-brand :href="baseUrl">
          <b-img v-show="logoUrl" class="header-logo" :src="logoUrl" :alt="$t('dp.navbar.logoDescription')" fluid />
        </b-navbar-brand>

        <slot name="nav-items"></slot>
      </div>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'DpAdminNavbar',
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
      default: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      required: false,
      default: null,
    },
    skipToMainContent: {
      type: String,
      required: false,
      default: 'main',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_variables';
.navbar {
  background-color: $dp-white;
  border-color: $dp-gray-9;
}
.navbar-toggler {
  padding-top: 6px !important;
  padding-bottom: 4px !important;
  margin-left: 5px !important;
  border-color: #ddd !important;
  max-width: 8% !important;
}
.navbar-light .navbar-nav .nav-link {
  font-size: 90%;
  font-weight: bold;
  color: #333 !important;
  @media (min-width: 992px) {
    padding: 30px 20px 10px 20px;
  }
}
</style>
