<template>
  <div>
    <b-button :class="{ 'camera-icon': isCamera }" variant="outline" aria-label="Upload Image" @click="openFileUploader">
      <font-awesome-layers v-if="isCamera">
        <fa-icon icon="plus" size="lg" />
        <fa-icon icon="camera" size="2x" />
      </font-awesome-layers>
    </b-button>
    <input ref="file" type="file" style="display: none" :accept="getFileTypes" @change="onChange($event)" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faPlus } from '@fortawesome/pro-solid-svg-icons';

library.add(faPlus, faCamera);

export default {
  name: 'BaseAvatarImageUploader',
  props: {
    enableImageCropper: {
      type: Boolean,
      default: false,
      required: false,
    },
    accept: {
      type: Array,
      default: () => ['image/*', '.heic'],
      required: false,
      validator: value =>
        value.every(
          value =>
            typeof value === 'string' &&
            [
              'image/*',
              'image/bmp',
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/svg+xml',
              'image/webp',
              'image/tiff',
              'image/tif',
              'image/heic',
              'image/heif',
              '.heic',
            ].includes(value)
        ),
    },
    isCamera: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      imageSrc: null,
    };
  },
  computed: {
    getFileTypes() {
      return this.accept.join(',');
    },
  },
  methods: {
    async openFileUploader() {
      this.$refs.file.click();
    },
    onChange(e) {
      const files = e.target.files;
      const input = this.$refs.file;
      if (files && files.length && !this.enableImageCropper) {
        this.$emit('upload', files[0]);
      }
      if (this.enableImageCropper) {
        this.imageSrc = URL.createObjectURL(files[0]);
        this.$emit('imageToCrop', this.imageSrc);
      }

      if (input) {
        input.value = '';
      }
      this.imageSrc = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/_variables';
.camera-icon {
  width: 80px;
  border: 1px solid $dp-gray-9;
  border-radius: 50%;
  background: $dp-gray-1;
  color: $dp-blue;
  height: 80px;
  cursor: pointer;
  .fa-layers {
    width: 100%;
    height: 100%;
  }
  .fa-plus {
    left: -40px;
    top: -30px;
  }
}
</style>
