<template>
  <dp-container :col-class="colClass">
    <section v-if="backToStore" class="mb-3 mt-4 ml-3">
      <b-link :to="{ name: 'Store' }" class="text-decoration-none">
        <fa-icon icon="chevron-left" />
        {{ $t('dp.productNotFound.backToStore') }}
      </b-link>
    </section>
    <div class="mt-5 mb-0 text-center overlay">
      <b-card overlay :img-src="require('../../assets/images/404.jpg')" img-fluid img-alt="" class="border-0">
        <b-card-title class="d-none d-lg-block display-4 font-weight-bold mt-4">
          <span v-if="notFound">{{ $t('dp.productNotFound.notFound') }}</span>
          <span v-else-if="error">{{ $t('dp.productNotFound.error') }}</span>
        </b-card-title>
        <b-card-title class="d-block d-lg-none h2 font-weight-bold mt-3">
          <span v-if="notFound">{{ $t('dp.productNotFound.notFound') }}</span>
          <span v-else-if="error">{{ $t('dp.productNotFound.error') }}</span>
        </b-card-title>
        <b-card-body class="mt-lg-5">
          <b-button v-if="notFound" variant="primary" class="px-5" :to="{ name: 'Store' }">
            {{ $t('dp.productNotFound.buttons.viewAll') }}
          </b-button>
          <b-button v-else-if="error" variant="primary" class="px-5" @click="reloadPage">
            {{ $t('dp.productNotFound.buttons.refresh') }}
          </b-button>
        </b-card-body>
      </b-card>
    </div>
  </dp-container>
</template>
<script>
import DpContainer from '../Container/Container';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

library.add(faChevronLeft);

export default {
  name: 'ProductNotFound',
  components: {
    DpContainer,
  },
  props: {
    notFound: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: Boolean,
      default: false,
      required: false,
    },
    backToStore: {
      type: Boolean,
      default: false,
      required: false,
    },
    colClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/styles/_variables';
.overlay {
  h2 {
    margin-top: 2rem;
    color: $dp-gray-3;
  }
}
</style>
