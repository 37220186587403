import { gql } from '../../utils/GraphQLUtil';
import { petTrackingDeviceAddToOrderContext } from './../gqlFields/petTrackingDeviceAddToOrder.fields';

export default gql`
  query PetTrackingDeviceAddToOrderContext {
      petTrackingDeviceAddToOrderContext {
            ${petTrackingDeviceAddToOrderContext}
      }
  }
`;
