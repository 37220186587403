import { isEmpty } from 'lodash';
import CityService from '@/api/services/CityService';

export const namespaced = true;

export const state = {
  error: null,
  loading: false,
  response: null,
  cityLogoURL: null,
};

export const getters = {
  getErrors: ({ error }) => error,
  noLicensing: ({ response }) => response?.noLicensing,
  isLoading: ({ loading }) => loading,
  getCityBaseURL: ({ response }) => response?.baseUrl,
  getCityLogoURL: ({ cityLogoURL }) => cityLogoURL,
  getCityOrganization: ({ response }) => response?.organization,
  getCityCustomerSupportContext: ({ response }) => response?.customerCareContext,
  getOrganizationOldTagNumberFormatDescription: ({ response }) => response?.organizationOldTagNumberFormatDescription,
  getOrganizationOldTagImage: ({ response }) =>
    response?.organizationOldTagImage ? `https://${response?.baseUrl}${response?.organizationOldTagImage?.original}` : null,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isSuccess: ({ hasErrors, response }) => !hasErrors && !isEmpty(response),
  getCityLetterLogoURL: ({ response }) => `https://${response?.baseUrl}${response?.letterLogo?.original}`,
  getImageAltText: ({ response }) => response?.letterLogo?.imageAltText,
  // getCityLetterLogoURL: () =>
  //   `https://boulder.dev-us.docupet.com/media/uploads/public/system/87/33/8733dffda0a1bf0db5bc4548f36654f0eb04ec4a4f78cf00ffc53107c965b19c.png`,
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  SUCCESS(state, response) {
    state.cityLogoURL = response && response.letterLogo && response.letterLogo.original ? response.letterLogo.original : null;
    state.error = null;
    state.loading = false;
    state.response = response;
  },
  FAILURE(state, error) {
    state.error = error;
    state.response = null;
    state.loading = false;
  },
  CLEAR(state) {
    state.error = null;
    state.response = null;
    state.loading = false;
  },
};

export const actions = {
  fetchCity: ({ commit, rootGetters }) => {
    commit('LOADING', true);
    CityService.fetchCity(rootGetters['getCityId'])
      .then((response) => {
        commit('SUCCESS', response);
      })
      .catch(({ error }) => {
        commit('FAILURE', error.message?.[0]?.message || error.message);
      });
  },
};
