<template>
  <div v-if="!isSuccess">
    <BasePanel :title="$t('resetPassword.title')" :subheading="$t('resetPassword.subheading')">
      <template #errors>
        <BaseDisplayMessage v-if="getErrorMessage" class="text-danger" :message="getErrorMessage" role="alert" />
      </template>

      <ValidationObserver ref="resetPasswordForm" v-slot="{ handleSubmit, valid }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <input v-model.trim="email" autocomplete="username" hidden />
          <DpBaseInput
            id="password-input"
            v-model.trim="password"
            name="password"
            ref="passwordField"
            type="password"
            autocomplete="new-password"
            use-password-validation-rules
            class="sentry-mask"
            additional-description="password-check-list"
            :bails="false"
            :vid="'password'"
            :autofocus="true"
            :label="'input.newPassword.label' | translate"
            :placeholder="'input.newPassword.placeholder' | translate"
          >
            <template #default="{ failedRules, dirty, valid }">
              <dp-secure-password-check-list
                id="password-check-list"
                :failed-rules="{ ...currentPasswordRule, ...failedRules }"
                :additional-rules="currentPasswordRule"
                :dirty="dirty"
                :valid="valid"
              />
            </template>
          </DpBaseInput>

          <DpBaseInput
            id="confirm-password-input"
            v-model.trim="confirmPassword"
            ref="passwordConfirmField"
            type="password"
            autocomplete="new-password"
            validation-rules="required|passwordMatch:@password"
            class="sentry-mask"
            :label="'input.passwordConfirm.label' | translate"
            :placeholder="'input.passwordConfirm.placeholder' | translate"
          />

          <b-button type="submit" variant="primary" class="mt-4 w-100" :disabled="isLoading || !valid">
            {{ 'resetPassword.buttonText' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>
        </form>
      </ValidationObserver>
    </BasePanel>
  </div>
  <div v-else>
    <BasePanel :title="$t('resetPassword.onSuccess.title')" :info="$t('resetPassword.onSuccess.infoText')">
      <b-link
        :disabled="isLoading"
        :to="{ name: 'LogIn', query: { email: email || '' } }"
        class="btn btn-primary mt-n2 w-100"
        v-text="$t('resetPassword.onSuccess.buttonText')"
      />
    </BasePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput, DpSecurePasswordCheckList } from '@docupet/component-library';

export default {
  name: 'ChangePassword',
  components: {
    DpSecurePasswordCheckList,
    DpBaseInput,
  },
  beforeRouteEnter(to, from, next) {
    const token = to.query?.token;

    if (token) {
      const { commit } = require('@/store').default;
      commit('SET_TOKEN', token, { root: true });
      next();
    } else next({ name: 'ForgotPassword' });
  },
  data: () => ({
    email: '',
    password: '',
    confirmPassword: '',
    currentPasswordRule: {},
  }),
  computed: {
    ...mapGetters('password', ['isLoading', 'isSuccess', 'hasErrors', 'getErrors', '_attemptCount']),
    contentsBlank: ({ password, confirmPassword } = this) => password === '' || confirmPassword === '',
    getErrorMessage: function () {
      if ((this.hasErrors || (this._attemptCount > 1 && !this.isLoading)) && this._attemptCount <= 3) {
        return { path: 'resetPassword.errorMessages.default' };
      }
      if ((this.hasErrors || this._attemptCount > 3) && this._attemptCount > 3) {
        return 'contactCustomerCare';
      }
      return null;
    },
  },
  created() {
    this.fetchPetOwner()
      .then(({ email }) => {
        this.email = email;
      })
      .catch(() => {
        this.$router.push({ name: 'ForgotPassword' });
      });
  },
  methods: {
    ...mapActions('password', ['doPasswordChange']),
    ...mapActions('completeSignup', ['fetchPetOwner']),
    async onSubmit({ password, confirmPassword, doPasswordChange } = this) {
      try {
        await doPasswordChange({ firstPassword: password, secondPassword: confirmPassword }).then((email) => {
          this.email = email;
        });
      } catch (e) {
        let errorList = [];
        e.error.message.forEach((errorMessage) => {
          if (errorMessage.message === 'current_password') {
            errorList[this.$refs.passwordField.name] = this.$t('resetPassword.errorMessages.currentPassword');
            this.$refs.resetPasswordForm.refs[this.$refs.passwordField.name].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            this.currentPasswordRule = {
              currentPassword: { displayText: errorList[this.$refs.passwordField.name], valid: false },
            };
          }
          if (errorMessage.message === 'password_mismatch') {
            errorList[this.$refs.passwordConfirmField.name] = this.$t('resetPassword.errorMessages.mismatch');
          }
        });
        this.$refs.resetPasswordForm.setErrors(errorList);
      }
    },
  },
};
</script>

<style scoped></style>
