export const FocusableElementMixin = {
  data() {
    return {
      currentFocus: null,
    };
  },
  methods: {
    scrollToError(refElement) {
      setTimeout(() => {
        const errors = Object.entries(refElement ? refElement.errors : null)
          .map(([key, value]) => ({ key, value }))
          .filter(error => error['value'].length);

        // Add child refs to parent
        const observers = refElement ? refElement.observers : [];
        observers.forEach(o => {
          if (Object.keys(o.refs).length > 0) {
            if (Object.entries(o.refs)) {
              Object.entries(o.refs).forEach(([refKey, refValue]) => (refElement.refs[refKey] = refValue));
            }
          }
        });

        if (errors.length === 0) {
          return;
        }

        const errorKeyIndex = errors[0]['key'];
        const errorKey = refElement.refs[errorKeyIndex];
        if (errorKey) {
          errorKey.$el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          setTimeout(() => {
            const firstParentElement = errorKey.$el ? errorKey.$el : errorKey.$parent.$el;
            this.focusFirstElement(firstParentElement);
          }, 500);
        }
      }, 50);
    },
    focusFirstElement(container) {
      const focusableSelectors =
        'a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])';
      const focusableElements = container.querySelectorAll(focusableSelectors);
      if (focusableElements.length > 0) {
        focusableElements[0].focus();
      }
    },
  },
};