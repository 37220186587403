<template>
  <div>
    <a v-if="viewSkipToMainContent" :href="`#${skipToMainContent}`" class="skip-link">{{ $t('dp.navbar.skipToMainContent') }}</a>
    <b-navbar class="my-0 align-items-center justify-content-between" toggleable="lg">
      <div class="container " :class="{ 'image-only-header': isImageOnlyHeader }">
        <b-nav-toggle
          v-if="!isImageOnlyHeader"
          target="navbar-toggle-collapse"
          label="Toggle open or close menu visibility"
          title="Menu"
          class="p-0 border-0"
        >
          <template #default="{ expanded }">
            <transition name="fade">
              <fa-icon v-if="expanded" :icon="['fas', 'x']" />
              <fa-icon v-else :icon="['fas', 'bars']" />
            </transition>
          </template>
        </b-nav-toggle>

        <!-- Desktop logo to resolver tab order-->
        <b-navbar-brand :href="logoRedirectUrl" class="logo mr-0 d-lg-block d-none">
          <b-img
            v-show="docupetLogo || logoUrl"
            class="header-logo img-fluid"
            :src="isImageOnlyHeader || !logoPath ? docupetLogo : logoUrl"
            :alt="$t('dp.navbar.logoDescription')"
          />
        </b-navbar-brand>

        <b-collapse v-if="!isImageOnlyHeader" id="navbar-toggle-collapse" is-nav class="flex-row d-lg-none">
          <b-navbar-nav ref="mobileMenuDropdown" class="ml-auto">
            <b-nav-form v-if="!isLoggedIn" class="d-lg-none">
              <b-button type="submit" aria-hidden="true" hidden />
              <div class="d-inline mr-3">
                <b-link router-tag="button" class="btn btn-mobile-nav btn-outline-primary" :href="`${baseLocaleUrl}/login`">
                  {{ $t('dp.navbar.login') }}
                </b-link>
              </div>
              <div class="d-inline">
                <b-link
                  router-tag="button"
                  class="btn btn-mobile-nav btn-primary"
                  :href="`${baseLocaleUrl}${isNoLicensing ? '/signup/register' : '/signup'}`"
                >
                  {{ $t('dp.navbar.signup') }}
                </b-link>
              </div>
            </b-nav-form>

            <!-- Mobile menu dropdown-->
            <b-nav-item-dropdown v-else :text="$t('dp.navbar.yourAccount')" class="d-lg-none account-dropdown">
              <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/my-details`">
                {{ $t('dp.navbar.myDetails') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/pet-guardians`">
                {{ $t('dp.navbar.petGuardians') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/profile-settings`">
                {{ $t('dp.navbar.profileSettings') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/order-history`">
                {{ $t('dp.navbar.orderHistory') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/billing-details`">
                {{ $t('dp.navbar.billingDetails') }}
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item :href="`${baseLocaleUrl}/logout?baseUrl=${baseLocaleUrl}`">
                {{ $t('dp.navbar.logOut') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item v-if="isLoggedIn" exact :href="`${baseLocaleUrl}/account`" :active="checkRoute? $route.name === 'Dashboard': false">
              {{ $t('dp.navbar.dashboard') }}
            </b-nav-item>

            <b-nav-item v-if="isDisplayLicensingPage && !isLoggedIn" :href="`${baseLocaleUrl}${'/licensing'}`">
              {{ $t('dp.navbar.licensing') }}
            </b-nav-item>

            <b-nav-item :href="`${baseLocaleUrl}/store`" :active="routePathContains('/store')" class="d-none d-lg-block">
              {{ $t('dp.navbar.shop') }}
            </b-nav-item>

            <b-nav-item
              v-if="isLoggedIn"
              :href="`${baseLocaleUrl}/account/full/account-app/my-pets/`"
              :active="routePathContains('/my-pets')"
            >
              {{ $t('dp.navbar.myPets') }}
            </b-nav-item>

            <b-nav-item v-if="isDisplayHomeSafePage && !isLoggedIn" :href="`${baseLocaleUrl}${'/homesafe'}`">
              Home<em>Safe</em><sup>&reg;</sup>
            </b-nav-item>

            <b-nav-item v-if="isDisplaySafeAndHappyFundPage && !isLoggedIn" :href="`${baseLocaleUrl}${'/safe-and-happy-fund'}`">
              {{ city.htmlSafeAndHappyFundLinkTitle || $t('dp.navbar.safeAndHappyFund') }}
            </b-nav-item>

            <b-nav-item v-if="!isLoggedIn && isDisplayAboutPage" :href="`${baseLocaleUrl}/about`">
              {{ $t('dp.navbar.about') }}
            </b-nav-item>

            <b-nav-item v-if="helpDeskUrl && isNoLicensing === false" :href="helpDeskUrl">
              {{ $t('dp.navbar.helpDesk') }}
            </b-nav-item>

            <b-nav-item
              v-if="!isLoggedIn"
              class="d-none d-lg-flex"
              :href="`${baseLocaleUrl}${isNoLicensing ? '/signup/register' : '/signup'}`"
            >
              {{ $t('dp.navbar.signup') }}
            </b-nav-item>

            <b-nav-form class="nav-buttons nav-item d-none d-lg-flex ml-2">
              <b-button type="submit" aria-hidden="true" hidden />
              <b-link
                v-if="!isLoggedIn"
                router-tag="button"
                class="btn btn-login my-2 my-sm-0 mr-0 btn-outline-primary"
                :href="`${baseLocaleUrl}/login`"
              >
                <strong>{{ $t('dp.navbar.login') }}</strong>
              </b-link>

              <b-dropdown
                v-else
                variant="outline-primary"
                toggle-class="btn-account my-2 my-sm-0 mr-0 d-flex align-items-center"
                no-caret
                right
              >
                <template #button-content>
                  <span class="mr-2">{{ $t('dp.navbar.yourAccount') }}</span>
                  <fa-icon :icon="['far', 'angle-up']" class="align-bottom when-open" />
                  <fa-icon :icon="['far', 'angle-down']" class="align-bottom when-closed" />
                </template>
                <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/my-details`">
                  {{ $t('dp.navbar.myDetails') }}
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/pet-guardians`">
                  {{ $t('dp.navbar.petGuardians') }}
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/profile-settings`">
                  {{ $t('dp.navbar.profileSettings') }}
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/order-history`">
                  {{ $t('dp.navbar.orderHistory') }}
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :href="`${baseLocaleUrl}/account/full/account-app/settings/billing-details`">
                  {{ $t('dp.navbar.billingDetails') }}
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item :href="`${baseLocaleUrl}/logout?baseUrl=${baseLocaleUrl}`">
                  {{ $t('dp.navbar.logOut') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>

        <!-- Mobile logo to resolver tab order -->
        <b-navbar-brand :href="logoRedirectUrl" class="logo mr-0 d-lg-none d-block">
          <b-img
            v-show="docupetLogo || logoUrl"
            class="header-logo img-fluid"
            :src="isImageOnlyHeader || !logoPath ? docupetLogo : logoUrl"
            :alt="$t('dp.navbar.logoDescription')"
          />
        </b-navbar-brand>

        <b-navbar-nav class="flex-row d-lg-none">
          <b-nav-item
            v-if="!isImageOnlyHeader"
            :href="`${baseLocaleUrl}/store`"
            :active="routePathContains('/store')"
            class="text-primary pr-2 py-0 has-icon"
            title="Shop"
          >
            <fa-icon class="fa-xl" :icon="['far', 'store']" />
          </b-nav-item>

          <b-nav-item
            v-if="!isImageOnlyHeader"
            class="basket py-0 has-icon"
            :href="`${baseLocaleUrl}/basket`"
            :aria-label="$t('dp.navbar.basket.ariaLabel', { basketCount: safeBasketCount })"
          >
            <span class="fa-layers fa-fw fa-xl">
              <fa-icon class="text-primary" :icon="['far', 'shopping-basket']" />
              <span class="fa-layers-counter fa-2x">{{ safeBasketCount }}</span>
            </span>
          </b-nav-item>
        </b-navbar-nav>

        <b-link
          v-if="!isImageOnlyHeader"
          class="basket d-none d-lg-block"
          :href="`${baseLocaleUrl}/basket`"
          :aria-label="$t('dp.navbar.basket.ariaLabel', { basketCount: safeBasketCount })"
        >
          <fa-icon class="float-right" :icon="['far', 'shopping-basket']" size="2x" />
          <b-badge class="position-relative rounded-circle badge-basket" variant="info" v-text="safeBasketCount" />
        </b-link>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { icons } from './assets/fontawesome.js';
import docupetLogo from './assets/docupet_logo.png';
library.add(icons);

export default {
  name: 'DpNavbar',
  props: {
    locale: {
      type: [String, null],
      required: false,
      default: null,
    },
    isLoggedIn: {
      type: Boolean,
      required: false,
      default: false,
    },
    city: {
      type: Object,
      required: false,
      default: null,
    },
    petOwner: {
      type: Object,
      required: false,
      default: null,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    logoPath: {
      type: String,
      required: false,
      default: null,
    },
    orderId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    basketCount: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    logoRedirectPath: {
      type: String,
      required: false,
      default: null,
    },
    isImageOnlyHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    helpDeskUrl: {
      type: String,
      required: false,
      default: 'https://docupetinc.zendesk.com/hc/en-us',
    },
    skipToMainContent: {
      type: String,
      required: false,
      default: 'main',
    },
    viewSkipToMainContent: {
      type: Boolean,
      required: false,
      default: true
    },
    checkRoute: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      docupetLogo: docupetLogo,
    };
  },
  computed: {
    safeBasketCount() {
      return Number.isSafeInteger(this.basketCount) && !(this.basketCount < 0)
        ? Number(this.basketCount) < 99
          ? this.basketCount.toString()
          : '99+'
        : '0';
    },
    baseLocaleUrl() {
      if (this.locale) {
        return `${this.baseUrl}/${this.locale}`;
      }

      return `${this.baseUrl}/${this.$i18n.locale.replace('-', '_')}`;
    },
    logoUrl() {
      // If the logo path is already a full URL don't include the base url.
      if (this.logoPath && this.logoPath.indexOf('.com') !== -1) {
        return this.logoPath;
      } else {
        return this.baseUrl + this.logoPath;
      }
    },
    logoRedirectUrl() {
      return this.logoRedirectPath
        ? this.logoRedirectPath
        : this.isLoggedIn
        ? `${this.baseLocaleUrl}/account/full/account-app`
        : this.baseUrl;
    },
    isDisplayHomeSafePage() {
      if (this.city) {
        return this.city.displayHomeSafePage;
      }
      return false;
    },
    isDisplaySafeAndHappyFundPage() {
      if (this.city) {
        return this.city.displaySafeAndHappyFundPage;
      }
      return false;
    },
    isDisplayLicensingPage() {
      if (this.petOwner && this.city && this.city.name === 'Boulder') {
        if (this.petOwner.vsLocation === 'COUNTY' || this.petOwner.vsLocation === 'OTHER') {
          return false;
        }
      }
      if (this.city) {
        return this.city.displayLicensingPage;
      }
      return false;
    },
    isNoLicensing() {
      if (this.city) {
        return this.city.noLicensing;
      }
      return true;
    },
    isDisplayAboutPage() {
      if (this.city) {
        return this.city.displayAboutPage;
      }
      return false;
    },
  },
  mounted() {
    this.resolveAccessibilityIssues();
  },
  methods: {
    routePathContains(path) {
      return window?.location?.pathname?.includes(path);
    },
    resolveAccessibilityIssues() {
      this.$refs?.mobileMenuDropdown?.querySelector('.account-dropdown a')?.removeAttribute('aria-haspopup');
    },
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap-grid';
@import '../../assets/styles/_variables';

#navbar-toggle-collapse {
  order: 2;
}
.image-only-header {
  display: flex;
  justify-content: center !important;
}
.header-logo {
  cursor: pointer;
  max-height: 50px;
}
.navbar {
  min-height: 70px;
  z-index: 1000;
  background-color: $dp-white;
}
.mini-toggle {
  display: none;
}
.skip-link {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  padding: 8px;
  text-decoration: none;
  background-color: $dp-white;
  border: 1px solid $dp-navy;
  &:focus {
    opacity: 1;
    z-index: 9999;
  }
}
.navbar .container {
  max-width: 1370px;
}
.navbar-light .navbar-nav .nav-item {
  font-weight: bold;
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-text,
.dropdown-item {
  color: $dp-navy !important;
  font-size: 15.4px;
  &:hover {
    color: $dp-blue !important;
  }
  &:active {
    background-color: $dp-gray-1 !important;
  }
}
.dropdown-item {
  &:hover,
  &:focus {
    background-color: $dp-light-blue-2 !important;
  }
}
.navbar-toggler {
  width: 23px;
  height: 23px;
}
.nav-buttons .nav-link {
  padding: 0 !important;
}
.navbar-light .navbar-nav {
  & button {
    font-size: 14.4px;
    &:not(.btn-account) {
      padding: 6px 25px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &.btn-account,
    &.btn-account {
      margin: 10px;
      padding: 10px 14px;
      border-radius: 6.5px;
      background-color: $dp-white !important;
      color: $dp-navy !important;
      &:hover {
        color: $dp-blue !important;
      }
    }
    &[aria-expanded='false'] .when-open,
    &[aria-expanded='true'] .when-closed {
      display: none;
    }
    @media screen and (max-width: 991px) {
      margin-top: 0 !important;
    }
  }
  & .btn-login {
    border-radius: 6.5px;
    font-size: 14.4px;
    padding: 10px 34px;
    margin-left: 0.5rem;
  }
}
.dropdown-menu {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.basket {
  color: $dp-navy;
  & .badge-basket {
    font-size: 1em;
    top: -0.75rem;
    left: 2.8rem;
  }
  &:hover {
    color: $dp-blue;
  }
  & .fa-layers-counter {
    background-color: $dp-yellow;
    color: #212529;
    top: -8px;
    right: -8px;
  }
  @include media-breakpoint-up(lg) {
    order: 3;
  }
}
.navbar-light .navbar-nav {
  & .nav-item {
    &.has-icon {
      .nav-link {
        &.active {
          border-bottom: none;
        }
      }
    }
    & .nav-link {
      height: 100%;
      display: flex;
      text-align: center;
      align-items: center;
      &.active {
        color: $dp-navy;
        font-weight: bold;
        border-bottom: 4px solid $dp-navy;
      }
    }
    @include media-breakpoint-up(lg) {
      &:not(:has(.nav-link.active)) {
        padding-bottom: 4px;
      }
    }
  }
}
@media screen and (max-width: 280px) {
  .logo {
    max-width: 130px;
  }
  .header-logo {
    max-height: 30px;
  }
  .navbar {
    min-height: auto;
  }
}
@media screen and (max-width: 430px) {
  .header-logo {
    max-height: 30px;
  }
  .navbar {
    min-height: 60px;
  }
}
@media screen and (max-width: 991px) {
  .navbar.navbar-light .navbar-collapse {
    & .navbar-nav {
      & li {
        &.form-inline {
          padding: 1rem 1rem 1rem 0;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          &.btn {
            margin-top: 0;
          }
          & form.form-inline div.d-inline .btn.btn-mobile-nav {
            border-radius: 6.5px;
            margin-right: 15px;
            font-size: 14.4px;
            padding: 10px 34px;
            text-transform: capitalize;
            font-weight: normal;
          }
        }
        &.nav-item {
          &:not(:nth-last-child(2)) {
            border-bottom: 1px solid #ddd;
          }
          &.b-nav-dropdown.dropdown.show > a {
            padding-bottom: 0 !important;
          }
          & .dropdown-menu.show {
            border: none;
          }
        }
      }
    }
  }
}
</style>
