import * as Sentry from '@sentry/vue';
import { getQueryParams, inSentryTestingMode } from '../src/utils/queryParametersUtil';

// Example of regex https://boulder.docupet.com and https://boulder.demo1-us.com
const DOCUPET_SITES = '^.+\\.docupet\\.com';
const DOCUPET = /docupet\.com/;
const ADDRESSY = /api\.addressy\.com/;
const NETWORK_DETAILS_ALLOW_URLS = [DOCUPET, ADDRESSY];

export const DEFAULT_CONFIG = {
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [DOCUPET_SITES, ADDRESSY],
    }),
    new Sentry.Replay({
      maskAllText: false,
      networkDetailAllowUrls: NETWORK_DETAILS_ALLOW_URLS,
    }),
  ],
  replaysSessionSampleRate: 0, // adjust for testing dev to always include a sample.
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
  normalizeDepth: 6,
  environment: 'production',
  ignoreErrors: [
    'Non-Error exception captured',
    'NetworkError',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  allowUrls: [DOCUPET],
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Can't use optional chaining without implementing Babel
    if (error) {
      // Ignore clarity.js errors
      if (error.stack && error.stack.includes('clarity.js')) {
        return null;
      }

      // Filter out unsuccessful login attempts.
      if (error.message && error.message.includes('not match any user')) {
        return null;
      }
    }

    return event;
  },
};

export function init(Vue, dsn, env, defaultConfig = DEFAULT_CONFIG) {
  const config = {
    ...defaultConfig,
    environment: env || defaultConfig.environment,
  };
  Sentry.init({
    Vue,
    dsn: dsn,
    ...config,
  });

  // QA Testing
  if (inSentryTestingMode() && Sentry.getCurrentHub().getClient()) {
    const queryParams = getQueryParams();
    const timeout = queryParams && 'timeout' in queryParams ? queryParams['timeout'] : 10000;

    setTimeout(() => {
      throw new Error('Testing Sentry QA parameter');
    }, timeout);
  }
}

export function breadcrumb(category, level, message, data = null) {
  Sentry.addBreadcrumb({
    category: category,
    level: level,
    message: message,
    data: {
      ...data,
    },
  });
}

export function user(id, email) {
  Sentry.setUser({
    id: id,
    email: email,
    username: email,
  });
}

export const sentry = Sentry;
