<template>
  <div>
    <ValidationObserver>
      <b-row no-gutters>
        <div class="d-flex justify-content-between flex-grow-1">
          <b-form-group
            :id="`phone-number-type-input-group-${id}`"
            :label="`Select ${phoneNumberTypeLabel} ${$t('dp.input.phoneNumber.label')}`"
            :label-sr-only="true"
            :label-for="`phone-number-type-select-${id}`"
            class="w-100"
          >
            <b-form-select
              :id="`phone-number-type-select-${id}`"
              :value="propsPhoneNumberType"
              :options="phoneNumberTypes"
              @change="$emit('updatePhoneType', propsPhoneNumberType, $event, id)"
            />
          </b-form-group>
          <b-link v-show="showRemovePhoneNumber" class="align-self-center ml-5" @click="$emit('removePhoneTypeByIndex', id)">
            {{ $t('dp.buttons.remove') }}
          </b-link>
        </div>
      </b-row>
      <div class="mt-2">
        <dp-base-input
          :id="`phone-number-type-input-${id}`"
          inputmode="numeric"
          pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
          type="tel"
          autocomplete="tel-national"
          class="sentry-mask"
          :name="`phone-number-${id}`"
          :value="formattedValue"
          :formatter="phoneNumberFormatter"
          :placeholder="`${$t('dp.input.phoneNumber.placeholder', { phoneNumberType: phoneNumberTypeLabel })}`"
          :label="`${$t('dp.input.phoneNumber.placeholder', { phoneNumberType: phoneNumberTypeLabel })}`"
          :label-sr-only="true"
          :validation-rules="validationRules"
          @input="$emit('updatePhoneNumber', $event.replace(/[^\d]/g, ''), id)"
        />
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import DpBaseInput from '../BaseInput/BaseInput';

export default {
  name: 'BasePhoneNumber',
  components: {
    DpBaseInput,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    phoneNumberTypes: {
      type: Array,
      default: () => [
        { text: this.$t('dp.input.phoneNumber.mobilePhone'), value: 'M' },
        { text: this.$t('dp.input.phoneNumber.homePhone'), value: 'H' },
        { text: this.$t('dp.input.phoneNumber.workPhone'), value: 'W' },
      ],
    },
    phoneNumberType: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    showRemovePhoneNumber: {
      type: Boolean,
      default: true,
    },
    validationRules: {
      type: String,
      default: 'required|phoneNumber',
    },
  },
  computed: {
    // Reactivity
    propsPhoneNumberType() {
      return this.phoneNumberType;
    },
    formattedValue() {
      return this.phoneNumberFormatter(this.value);
    },
    phoneNumberTypeLabel() {
      let label = '';
      switch (this.phoneNumberType.toLowerCase()) {
        case 'm':
          label = this.$t('dp.input.phoneNumber.mobilePhone');
          break;
        case 'h':
          label = this.$t('dp.input.phoneNumber.homePhone');
          break;
        case 'w':
          label = this.$t('dp.input.phoneNumber.workPhone');
          break;
        default:
          label = this.$t('dp.input.phoneNumber.mobilePhone');
      }
      return label.toLowerCase();
    },
  },
  methods: {
    phoneNumberFormatter(phone) {
      if (phone) {
        let x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      }
    },
  },
};
</script>
