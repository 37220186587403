export const loadStripe = async (key, options) => {
  if (typeof window.Stripe !== 'undefined') {
    return window.Stripe(key, options);
  }

  const Stripe = document.createElement('script');
  Stripe.src = 'https://js.stripe.com/v3/';

  await new Promise((resolve, reject) => {
    Stripe.onload = () => {
      if (typeof window.Stripe !== 'undefined') {
        resolve(window.Stripe(key, options));
      } else {
        const errorMsg = 'Stripe v3 library is not loaded';
        console.log(errorMsg);
        reject(Error(errorMsg));
      }
    };
    Stripe.onerror = () => {
      const errorMsg = 'Failed to load Stripe v3 library';
      console.log(errorMsg);
      reject(Error(errorMsg));
    };
    document.body.appendChild(Stripe);
  });

  return window.Stripe(key, options);
};

export const initStripe = async (key, options) => {
  try {
    return await loadStripe(key, options);
  } catch (error) {
    return error.message;
  }
};

export const createElements = (instance, options) => {
  if (typeof instance === 'undefined') {
    const errorMsg = 'Instance object is not defined, make sure you initialized Stripe before creating elements';
    console.error(errorMsg);
    return errorMsg;
  }

  return instance.elements(options);
};

export const createElement = (elements, elementType, options) => {
  if (typeof elements === 'undefined') {
    const errorMsg = 'Elements object is not defined. You can not create stripe element without it';
    console.error(errorMsg);
    return errorMsg;
  }
  if (typeof elementType === 'undefined') {
    const errorMsg = 'elementType is required. You can not create stripe element without it';
    console.error(errorMsg);
    return errorMsg;
  }
  return elements.create(elementType, options);
};

export function destroy(instance) {
  instance = undefined;
  return instance;
}
