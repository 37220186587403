<template>
  <div
      id="add-to-order"
  >
    <div class="exclusive-offer mb-5">
      <div class="bg-blue">
        <div class="container">
          <img
              id="bottom-exclusive-device-img"
              alt="Pet Tracker Device"
              class="device-img"
              :src="deviceImage"
          />
          <div class="column-left">
            <img
                alt="Pet Tracker App"
                :src="appImage"
            />
          </div>
          <div
              id="bottom-exclusive-container"
              class="container column-right"
              style="max-width: 550px !important; margin: auto; padding-top: 44px; padding-bottom: 44px;"
          >
            <div class="column-container w-100" style="margin: auto">
              <h2 id="order-subtitle-bottom" v-html="subtitle"></h2>
              <p class="mb-4" v-html="callToActionBody"></p>
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PetTrackingDeviceMarketingContentBlock",
  props: {
    deviceImage: {
      type: String,
      required: true
    },
    appImage: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    callToActionBody: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.exclusive-offer img {
  width: 100%;
  height: auto;
}

.exclusive-offer .bg-blue {
  background: #f0f4ff;
}

.exclusive-offer .container {
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1143px;
  padding: 0 24px;
}
.exclusive-offer .column-left {
  width: 42.5%;
  margin-right: 8.6%;
  align-self: end;
  padding-top: 44px;
  box-sizing: border-box;
}

.exclusive-offer .headline {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #009fdf;
}

.exclusive-offer h2,
.exclusive-offer .price {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
  color: #003c71;
}

.exclusive-offer .price {
  font-size: 28px;
}

.exclusive-offer .cta,
.cta {
  padding: 24px 56px;
  box-sizing: border-box;
  background: #003c71;
  color: white;
  border-radius: 9px;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 1.3;
  display: inline-block;
}

.exclusive-offer .cta:hover,
.cta:hover {
  text-decoration: none;
}

.exclusive-offer .column-right {
  width: 48.9%;
  padding-top: 66px;
}

.exclusive-offer .device-img {
  position: absolute;
  bottom: -2px;
  left: 42.5%;
  width: 36.4%;
  height: auto;
  margin-left: -23%;
}
.exclusive-offer .disclaimer {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #4a4a4a;
}

@media only screen and (max-width: 992px) {
  .exclusive-offer .headline {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  .exclusive-offer h2,
  .exclusive-offer .price {
    font-size: 28px;
  }

  .exclusive-offer .price {
    font-size: 20px;
  }

  .exclusive-offer .cta,
  .cta {
    padding: 16px 40px;
    font-size: 16px !important;
  }

  .cta-outline {
    border: 2px solid #003c71 !important;
  }

  .pet-tracking-device-add-to-order-component {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 768px) {
  .exclusive-offer .column-left {
    width: 40%;
    margin-right: 15%;
  }

  .exclusive-offer .column-right {
    width: 57.7%;
    padding-top: 49px;
    padding-bottom: 20px;
  }

  .exclusive-offer .device-img {
    position: absolute;
    bottom: -2px;
    left: 31vw;
    width: 27.7vw;
    height: auto;
    margin-left: -13.5vw;
  }
}
@media only screen and (max-width: 576px) {
  .container {
    padding: 0 16px;
  }

  .exclusive-offer .container {
    display: block;
    padding-top: 50px;
    text-align: center;
  }

  .exclusive-offer .device-img {
    position: relative;
    bottom: unset;
    left: unset;
    width: 100%;
    margin: 0 auto 42px auto;
    max-width: 224px;
    height: auto;
  }

  .exclusive-offer .column-left {
    display: none;
  }

  .exclusive-offer .column-right {
    text-align: left;
    width: 100%;
    padding-bottom: 24px;
    padding-top: 0;
  }

  .exclusive-offer .price {
    margin-bottom: 42px;
  }

  .exclusive-offer .cta,
  .cta {
    width: 100%;
    text-align: center;
    display: block;
  }

  .exclusive-offer .headline,
  .exclusive-offer .price {
    font-size: 18px;
  }
}

.cta-outline {
  background: white !important;
  color: #003c71 !important;
  border-radius: 9px;
  border: #003c71 2px solid;
}

.cta {
  font-weight: 400 !important;
}
</style>