<template>
  <b-button
    v-bind="$attrs"
    variant="outline-primary"
    class="bubble"
    :aria-label="`${name} colour`"
    data-toggle="tooltip"
    data-placement="auto"
    :style="colorStyle"
    :title="`Select ${name}`"
    v-on="$listeners"
    @click="clicked(name, hex)"
  >
    <span class="sr-only">{{ name }} colour</span>
    <span :class="{ check: !thing }" style="font-size: 20pt;">
      &check;
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'ColorBubble',
  components: {},
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: null,
    },
    hex: {
      type: String,
      required: true,
      default: '#FFFFFF',
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  data: function() {
    return {
      thing: '',
    };
  },
  computed: {
    colorStyle() {
      return 'background-color: ' + this.hex + '; ' + 'color: ' + (this.hex > '#805A26' ? 'black' : 'white');
    },
  },
  methods: {
    clicked(name, hex) {
      this.thing = !this.thing;
      this.$emit('input', { name, hex });
    },
  },
};
</script>

<style scoped>
.bubble {
  border-radius: 30px;
  width: 60px;
  height: 60px;
  border: 1px solid #eee;
  text-align: center;
  font-size: 9pt;
  padding: 4px;
}

.check {
  display: none;
}
</style>
