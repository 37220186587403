//regular
import { faShoppingBasket } from '@fortawesome/pro-regular-svg-icons/faShoppingBasket';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { faStore } from '@fortawesome/pro-regular-svg-icons/faStore';

//export array
export const icons = [faShoppingBasket, faAngleDown, faAngleUp, faBars, faX, faStore];
