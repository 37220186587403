<template>
  <BasePanel :title="$t('fetchMyRecord.title')">
    <template #container-header>
      <b-container class="mb-4" fluid>
        <b-img center class="city-logo-img" :src="getCityLetterLogoURL" :alt="`${getImageAltText || getCityOrganization}`" fluid />
      </b-container>
    </template>

    <template #info>
      <BaseDisplayMessage
        v-if="getCityOrganization"
        class="text-dark"
        :no-path-translation="false"
        :message="{
          path: 'fetchMyRecord.subheading',
          getCityOrganization: getCityOrganization,
          action: {
            text: 'sign up here',
            to: { name: 'SyfSignUp' },
          },
        }"
      />
    </template>

    <slot name="errors" />

    <b-tabs content-class="mt-3" nav-class="flex-nowrap text-center align-items-stretch">
      <b-tab
        :active="currentScreen == 'ByAccessCode'"
        title-item-class="font-weight-bold w-50"
        title-link-class="h-100 d-flex align-items-center"
        :title-link-attributes="{ to: { name: 'FetchMyRecordByAccessCode' }, activeClass: 'text-dark' }"
      >
        <template #title>
          <span class="text-decoration-none w-100"> {{ 'fetchMyRecord.byAccessCode.title' | translate }} </span>
        </template>
        <ValidationObserver>
          <slot v-if="currentScreen == 'ByAccessCode'" />
        </ValidationObserver>
      </b-tab>
      <b-tab
        :active="currentScreen == 'ByTagNumber'"
        title-item-class="font-weight-bold w-50"
        title-link-class="h-100 d-flex align-items-center"
        :title-link-attributes="{ to: { name: 'FetchMyRecordByTagNumber' }, activeClass: 'text-dark' }"
      >
        <template #title>
          <span class="text-decoration-none w-100"> {{ 'fetchMyRecord.byTagNumber.title' | translate }} </span>
        </template>
        <ValidationObserver>
          <slot v-if="currentScreen == 'ByTagNumber'" />
        </ValidationObserver>
      </b-tab>
    </b-tabs>

    <template #footerMessage>
      <slot name="footerMessage" />
    </template>

    <template #footer>
      <slot name="footer" />
    </template>

    <b-modal
      id="modal-how-we-source-existing-records-info"
      title="How we fetch your existing license records"
      ok-only
      ok-variant="outline-primary"
      ok-title="Close"
    >
      <strong>Why matching existing records is important</strong>
      <p>
        {{ getCityOrganization }} has provided us with existing pet licensing records so that we can send reminder notices and keep your
        information current. It is really important that existing records are updated so that we don't have duplicate information and send
        unnecessary reminders to you!
      </p>
      <strong>About DocuPet's "Access Codes"?</strong>
      <p>
        When we send renewal reminders via mail or email (when we have this on file) to pet owners who have not yet created accounts with
        DocuPet we include an Access Code. These unique codes ensure that only the contact information on file can access each record.
      </p>
      <strong>Matching Pet and Pet Owner Data</strong>
      <p>
        Sometimes pet owners who have previously licensed with {{ getCityOrganization }} come to our site to renew a pet license without
        having yet received an Access Code. In these cases, we can often source a previous record using data matching. We will ask for a
        current license tag number, a pet's name, your ZIP code and your phone number. If all of these match, we can fetch your record.
      </p>
      <strong>Creating a DocuPet Account</strong>
      <p>
        Once we have your record matched we will send you to the account creation page. Once you have entered your email address and created
        a password, you will find your existing pet licensing information in your online profile.
      </p>
    </b-modal>
  </BasePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BaseFetchMyRecord',
  props: {
    currentScreen: {
      type: String,
      default: 'ByAccessCode',
    },
  },
  computed: {
    ...mapGetters('city', ['getCityLetterLogoURL', 'getCityOrganization', 'getImageAltText']),
  },
  created: function () {
    this.fetchCity();
    this.$store.commit('fetchMyRecord/RESET_ERROR');
  },
  methods: {
    ...mapActions('city', ['fetchCity']),
    screenChanged: function (screenName) {
      this.$router.push({ name: `FetchMyRecord${screenName}`, query: this.$router.currentRoute.query });
    },
  },
};
</script>

<style lang="scss" scoped>
.city-logo-img {
  max-height: 180px;
  width: auto;
}
</style>
