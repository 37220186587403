<template>
  <div v-if="stripeElementsReady">
    <b-row no-gutters class="my-2" align-v="center">
      <b-col cols="12">
        <label class="mb-0">{{ $t('dp.stripeForm.cardNumber') }}</label>
        <stripe-element
          ref="cardNumber"
          type="cardNumber"
          :elements="elements"
          :options="stripeOptions"
          @change="cardNumber = $event.complete"
        />
      </b-col>
    </b-row>
    <b-row class="my-2" align-v="center">
      <b-col cols="6" md="3">
        <label class="mb-0">{{ $t('dp.stripeForm.expiryDate') }}</label>
        <stripe-element
          ref="cardExpiry"
          type="cardExpiry"
          :elements="elements"
          :options="stripeOptions"
          @change="expiry = $event.complete"
        />
      </b-col>
      <b-col cols="6" md="3">
        <label class="mb-0">
          {{ $t('dp.stripeForm.cvcNumber') }}
          <b-button v-b-modal.dp-stripe-form-cvc-help-text variant="outline-primary" class="tooltip-btn ml-1" size="sm">
            <b-icon-question-circle-fill variant="dark"></b-icon-question-circle-fill>
          </b-button>
        </label>
        <stripe-element ref="cardCvc" type="cardCvc" :elements="elements" :options="stripeOptions" @change="cardCvc = $event.complete" />
      </b-col>
      <b-col cols="12" md="6" class="mt-md-0 mt-2">
        <label class="mb-0">{{ $t('dp.stripeForm.postalCode') }}</label>
        <stripe-element
          ref="postalCode"
          type="postalCode"
          :elements="elements"
          :options="stripeOptionsPostalCodeLocale"
          @change="postalCode = $event.complete"
        />
      </b-col>
    </b-row>

    <b-modal
      id="dp-stripe-form-cvc-help-text"
      :hide-footer="true"
      :aria-label="$t('dp.stripeForm.cvcHeader')"
      header-close-variant="btn-outline-primary"
      header-class="justify-content-end border-0"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-end">
          <b-button variant="outline-primary" @click="close()">
            Close
          </b-button>
        </div>
      </template>
      <p>{{ $t('dp.stripeForm.cvcHelper') }}</p>
      <img src="./assets/cvc_helper.jpg" :alt="$t('dp.stripeForm.cvcHelperImageAlt')" />
    </b-modal>
  </div>
</template>

<script>
import { BIconQuestionCircleFill } from 'bootstrap-vue';
import { initStripe, createElements } from '../../api/stripe';
import StripeElement from '../StripeElement/StripeElement';

export default {
  components: {
    StripeElement,
    BIconQuestionCircleFill,
  },
  props: {
    publicKey: {
      type: String,
      required: true,
    },
    showIcons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      complete: false,
      cardNumber: false,
      expiry: false,
      cardCvc: false,
      postalCode: false,
      instance: {},
      elements: {},
    };
  },
  computed: {
    stripeElementsReady() {
      if (!this.elements) {
        return false;
      }

      return Object.keys(this.elements).length > 0;
    },
    stripeOptions() {
      return {
        showIcon: this.showIcons,
        style: {
          base: {
            color: '#495057',
            iconColor: '#495057',
            lineHeight: '22px',
            fontFamily: 'Lato, sans-serif',
            fontWeight: '400',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': { color: '#6c757d' },
          },
          invalid: { color: '#fa755a', iconColor: '#fa755a' },
        },
      };
    },
    stripeOptionsPostalCodeLocale() {
      return Object.assign({ placeholder: this.$t('dp.stripeForm.postalCodePlaceholder') }, this.stripeOptions);
    },
  },
  watch: {
    complete() {
      this.$emit('completedForm', this.complete);
    },
    cardNumber() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cardCvc() {
      this.update();
    },
    postalCode() {
      this.update();
    },
  },
  async mounted() {
    this.instance = await initStripe(this.publicKey);
    if (typeof this.instance === 'object') {
      this.elements = createElements(this.instance, this.stripeOptions);
    }
  },
  methods: {
    update() {
      this.complete = this.cardNumber && this.expiry && this.cardCvc && this.postalCode;

      // field completed, find field to focus next
      if (this.cardNumber) {
        if (!this.expiry) {
          this.$refs.cardExpiry.stripeElement.focus();
        } else if (!this.cardCvc) {
          this.$refs.cardCvc.stripeElement.focus();
        }
      } else if (this.expiry) {
        if (!this.cardCvc) {
          this.$refs.cardCvc.stripeElement.focus();
        } else if (!this.cardNumber) {
          this.$refs.cardNumber.stripeElement.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    async getToken() {
      try {
        const data = await this.instance.createToken(this.$refs.cardNumber.stripeElement);

        if (data && data.token && !data.error) {
          this.$emit('tokenCreated', { token: data.token.id, type: data.token.type });
          return { token: data.token.id, type: data.token.type };
        } else {
          throw 'tokenFailed';
        }
      } catch {
        this.$emit('tokenFailed');
        return null;
      }
    },
  },
};
</script>
