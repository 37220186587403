<template>
  <section>
    <header class="text-right">
      <b-button
        v-if="hasDevicesCurrentlyInBasket"
        :disabled="requestState.loading"
        class="text-dark text-decoration-underline p-1 pl-0"
        variant="link"
        @click="$emit('create-basket-item-cancelled')"
      >
        {{ cancelButtonText }}
      </b-button>
    </header>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <b-form @submit.prevent="submit">
        <ValidationProvider v-slot="validationContext" rules="required">
          <label for="selectedPetField" class="my-2">
            <strong>{{ stepOneText }}</strong>
          </label>
          <b-form-select
            id="selectedPetField"
            v-model="selectedPet"
            :disabled="requestState.loading"
            class="mb-3"
            :state="getValidationState(validationContext)"
            :options="petOptions"
          />
          <b-alert v-if="validationContext.validated && validationContext.invalid" show variant="warning" fade>
            {{ selectPetText }}
          </b-alert>
        </ValidationProvider>
        <ValidationProvider v-slot="validationContext" rules="required">
          <b-form-radio-group
            v-model="selectedSubscription"
            class="mb-3"
            :state="getValidationState(validationContext)"
            @change="emitSubscriptionEvent(selectedSubscription)"
          >
            <label class="my-2">
              <strong>{{ stepTwoText }}</strong>
            </label>
            <b-form-radio
              v-for="(option, key) in subscriptionOptions"
              :key="`${option.value} ${key}`"
              :disabled="requestState.loading"
              :value="option.productId"
              class="mb-2 w-auto d-flex mx-0 pl-4 my-md-2"
              name="subscription-selection"
              :plain="true"
            >
              <div class="d-flex align-items-start align-items-md-center w-100 flex-column flex-md-row">
                <span class="pb-0 mb-0 mr-3">
                  <span class="font-weight-bold" v-html="option.label"></span>
                  <span class="d-block fs-14 pt-0 mt-0">{{ option.billed }}</span>
                </span>
                <div class="d-flex my-2">
                  <span
                    v-for="(extra, index) in option.extras"
                    :key="`extra-${index}`"
                    class="px-2 py-1 fs-10 mr-2 text-center font-weight-bold rounded extras"
                    :class="[extra.color, extra.backgroundColor]"
                    v-html="extra.text"
                  >
                  </span>
                  <span
                    v-if="option.savePercent"
                    class="px-2 py-1 text-center save-percent fs-10 font-weight-bold rounded"
                    v-html="option.savePercent"
                  >
                  </span>
                </div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <b-alert v-if="validationContext.validated && validationContext.invalid" show variant="warning" fade>
            {{ selectSubscriptionText }}
          </b-alert>
        </ValidationProvider>
        <b-alert v-for="(error, key) in requestState.errors" :key="`form-errors-${key}`" show variant="warning" fade>
          <span>{{ error }}</span>
        </b-alert>
        <b-button
          :disabled="requestState.loading || disableState"
          :class="[{ disabled: invalid }, 'w-100']"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="requestState.loading" small />
          <span v-else>{{ submitButtonText }}</span>
        </b-button>
      </b-form>
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import RequestState from '../../utils/requestState';

export default {
  name: 'AddAnotherDeviceForm',
  components: { ValidationProvider },
  inject: ['context', 'apiService'],
  props: {
    disableState: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      selectedPet: null,
      selectedSubscription: null,
      requestState: new RequestState(),
    };
  },
  computed: {
    petOptions() {
      return [
        { value: null, text: 'Select Pet Name' },
        ...this.context?.petOptions?.map(option => ({ text: option.label, value: option.value, selected: option.selected })),
      ];
    },
    subscriptionOptions() {
      return this.context?.subscriptionOptions;
    },
    cancelButtonText() {
      return this.context?.cancelNewOrderItemButtonText ?? 'Cancel';
    },
    submitButtonText() {
      return this.context?.submitNewOrderItemFormButtonText ?? 'Add to Basket';
    },
    stepOneText() {
      return this.context?.stepOneText;
    },
    stepTwoText() {
      return this.context?.stepTwoText;
    },
    selectPetText() {
      return this.context?.selectPetText;
    },
    selectSubscriptionText() {
      return this.context?.selectSubscriptionText;
    },
    hasDevicesCurrentlyInBasket() {
      return this.context?.devicesCurrentlyInBasket.length > 0;
    },
  },
  mounted() {
    if (this.petOptions.length > 1) {
      let defaultPet = this.petOptions.find(defaultPet => defaultPet.selected === true);
      this.selectedPet = defaultPet ? defaultPet.value : this.petOptions[1].value;
    }

    if (this.context?.subscriptionOptions.length === 1) {
      this.selectedSubscription = parseInt(this.context?.subscriptionOptions[0].productId);
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      if ((dirty || validated) && !valid) {
        return false;
      }

      return null;
    },
    emitSubscriptionEvent(subscriptionProductId) {
      let subscription = this.subscriptionOptions.find(item => item.productId === subscriptionProductId);
      this.$emit('subscription-radio-click', subscriptionProductId, subscription.label);
    },
    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      try {
        this.requestState.start();
        this.$emit('create-basket-item-started');
        const response = await this.apiService.mutationAddPetTrackingDeviceToOrder(this.selectedPet, this.selectedSubscription);
        this.requestState.success(response);
        this.$refs.form.reset();
        this.$emit('create-basket-item-success', response);
      } catch (e) {
        this.requestState.error(e.message);
        this.$emit('create-basket-item-error', e.message);
      } finally {
        this.$emit('create-basket-item-complete');
      }
    },
  },
};
</script>

<style>
.fs-10 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}

.extras {
  line-height: 130% !important;
}

.bg-interrupt-yellow {
  background: #ffe9a4;
}

.bg-interrupt-green {
  background: #438000;
}

.text-interrupt-blue {
  color: #214e76;
}

.text-interrupt-white {
  color: white;
}
</style>
