import Vue from 'vue';
import i18n from '../i18n';
import { DpSentryInit } from '@docupet/component-library';
import { ValidationObserver } from 'vee-validate';
import { FontAwesomeIcon } from './config/fontawesome';
import { componentsPlugin, installFactory } from './config/bootstrap';
import { upperFirst, camelCase, forEach, get, chunk, keys, toUpper } from 'lodash';
import { ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full.esm';

const env = (process.env?.VUE_APP_ENV || process.env?.NODE_ENV)?.toLowerCase();
if (env !== 'local') {
  DpSentryInit(Vue, 'https://1cfc236dff8943e28bda3fb8c3ec4cc6@o452146.ingest.sentry.io/5816380', env);
}

// Bootstrap Tree-shaking
const BootstrapVue = installFactory(
  {
    plugins: {
      componentsPlugin,
    },
  },
  'BootstrapVue'
);
Vue.use(BootstrapVue);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('FaIcon', FontAwesomeIcon);

// Lodash
Vue.set(Vue.prototype, '_', { get, keys, chunk, toUpper });

const requireComponent = require.context('.', false, /Base[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));

  Vue.component(componentName, componentConfig.default || componentConfig);
});

// Custom Validators

const REGEXP_VALIDATORS = {
  oneLowerChar: /(?=.*[a-z])/,
  oneUpperChar: /(?=.*[A-Z])/,
  oneDigitChar: /(?=.*[0-9])/,
  oneSpecialChar: /(?=.*\W)/,
  phoneNumber: /\+?(\d)?[ .-]?(\(?\d{3}\)?)[ .-]?(\d{3})[ .-]?(\d{4})/,
};

forEach(REGEXP_VALIDATORS, (validation_exp, validation_key) => {
  extend(validation_key, {
    validate: (value) => validation_exp.test(value),
    message: (field) => `The ${field} is not valid`,
  });
});

extend('passwordMatch', {
  params: ['target'],
  validate: (value, { target }) => value === target,
  message: i18n.t('resetPassword.errorMessages.mismatch'),
});

//OVERRIDE REQUIRED RULES WITH CUSTOM MESSAGES
const getCustomRequiredMessage = (field) => {
  let message = '';
  switch (field) {
    case 'tag-number':
      message = i18n.t('input.tagNumber.errorMessages.tag_number_required');
      break;
    case 'email':
      message = i18n.t('dp.input.email.customErrorMessage.isEmpty');
      break;
    case 'password':
      message = i18n.t('dp.input.password.customErrorMessage.isEmpty');
      break;
    case 'accepted-tc-pp':
      message = i18n.t('dp.input.agreeToTermsConditions.customErrorMessage');
      break;
    case 'postal-code':
      message = i18n.t('input.postalCode.errorMessages');
      break;
    default:
      message = `The ${field.split('-').join(' ')} is required`;
  }
  return message;
};
extend('required', {
  message: getCustomRequiredMessage,
});

//OVERRIDE ALPHA NUMERIC RULES WITH CUSTOM MESSAGESasd
const getCustomAlphaNumericMessage = (field) => {
  let message = '';
  switch (field) {
    default:
      message = i18n.t('fetchMyRecord.byAccessCode.errorMessages.onlyAlphaNumericCharacters', { field: field.split('-').join(' ') });
  }
  return message;
};
extend('alpha_num', {
  message: getCustomAlphaNumericMessage,
});

//OVERRIDE EMAIL FIELD MESSAGE
extend('email', {
  message: i18n.t('dp.input.email.customErrorMessage.hasValue'),
});

//EMAIL EXISTS
const noEmailFoundParams = ['email', 'message'];
extend(
  'emailAddressNotFound',
  {
    validate: (value, params) => value !== params[0],
    message: (value, params) => params[1],
  },
  noEmailFoundParams
);

//OVERRIDE PHONE NUMBER FIELD MESSAGE
extend('phoneNumber', {
  message: i18n.t('input.phoneNumber.customErrorMessage'),
});

const emailExistsParams = ['email', 'message'];
extend(
  'emailExists',
  {
    validate: (value, params) => value !== params[0],
    message: (value, params) => params[1],
  },
  emailExistsParams
);
