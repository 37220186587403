export default {
  namespaced: true,
  state: {
    isLoading: false,
    isErrored: false,
  },
  mutations: {
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_ERROR(state, isErrored) {
      state.isErrored = isErrored;
    },
    RESET(state) {
      state.isLoading = false;
      state.isErrored = false;
    },
  },
  actions: {
    start({ commit }) {
      commit('RESET');
      commit('SET_LOADING', true);
    },
    stop({ commit }) {
      commit('SET_LOADING', false);
    },
    handleError({ commit }, isErrored) {
      commit('SET_ERROR', isErrored);
      commit('SET_LOADING', false);
    },
  },
  getters: {
    isLoading: state => state.isLoading,
    hasError: state => state.isErrored,
  },
};
