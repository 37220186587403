<template>
  <ValidationProvider
    v-slot="{ errors, valid, dirty, failedRules, touched, invalid }"
    ref="validationProvider"
    :name="name || label.toLowerCase()"
    :rules="rules"
    :immediate="forceImmediateValidation"
    :bails="bails"
    :vid="vid"
  >
    <b-form-group :id="`${name}-input-group`" :label-for="$attrs.id">
      <div class="d-flex justify-content-between align-items-baseline">
        <slot name="label" :props="$props">
          <label v-if="label" class="input-label text-dark" :for="$attrs.id" :class="{ 'sr-only': labelSrOnly }">
            {{ label }}
          </label>
        </slot>
        <b-form-text v-if="isPasswordType && value">
          <b-link @click="toggleShowPassword">
            {{ showPassword ? $t('dp.input.password.show') : $t('dp.input.password.hide') }}
          </b-link>
        </b-form-text>
        <b-form-text v-else-if="formText">
          {{ formText }}
        </b-form-text>
      </div>
      <b-form-input
        v-model="field"
        v-bind="$attrs"
        :name="name"
        :aria-describedby="`${$attrs.id}-invalid-feedback ${additionalDescription}`"
        :class="(touched && invalid) || (invalidClass && invalid) ? 'is-invalid' : ''"
        :state="forceErrorState ? false : errors[0] ? false : valid && field && field.length ? true : null"
        :type="showPassword ? 'text' : type"
        autocomplete="off"
        @keydown="preventSpecificCharacters($event)"
        @blur="onBlurEvent"
        @focus="onFocusEvent"
      />
      <i18n
        v-if="errorMessageHasPath.path || noPathTranslate"
        :id="`${$attrs.id}-invalid-feedback`"
        :path="noPathTranslate ? errorMessageHasPath : errorMessageHasPath.path"
        tag="div"
        v-bind="$attrs"
        class="invalid-feedback"
        role="alert"
      >
        <template #br>
          <br />
        </template>
        <template #action>
          <b-link :to="errorMessageHasPath.action.to" class="font-weight-bold">
            {{ errorMessageHasPath.action.text }}
          </b-link>
        </template>
      </i18n>
      <div
        v-else
        :id="`${$attrs.id}-invalid-feedback`"
        class="invalid-feedback"
        role="alert"
        v-html="isPasswordType && field && field.length && errors[0] ? $t('dp.input.password.customErrorMessage.hasValue') : errors[0]"
      />
      <slot :failedRules="failedRules" :dirty="dirty" :valid="valid" />
      <slot name="belowField" />
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    formText: {
      type: String,
      default: '',
    },
    invalidClass: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: [String, Object],
      default: '',
    },
    validationRules: {
      type: [String, Object],
      default: '',
    },
    usePasswordValidationRules: {
      type: Boolean,
      default: false,
    },
    forceImmediateValidation: {
      type: Boolean,
      default: false,
    },
    forceErrorState: {
      type: Boolean,
      default: false,
    },
    bails: {
      type: Boolean,
      default: true,
    },
    vid: {
      type: String,
      default: '',
    },
    noPathTranslate: {
      type: Boolean,
      default: false,
    },
    labelSrOnly: {
      type: Boolean,
      default: false,
    },
    additionalDescription: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    field: {
      get: ({ value } = this) => value,
      set(value) {
        this.$emit('input', value);
      },
    },
    isPasswordType: (self = this) => self.name.toLowerCase() === 'password',
    errorMessageHasPath() {
      return this.validationErrors?.path ? this.validationErrors : false;
    },
    rules() {
      if (this.usePasswordValidationRules) {
        return `required|min:12|oneLowerChar|oneUpperChar|oneDigitChar|oneSpecialChar|${this.validationRules}`;
      }
      return this.validationRules;
    },
  },
  watch: {
    validationErrors: function(value) {
      if (value) this.$refs.validationProvider.errors.push(value);
    },
  },
  methods: {
    preventSpecificCharacters(e) {
      if (e.key === '/') {
        e.preventDefault();
      }
    },
    onBlurEvent() {
      if (this.type.toLowerCase() === 'password') this.showPassword = false;
    },
    onFocusEvent() {
      this.$emit('focus');
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped></style>
