<template>
  <footer class="footer">
    <b-row class="p-4" align-v="end" no-gutters>
      <b-col>
        <span class="mr-1">&copy; {{ new Date().getFullYear() }} {{ $t('dp.footer.copyright') }}</span>
        <div class="d-block d-md-none" />
        <span>
          <span class="mr-1">
            <b-link :href="baseUrl + '/tos'" target="_blank">
              {{ $t('dp.footer.tos') }} <span class="sr-only">{{ $t('dp.links.opensInNewTab') }}</span>
            </b-link>
          </span>
          |
          <span class="ml-1">
            <b-link :href="baseUrl + '/privacy-policy'" target="_blank">
              {{ $t('dp.footer.privacy') }} <span class="sr-only">{{ $t('dp.links.opensInNewTab') }}</span>
            </b-link>
          </span>
        </span>
      </b-col>
    </b-row>
  </footer>
</template>

<script>
export default {
  name: 'DpFooter',
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables';
.footer {
  color: $dp-text;
  font-size: 13px;
  background-color: $dp-gray-1;
  box-shadow: 0 0 3.5px 0.5px $dp-shadow-1;
  a {
    color: $dp-text;
    text-decoration: underline;
  }
}
</style>
