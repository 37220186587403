import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  // if user is offline user
  async tryLoginByAccessCode(payload) {
    try {
      const query = gql`
        mutation loginByAccessCode($accessCode: String!, $postalCode: String!) {
          loginByAccessCode(accessCode: $accessCode, postalCode: $postalCode) {
            token
          }
        }
      `;
      const variables = { ...payload };
      const { loginByAccessCode: results } = await axios.post('/', {
        query,
        variables,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
  // if user is offline user
  async tryLoginByTagNumber(payload) {
    try {
      const query = gql`
        mutation loginByTagNumber($tagNumber: String!, $petName: String!, $postalCode: String!, $phoneNumber: String!) {
          loginByTagNumber(tagNumber: $tagNumber, petName: $petName, postalCode: $postalCode, phoneNumber: $phoneNumber) {
            token
          }
        }
      `;
      const variables = { ...payload };
      const { loginByTagNumber: results } = await axios.post('/', {
        query,
        variables,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
