<template>
  <b-row>
    <b-col>
      <b-row v-show="isLoading">
        <b-col class="text-center mb-4">
          <b-spinner />
        </b-col>
      </b-row>
      <b-row v-show="!isLoading">
        <b-col v-if="jurisdictionMoveTerms && jurisdictionMoveTerms.header && !isLoading">
          <b-card bg-variant="muted-warning" class="shadow-none mb-4">
            <b-card-title
              v-if="jurisdictionMoveTerms.header"
              :title="jurisdictionMoveTerms.header"
              title-tag="h2"
              class="font-weight-bold mb-2 text-dark"
            />
            <b-card-sub-title v-if="jurisdictionMoveTerms" class="my-2 h6" sub-title-tag="h3" sub-title-text-variant="dark">
              <p v-if="jurisdictionMoveTerms.subHeader" v-html="jurisdictionMoveTerms.subHeader" />
              <p v-if="jurisdictionMoveTerms.termIntro">{{ jurisdictionMoveTerms.termIntro }}</p>
            </b-card-sub-title>
            <b-card-text class="text-dark">
              <div>
                <!-- Admin PO layout -->
                <div v-if="isAdmin">
                  <ol>
                    <li v-for="(list, key) in jurisdictionMoveTerms.list" :key="key" v-html="list" />
                  </ol>
                  <BaseCheckbox
                    v-if="jurisdictionMoveTerms.action.length === 1"
                    id="confirm-city-change"
                    v-model="confirmCityChange"
                    name="accepted-moving-jurisdisction"
                    :label="jurisdictionMoveTerms.action[0].text"
                    :validation-rules="{ required: { allowFalse: false } }"
                    class="d-inline-block text-danger"
                    size="md"
                  />
                </div>
                <!-- PO layout -->
                <div v-if="!isAdmin">
                  <p v-for="(list, key) in jurisdictionMoveTerms.list" :key="key" v-html="list" />
                  <BaseRadioInput
                    v-if="jurisdictionMoveTerms.action.length > 1"
                    id="confirm-city-change"
                    v-model="confirmCityChange"
                    :validation-rules="{ required: { allowFalse: true } }"
                    :options="[
                      {
                        value: true,
                        html: jurisdictionMoveTerms.action[0].text,
                      },
                      {
                        value: false,
                        html: jurisdictionMoveTerms.action[1].text,
                      },
                    ]"
                    stacked
                  />
                </div>
              </div>
              <b-row v-if="confirmCityChange !== null && !confirmCityChange">
                <b-col>
                  <b-button :to="{ name: 'DeactivateAccount' }" variant="primary"
                    >{{ $t('dp.jurisdictionChange.deactivateAccount') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import BaseRadioInput from '../BaseComponents/BaseRadioInput/BaseRadioInput';
import BaseCheckbox from '../BaseComponents/BaseCheckbox/BaseCheckbox';

export default {
  name: 'JurisdictionChangeTerms',
  components: {
    BaseRadioInput,
    BaseCheckbox,
  },
  props: {
    cityId: {
      type: String,
      required: true,
    },
    cityNoLicensing: {
      type: Boolean,
      required: true,
    },
    suggestedCity: {
      type: Object,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    jurisdictionMoveList: {
      type: [Array],
      required: false,
      default: null,
    },
    jurisdictionMoveTerms: {
      type: Object,
      required: false,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      confirmCityChange: null,
    };
  },
  watch: {
    suggestedCity: function(newState, oldState) {
      if (newState === oldState) {
        return;
      }

      this.resetConfirmCity();
    },
  },
  methods: {
    resetConfirmCity() {
      this.confirmCityChange = null;
    },
  },
};
</script>

<style>
#radio-input-confirm-city-change .custom-radio {
  margin-left: 0 !important;
}
</style>
