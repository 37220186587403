import { isEmpty } from 'lodash';
import FetchMyRecordService from '@/api/services/FetchMyRecordService';

export const namespaced = true;

export const state = {
  error: null,
  loading: false,
  response: null,
  _attempts: {
    count: 1,
  },
};

export const getters = {
  getErrors: ({ error }) => error,
  isLoading: ({ loading }) => loading,
  _attemptCount: ({ _attempts }) => _attempts.count,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isSuccess: ({ response, hasErrors }) => !hasErrors && !isEmpty(response),
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  RESET_ERROR(state) {
    state.error = null;
    state._attempts.count = 1;
  },
  SUCCESS(state, response) {
    state.error = null;
    state.loading = false;
    state.response = response;
    state._attempts.count = 1;
  },
  FAILURE(state, error) {
    state.error = error;
    state.loading = false;
    state.response = null;
    state._attempts.count++;
  },
};

export const actions = {
  tryLoginByAccessCode: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      FetchMyRecordService.tryLoginByAccessCode(payload)
        .then(({ token }) => {
          commit('SUCCESS', 'success', token);
          !isEmpty(token) ? resolve(token) : reject();
        })
        .catch(({ error }) => {
          const errorMessage =
            error?.message?.[0]?.message ||
            error?.message ||
            'An unknown error occurred';

          commit('FAILURE', errorMessage);
          reject();
        });
    }).then((token) => {
      commit('SET_TOKEN', token, { root: true });
    }),
  tryLoginByTagNumber: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      FetchMyRecordService.tryLoginByTagNumber(payload)
        .then(({ token }) => {
          commit('SUCCESS', 'success', token);
          !isEmpty(token) ? resolve(token) : reject();
        })
        .catch(({ error }) => {
          commit('FAILURE', error?.message[0]?.message);
          reject();
        });
    }).then((token) => {
      commit('SET_TOKEN', token, { root: true });
    }),
};
