import Vue from 'vue';
import Vuex from 'vuex';
import { DpSentryAddBreadcrumb, DpNavigationModule as navigation } from '@docupet/component-library';
import i18n from '@/i18n.js';
import modules from '@/store/modules/_index';

Vue.use(Vuex);

const initialState = window.__INITIAL_STATE__ || {
  loading: true,
  token: process.env.VUE_APP_TOKEN || null,
  locale: process.env.VUE_APP_LOCALE || 'en_CA',
  cityLogoURL: window.CITY_LOGO_URL || 'https://www.docupet.com/wp-content/uploads/2019/05/docupet-logo-sm-shelter.png',
  cityId: window.CITY_ID || 3,
  baseUrl: global.API_URL || process.env.VUE_APP_BASE_URL,
};

export default new Vuex.Store({
  state: initialState,
  getters: {
    getToken: (state) => state.token,
    getBaseUrl: (state) => state.baseUrl,
    getCityId: ({ cityId }) => cityId,
    isLoading: ({ loading }) => loading,
    getLocale: ({ locale }) => locale || window.__INITIAL_STATE__.locale || 'en_CA',
  },
  mutations: {
    SET_LOADING(state, loadingState = true) {
      state.loading = loadingState;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_LOCALE(state, locale = window.__INITIAL_STATE__.locale || 'en_CA') {
      locale = locale.replace('-', '_');
      i18n.locale = locale;
      state.locale = locale;
    },
    SET_CITY_LOGO_URL(state, url) {
      if (url) state.cityLogoURL = url;
    },
    SET_CITY_ID(state, cityId) {
      if (cityId) state.cityId = cityId;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    navigation,
    ...modules,
  },
  plugins: [
    (store) => {
      store.subscribeAction((action, state) =>
        DpSentryAddBreadcrumb('Action', 'info', action?.type || '', {
          ...state,
          token: null,
        })
      );
      store.subscribe((mutation) => DpSentryAddBreadcrumb('Mutation', 'info', mutation?.type || ''));
    },
  ],
});
