import Vue from 'vue';
import i18n from '@/i18n';
import store from '@/store/';
import Router from 'vue-router';
// Views
import LogIn from '@/views/LogIn';
import StoreLogIn from '@/views/StoreLogIn';
import ResetPassword from '@/views/ResetPassword';
import ForgotPassword from '@/views/ForgotPassword';
import CompleteSignup from '@/views/CompleteSignup';
import RequestAccessCode from '@/views/RequestAccessCode';
import FetchMyRecordByTagNumber from '@/views/FetchMyRecord/ByTagNumber';
import FetchMyRecordByAccessCode from '@/views/FetchMyRecord/ByAccessCode';
import ScreenContextService from '@/api/services/ScreenContextService';

Vue.use(Router);

function handleContext(contextLoader) {
  return async (to, from, next) => {
    if (!store?.hasModule?.('navigation')) {
      throw new Error('Navigation module required');
    }
    await store.dispatch('navigation/start');
    try {
      to.params.context = await contextLoader();
    } catch (error) {
      await store.dispatch('navigation/handleError', error);
    } finally {
      await store.dispatch('navigation/stop');
      next();
    }
  };
}

const routes = [
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn,
    beforeEnter: (to, from, next) => {
      if (to.query?.redirect_path === '/store') next({ name: 'StoreLogIn', query: { ...to.query } });
      if (to.query?.token) {
        next({ name: 'SyfLoginSuccessRedirect', query: { ...to.query } });
      } else next();
    },
  },
  {
    path: '/login/store',
    name: 'StoreLogIn',
    component: StoreLogIn,
    beforeEnter: (to, from, next) => {
      if (to.query?.token) {
        next({ name: 'SyfLoginSuccessRedirect', query: { ...to.query } });
      } else next();
    },
    meta: {
      isStoreLogin: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    alias: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/request-access-code',
    name: 'RequestAccessCode',
    component: RequestAccessCode,
  },
  {
    path: '/activate',
    name: 'FetchMyRecord',
    redirect: { name: 'FetchMyRecordByAccessCode' },
  },
  {
    path: '/activate/code',
    name: 'FetchMyRecordByAccessCode',
    component: FetchMyRecordByAccessCode,
  },
  {
    path: '/activate/tag',
    name: 'FetchMyRecordByTagNumber',
    component: FetchMyRecordByTagNumber,
  },
  {
    path: '/complete-signup/',
    alias: ['/activate/complete', '/activate/complete/:action', '/complete-signup/:action'],
    name: 'CompleteSignup',
    component: CompleteSignup,
    beforeEnter: handleContext(ScreenContextService.fetchCompleteSignupScreenContext),
    props: (route) => ({ fetchMyRecord: route.query.fetchMyRecord, context: route.params.context }),
  },
  // External Symfony URI's
  {
    path: '/',
    name: 'BaseURL',
    beforeEnter: () => {
      window.location.href = `${window.BASE_URL}`;
    },
  },
  {
    path: '/signup',
    name: 'SyfSignUp',
    beforeEnter: ({ query }) => {
      const { targetPath, redirectPath, noLicensing } = query;
      let redirectLink = noLicensing ? '/signup/register?' : '/signup?';
      window.location.href = `${window.BASE_URL}${redirectLink}${targetPath ? `&_target_path=${targetPath}` : ``}${
        redirectPath ? `&redirect_path=${redirectPath}` : ``
      }`;
    },
  },
  {
    path: '/authenticate',
    name: 'SyfLoginSuccessRedirect',
    beforeEnter: ({ query }) => {
      const { token, targetPath, redirectPath } = query;
      const currentQuery = new URL(window.NEXT_URL, window.BASE_URL).search;
      const GO_TO_URL = store.getters['completeSignup/getRedirectUri'] ?? window.NEXT_URL;
      const UTM_QUERY_PARAMS = store.getters['completeSignup/getUtmQueryParams'];

      window.location.href = `${GO_TO_URL}${currentQuery ? '&' : '?'}token=${token}${targetPath ? `&_target_path=${targetPath}` : ``}${
        redirectPath ? `&redirect_path=${redirectPath}` : ``
      }${UTM_QUERY_PARAMS ? `&${new URLSearchParams(UTM_QUERY_PARAMS).toString()}` : ''}
      `;
    },
  },
  {
    path: '/tos',
    name: 'TermsOfService',
    beforeEnter: () => {
      window.open(`${window.BASE_URL}/tos`, '_blank');
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    beforeEnter: () => {
      window.open(`${window.BASE_URL}/privacy-policy`, '_blank');
    },
  },
];

const router = new Router({
  mode: 'history',
  base: `/${store.getters['getLocale'] || ''}`,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const pageTitle = i18n.messages[store.getters['getLocale']]?.meta.title?.[to.name] || '';
  let next_url = null;

  const redirect_path_length = to.query?.redirect_path?.length ?? 0;

  if (redirect_path_length > 0) {
    next_url = to.query?.redirect_path;
  }

  if (next_url) {
    window.NEXT_URL = next_url;
    store.commit('completeSignup/SET_REDIRECT', next_url);
  }
  document.title = pageTitle ? `DocuPet - ${pageTitle}` : 'DocuPet';

  next();
});

export default router;
