<template>
  <b-card bg-variant="muted-warning" text-variant="dark" class="shadow-none">
    <b-card-title v-if="title" :title="title" title-tag="h2" class="font-weight-bold mb-2 text-dark" />
    <b-card-sub-title v-if="terms" sub-title-text-variant="dark" class="mb-2 h6" :sub-title="terms" sub-title-tag="h3" />
    <div>
      <div v-for="(suggestion, key) of suggestedJurisdictions" :key="`suggested-jurisdictions-radio${key}`" class="form-check">
        <div class="form-check-label mb-2 d-flex align-items-start">
          <b-form-radio
            v-model="selectedJurisdictionIdField"
            :disabled="requireAdministrativeAccess && !suggestion.adminHasAccess"
            class="form-check-input "
            type="radio"
            name="suggestion"
            :value="suggestion.id"
          >
            <template #default>
              <span
                :class="[
                  { 'text-danger': requireAdministrativeAccess && !suggestion.adminHasAccess },
                  'font-weight-bold d-flex align-items-center',
                ]"
              >
                <i
                  v-if="showJurisdictionBoundariesMap && suggestion.color"
                  style="width: 1rem; height: 1rem; margin-right: 0.25rem; display: inline-block; border-radius: .25rem; border: 1px solid #ddd;"
                  :style="{ backgroundColor: suggestion.color }"
                ></i>
                {{ suggestion.name }}
              </span>
              <p v-if="suggestion.jurisdictionInformation" class="mb-0 small" v-html="suggestion.jurisdictionInformation"></p>
            </template>
          </b-form-radio>
        </div>
      </div>
    </div>

    <div v-if="fallbackJurisdiction" class="mb-4">
      <div class="d-inline" v-html="nonLicensingHtml"></div>

      <div v-if="nonLicensingLearnMoreHtml" class="mb-2">
        <b-link v-b-toggle.nonLicensingLearnMoreHtml class="text-secondary">
          Learn More <span class="sr-only">about DocuPet here</span>
        </b-link>
      </div>

      <b-collapse id="nonLicensingLearnMoreHtml" class="mb-2">
        <div v-html="nonLicensingLearnMoreHtml"></div>
      </b-collapse>

      <div class="form-check">
        <label class="form-check-label d-flex align-items-center">
          <b-form-radio
            v-model="selectedJurisdictionIdField"
            :disabled="requireAdministrativeAccess && !fallbackJurisdiction.adminHasAccess"
            class="form-check-input"
            type="radio"
            name="suggestion"
            :value="fallbackJurisdiction.id"
          />
          <span class="font-weight-bold mt-1" v-text="localFallbackJurisdictionOptionLabel"></span>
        </label>
      </div>
    </div>

    <dp-jurisdiction-boundaries-map
      v-if="showJurisdictionBoundariesMap"
      ref="jurisdictionBoundariesMap"
      class="w-100 h-100"
      :g-maps-map-id="gMapsMapId"
      :g-maps-api-key="gMapsApiKey"
      :address="address"
      :jurisdiction-boundaries="jurisdictionBoundaries"
    />
  </b-card>
</template>

<script>
import DpJurisdictionBoundariesMap from '../JurisdictionBoundariesMap/JurisdictionBoundariesMap';
import { DpJurisdictionBoundaries } from '../../../index';

export default {
  name: 'DpJurisdictionSuggestionsSelection',
  components: {
    DpJurisdictionBoundariesMap,
  },
  props: {
    preselectJurisdictionId: {
      type: Number,
      default: null,
      required: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    terms: {
      type: String,
      default: '',
    },
    suggestedJurisdictions: {
      type: Array,
      default: () => [],
    },
    nonLicensingHtml: {
      type: String,
      default: '',
    },
    nonLicensingLearnMoreHtml: {
      type: String,
      default: '',
    },
    nonLicensingFallbackOptionLabel: {
      type: String,
      default: null,
    },
    fallbackJurisdiction: {
      type: Object,
      required: false,
      default: null,
    },
    requireAdministrativeAccess: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      required: false,
      default: null,
    },
    jurisdictionBoundaries: {
      type: Object,
      default: null,
      validator: value => value instanceof DpJurisdictionBoundaries,
    },
    gMapsApiKey: {
      type: String,
      required: false,
      default: null,
    },
    gMapsMapId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedJurisdictionId: null,
    };
  },
  computed: {
    showJurisdictionBoundariesMap() {
      return this.gMapsMapId && this.gMapsApiKey && this.jurisdictionBoundaries?.getBoundaries().length > 0;
    },
    selectedJurisdictionIdField: {
      get() {
        return this.selectedJurisdictionId;
      },
      set(newValue) {
        if (newValue === this.selectedJurisdictionId) {
          return;
        }

        this.selectedJurisdictionId = newValue;
        this.$refs?.jurisdictionBoundariesMap?.panToBoundaryForJurisdiction?.(newValue);
        this.$emit('selectionChanged', newValue);
      },
    },
    localFallbackJurisdictionOptionLabel() {
      return this.nonLicensingFallbackOptionLabel ?? this.$t('dp.suggestedJurisdictions.fallbackJurisdictionOptionLabel');
    },
  },
  mounted() {
    if (this.preselectJurisdictionId) {
      this.selectedJurisdictionId = this.preselectJurisdictionId;
    }
  },
};
</script>

<style scoped lang="scss"></style>
