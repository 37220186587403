<template>
  <b-embed type="object" :aspect="aspect">
    <div ref="map" class="w-100 h-100"></div>
  </b-embed>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import { DpJurisdictionBoundaries } from '../../../index';
import { BEmbed } from 'bootstrap-vue';

export default {
  name: 'DpJurisdictionBoundariesMap',
  components: { BEmbed },
  props: {
    aspect: {
      type: String,
      required: false,
      default: '4by3',
      validator: value => ['4by3', '16by9', '21by9', '1by1'].includes(value),
    },
    address: {
      type: String,
      required: true,
    },
    jurisdictionBoundaries: {
      type: Object,
      default: () => {},
      validator: value => value instanceof DpJurisdictionBoundaries,
    },
    gMapsApiKey: {
      type: String,
      required: true,
    },
    gMapsMapId: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: false,
      default: 'US',
    },
  },
  data() {
    return {
      /**
       * @typedef {Object} GoogleGeocoder
       * @property {function} geocode
       *
       * @type {GoogleMap|null}
       **/
      map: null,

      /**
       * @typedef {Object} GoogleMap
       * @property {function} panTo
       * @property {function} getZoom
       * @property {function} setZoom
       * @property {function} getFeatureLayer
       * @property {Object} mapCapabilities
       *
       * @type {GoogleGeocoder|null}
       **/
      geocoder: null,

      /**
       * @typedef {Object} GoogleMarker
       *
       * @type {GoogleMarker|null}
       **/
      marker: null,

      markerFactory: null,
    };
  },
  computed: {
    featureLayerBoundariesMapping() {
      return {
        COUNTRY: this.getBoundariesForFeatureLayer('COUNTRY'),
        ADMINISTRATIVE_AREA_LEVEL_1: this.getBoundariesForFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_1'),
        ADMINISTRATIVE_AREA_LEVEL_2: this.getBoundariesForFeatureLayer('ADMINISTRATIVE_AREA_LEVEL_2'),
        LOCALITY: this.getBoundariesForFeatureLayer('LOCALITY'),
        POSTAL_CODE: this.getBoundariesForFeatureLayer('POSTAL_CODE'),
      };
    },
    showJurisdictionBoundariesMap() {
      return this.gMapsMapId && this.gMapsApiKey && this.jurisdictionBoundaries?.getBoundaries().length > 0;
    },
  },
  async mounted() {
    if (!this.showJurisdictionBoundariesMap) {
      return;
    }
    await this.buildGoogleMap();
  },
  methods: {
    getBoundariesForFeatureLayer(layerName) {
      const GOOGLE_FEATURE_TYPES = {
        COUNTRY: 'COUNTRY',
        ADMINISTRATIVE_AREA_LEVEL_1: 'ADMINISTRATIVE_AREA_LEVEL_1',
        ADMINISTRATIVE_AREA_LEVEL_2: 'ADMINISTRATIVE_AREA_LEVEL_2',
        LOCALITY: 'LOCALITY',
        POSTAL_CODE: 'POSTAL_CODE',
      };

      // Check if the jurisdictionBoundary has a valid externalSourceLayer
      if (!Object.keys(GOOGLE_FEATURE_TYPES).includes(layerName)) {
        throw new RangeError(`Invalid externalSourceLayer: ${layerName}`);
      }

      return this.jurisdictionBoundaries?.getBoundaries().filter(boundary => boundary.externalSourceLayer === layerName);
    },
    async buildGoogleMap() {
      if (!this.gMapsApiKey || !this.gMapsMapId) {
        return;
      }

      await this.loadMapAndGeocoding();
      this.renderJurisdictionBoundaries();
      await this.pinAddressThenPanZoomMap(this.address);
    },
    async loadMapAndGeocoding() {
      const loader = new Loader({
        apiKey: this.gMapsApiKey,
        version: 'weekly',
        libraries: ['maps', 'geocoding', 'marker'],
      });

      const mapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 12,
        mapId: this.gMapsMapId,
        streetViewControl: false,
        mapTypeControl: false,
      };

      const { Geocoder } = await loader.importLibrary('geocoding');
      this.geocoder = new Geocoder({
        region: this.country,
      });

      const { Map } = await loader.importLibrary('maps');
      this.map = new Map(this.$refs.map, mapOptions);

      const { AdvancedMarkerElement } = await loader.importLibrary('marker');

      this.marker = new AdvancedMarkerElement({
        map: this.map,
        position: mapOptions.center,
        title: 'Home Address',
      });
    },
    /** @param {string} address **/
    async pinAddressThenPanZoomMap(address) {
      if (address === null || address === '') {
        return;
      }

      const { results } = await this.geocoder?.geocode({ address });

      const location = results?.[0]?.geometry.location;

      if (!this.marker || !this.map) {
        return;
      }

      this.marker.position = location;

      const zoom = this.map.getZoom();
      this.map.panTo(location);
      this.map.setZoom(zoom);
    },
    renderJurisdictionBoundaries() {
      Object.keys(this.featureLayerBoundariesMapping).forEach(layerName => {
        const featureLayer = this.map.getFeatureLayer(layerName);

        if (!featureLayer) {
          throw new Error(`Feature layer ${layerName} wasn't found`);
        }

        featureLayer.style = options => {
          const boundaries = this.featureLayerBoundariesMapping[layerName];

          const boundary = boundaries.find(boundary => boundary.externalSourceId === options.feature.placeId);

          if (boundary) {
            return {
              fillColor: boundary.fillColor,
              fillOpacity: 0.5,
              strokeColor: boundary.strokeColor,
              strokeWeight: 2,
            };
          }
        };
      });
    },
    async panToBoundaryForJurisdiction(jurisdictionId) {
      const boundary = this.jurisdictionBoundaries?.getBoundaries()?.find(b => b.jurisdictionId === jurisdictionId);

      if (!boundary || !boundary.externalSourceId || boundary.externalSource !== 'GOOGLE') {
        return;
      }

      const { results } = await this?.geocoder?.geocode({ placeId: boundary.externalSourceId });

      const location = results?.[0]?.geometry.location;

      if (!location) {
        return;
      }

      this.map.panTo(location);
      this.map.setZoom(12);
    },
  },
};
</script>

<style lang="scss" scoped></style>
