export default class RequestState {
  constructor() {
    this.loading = false;
    this.errors = [];
    this.data = null;
  }

  start() {
    this.loading = true;
    this.errors = [];
  }

  error(error) {
    this.errors.push(error);
    this.loading = false;
  }

  reset() {
    this.loading = false;
    this.errors = [];
    this.data = null;
  }

  success(data) {
    this.data = data;
    this.loading = false;
  }
}
