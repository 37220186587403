<template>
  <DpContainer class="text-center py-3">
    <h1 class="font-weight-bold">{{ $t('dp.error.title') }}</h1>
    <p class="my-4">
      {{ $t('dp.error.body') }}
    </p>
  </DpContainer>
</template>

<script>
import DpContainer from '../Container/Container.vue';

export default {
  name: 'Error',
  components: { DpContainer },
};
</script>

<style lang="scss" scoped></style>
