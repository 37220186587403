<template>
  <div>
    <base-avatar-image-uploader v-if="showUploader" :accept="acceptedFileTypes" :enable-image-cropper="true" @imageToCrop="imageToCrop" />
    <b-modal
      ref="cropperModal"
      :hide-footer="true"
      :hide-header="true"
      :aria-label="$t('dp.imageCropper.title')"
      size="lg"
      @hidden="resetState"
      @hide="cancel"
      @shown="resetCropper"
    >
      <b-row>
        <b-col class="mb-3 text-center">
          <h1>{{ $t('dp.imageCropper.title') }}</h1>
        </b-col>
      </b-row>
      <!-- image cropper -->
      <b-row v-show="!destination && !uploadError" align-h="center" class="my-2 text-center">
        <b-col cols="12">
          <div v-if="!imageReady" class="text-center">
            <b-spinner />
          </div>
          <cropper
            ref="cropper"
            class="cropper"
            :src="image"
            :canvas="{
              height: 400,
              width: 400,
            }"
            :stencil-props="{
              aspectRatio: 1,
              handlersClasses: {
                default: 'cropper-handler',
              },
              handlers: {
                eastNorth: true,
                north: false,
                westNorth: true,
                west: false,
                westSouth: true,
                south: false,
                eastSouth: true,
                east: false,
              },
              linesClasses: {
                default: 'line-handler',
              },
            }"
            @ready="ready"
            @error="error"
          />
          <div v-if="controls && imageReady && !destination" class="cropper-controls mt-3">
            <button @click="zoomIn"><fa-icon :icon="['fa', 'magnifying-glass-plus']" /></button>
            <button @click="zoomOut"><fa-icon :icon="['fa', 'fa-magnifying-glass-minus']" /></button>
            <button @click="rotateRight"><fa-icon :icon="['fa', 'rotate-right']" /></button>
            <button @click="rotateLeft"><fa-icon :icon="['fa', 'rotate-left']" /></button>
            <button @click="reset"><fa-icon :icon="['fa', 'rotate']" /></button>
          </div>
        </b-col>
      </b-row>

      <!-- error message -->
      <b-row v-show="uploadError" class="text-center justify-content-center">
        <b-col v-show="imageConversion.error" cols="12">
          <p class="text-danger" role="alert">{{ $t(imageConversion.errorMessage) }}</p>
        </b-col>
        <b-col v-show="!imageConversion.error" cols="12">
          <p class="text-danger" role="alert">{{ $t('dp.imageCropper.error') }}</p>
        </b-col>
        <b-col cols="4">
          <b-button class="btn-block mt-2 mb-3" variant="outline-primary" @click="cancel">
            {{ $t('dp.imageCropper.cancel') }}
          </b-button>
        </b-col>
      </b-row>

      <!-- image cropper preview -->
      <b-row v-show="destination && !uploadError" align-h="center" class="my-2 text-center">
        <b-col cols="12">
          <b-img :src="destination" class="img-preview" alt="" fluid center />
        </b-col>
      </b-row>

      <!-- image cropper CTAs -->
      <b-row v-if="!uploadError && imageReady" :class="{ disabled: loading }">
        <b-col class="mt-4 mb-3">
          <b-row align-h="around" class="flex-column-reverse flex-md-row align-items-center">
            <b-col cols="4">
              <b-button v-show="!destination" variant="outline-primary" class="btn-block mt-2" @click="cancel">
                {{ $t('dp.imageCropper.cancel') }}
              </b-button>
              <b-button v-show="destination" variant="outline-primary" class="btn-block" @click="back">
                {{ $t('dp.imageCropper.back') }}
              </b-button>
            </b-col>
            <b-col md="4" cols="6">
              <b-button v-show="!destination" variant="primary" class="btn-block mt-2" @click="crop">
                {{ $t('dp.imageCropper.applyCrop') }}
              </b-button>
              <b-button v-show="destination" variant="primary" class="btn-block" :disabled="loading" @click="addToCard">
                {{ $t('dp.imageCropper.addToCard') }}
                <div v-if="loading" role="status" class="fa-pulse float-right mt-1">
                  <fa-icon :icon="['fas', 'spinner']" class="d-flex" />
                  <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCrop,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';
import BaseAvatarImageUploader from '../../components/BaseComponents/BaseAvatarImageUploader/BaseAvatarImageUploader';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ImageConversionMixin } from '../../mixins/ImageConversionMixin';

library.add(faCrop, faMagnifyingGlassPlus, faMagnifyingGlassMinus, faRotate, faRotateLeft, faRotateRight, faSpinner);

export default {
  name: 'ImageCropper',
  components: {
    BaseAvatarImageUploader,
    Cropper,
  },
  mixins: [ImageConversionMixin],
  props: {
    controls: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    loading: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    showUploader: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      cropper: {},
      imageReady: false,
      destination: null,
      image: null,
      imageId: null,
      uploadError: false,
    };
  },
  computed: {
    acceptedFileTypes() {
      return [...this.imageConversion?.settings?.whitelist, ...this.imageConversion?.settings?.greylist];
    },
  },
  methods: {
    imageToCrop(image, imageId = null) {
      this.$refs.cropperModal.show();
      if (!image) {
        this.uploadError = true;
        return false;
      }

      // Wait for the image to load before setting it in the cropper
      const img = new Image();
      img.onload = () => {
        this.image = image;
        this.imageId = imageId;
      };
      img.onerror = () => {
        this.uploadError = true;
      };
      img.src = image;
    },
    async error() {
      const imageBlob = await this.urlToBlob(this.image);
      if (imageBlob && this.blobNeedsConversion(imageBlob)) {
        await this.convertImage(imageBlob);
      } else {
        this.uploadError = true;
      }
    },
    async convertImage(imageBlob) {
      try {
        this.image = await this.convertBlob(imageBlob);
        this.uploadError = false;
      } catch (e) {
        this.uploadError = true;
      }
    },
    ready() {
      this.imageReady = true;
      //Manually inject the alt text into the image cropper
      this.$refs?.cropper?.$refs?.image?.setAttribute('alt', `${this.$t('dp.imageCropper.title')} Selected Photo`);
      this.$refs?.cropper?.$refs?.stencil?.$el
        ?.querySelector('.vue-preview__image')
        ?.setAttribute('alt', `${this.$t('dp.imageCropper.title')} Preview`);
    },
    zoomIn() {
      this.$refs.cropper.zoom(2);
    },
    zoomOut() {
      this.$refs.cropper.zoom(0.5);
    },
    rotateRight() {
      this.$refs.cropper.rotate(45);
    },
    rotateLeft() {
      this.$refs.cropper.rotate(-45);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    crop() {
      const response = this.$refs.cropper?.getResult();
      if (!response?.canvas) {
        this.uploadError = true;
        return false;
      }
      this.destination = response.canvas.toDataURL('image/png');
    },
    cancel() {
      this.image = null;
      this.imageId = null;
      this.$refs.cropperModal.hide();
    },
    resetCropper() {
      this.$refs.cropper?.reset();
      this.$refs.cropper?.refresh();
    },
    back() {
      this.destination = null;
    },
    resetState() {
      this.destination = null;
      this.uploadError = false;
      this.imageReady = false;
    },
    addToCard() {
      if (this.showUploader) {
        const response = this.$refs.cropper?.getResult();
        if (!response?.canvas) {
          this.uploadError = true;
          return false;
        }
        response.canvas.toBlob(blob => {
          this.$emit('addToCard', blob, this.destination);
        });
      } else {
        this.$emit('base64Image', this.destination, this.imageId, true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/_variables';
.cropper {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 400px;
  max-height: 400px;
  ::v-deep .line-handler {
    border: 1px solid $dp-blue;
  }
  ::v-deep .cropper-handler {
    border: 2px solid $dp-blue;
    border-radius: 50%;
  }
  ::v-deep .vue-simple-handler {
    width: 15px;
    height: 15px;
  }
}
.cropper-controls {
  button {
    background: none;
    border: none;
    font-size: 26px;
  }
}
.disabled {
  pointer-events: none;
}
</style>
