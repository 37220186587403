<template>
  <div>
    <b-skeleton-wrapper :loading="requestState.loading">
      <template #loading>
        <div class="pet-tracking-device-add-to-order-component">
          <div>
            <b-skeleton type="button" width="100%"></b-skeleton>
          </div>
        </div>
      </template>

      <div v-if="localContext" class="pet-tracking-device-add-to-order-component">
        <transition-group name="basket">
          <section
            v-for="basketItem in devicesCurrentlyInBasket"
            :key="`pet-tracking-device-order-item-${basketItem.orderItemId}`"
            class="mb-3 "
          >
            <DeviceOrderItem
              :basket-item="basketItem"
              :disable-state="disableAction"
              @remove-basket-item-success="removeItemFromBasket($event)"
              @remove-basket-item-started="disableActionEvent(true)"
              @remove-basket-item-complete="disableActionEvent(false)"
            />
          </section>
        </transition-group>
        <section v-if="isFormOpen">
          <AddAnotherDeviceForm
            :disable-state="disableAction"
            @create-basket-item-started="disableActionEvent(true)"
            @create-basket-item-success="addItemToBasket($event)"
            @create-basket-item-cancelled="toggleForm(false)"
            @subscription-radio-click="(productId, productLabel) => $emit('subscription-radio-click', productId, productLabel)"
          />
        </section>
        <section v-else>
          <div>
            <b-button class="w-100 bg-white text-primary" variant="secondary" @click="addAnotherDeviceClick">
              {{ startNewOrderItemButtonText }}
            </b-button>
          </div>

          <div v-if="devicesCurrentlyInBasket.length > 0">
            <b-button class="w-100 mt-4" variant="primary" :href="continueToCheckoutUrl" @click="continueButtonClick">
              {{ continueButtonText }}
            </b-button>
          </div>
        </section>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import AddAnotherDeviceForm from './AddAnotherDeviceForm.vue';
import DeviceOrderItem from './DeviceOrderItem.vue';
import PetTrackingDeviceAddToOrderApiService from './../../api/services/PetTrackingDeviceAddToOrderApiService';
import RequestState from './../../utils/requestState';
import PetTrackingDeviceBasketItemModel from './../../models/PetTrackingDeviceBasketItemModel';
import PetTrackingDeviceAddToOrderContext from './../../models/PetTrackingDeviceAddToOrderContext';

export default {
  name: 'PetTrackingDeviceAddToOrder',
  components: { DeviceOrderItem, AddAnotherDeviceForm },
  props: {
    apiUrl: {
      required: true,
      type: String,
    },
    apiToken: {
      required: true,
      type: String,
    },
    initialContext: {
      required: false,
      type: Object,
      default: new PetTrackingDeviceAddToOrderContext({
        subscriptionOptions: [],
        petOptions: [],
        devicesCurrentlyInBasket: [],
        startNewOrderItemButtonText: null,
        continueToCheckoutUrl: null,
        continueButtonText: null,
        deleteOrderItemButtonText: null,
        submitNewOrderItemFormButtonText: null,
        cancelNewOrderItemButtonText: null,
        stepOneText: null,
        stepTwoText: null,
        selectPetText: null,
        selectSubscriptionText: null,
      }),
    },
  },
  data() {
    return {
      apiService: new PetTrackingDeviceAddToOrderApiService(this.apiUrl, this.apiToken),
      /** @type {boolean} **/
      isFormOpen: false,
      /** @type {boolean} **/
      disableAction: false,
      /** @type {PetTrackingDeviceAddToOrderContext} **/
      localContext: null,
      requestState: new RequestState(),
    };
  },
  computed: {
    /** @type {PetTrackingDeviceBasketItemModel[]} **/
    devicesCurrentlyInBasket() {
      return (
        this.localContext?.devicesCurrentlyInBasket?.map(
          basketItem =>
            new PetTrackingDeviceBasketItemModel({
              petName: basketItem.petName,
              displayPrice: basketItem.displayPrice,
              orderItemId: basketItem.orderItemId,
              productLabel: basketItem.productLabel,
            })
        ) ?? []
      );
    },
    /** @type {string}**/
    startNewOrderItemButtonText() {
      return this.localContext?.startNewOrderItemButtonText ?? 'Add another Smart Tracker';
    },
    /** @type {string}**/
    continueButtonText() {
      return this.localContext?.continueButtonText ?? 'View Basket & Checkout';
    },
    continueToCheckoutUrl() {
      return this.localContext?.continueToCheckoutUrl ?? `/basket`;
    },
  },
  watch: {
    devicesCurrentlyInBasket(newValue) {
      if (newValue.length > 0) return;
      if (newValue.length === 0) this.$emit('cart-has-no-devices');
      this.toggleForm(true);
    },
  },
  provide() {
    const _this = this;
    return {
      get apiService() {
        return _this.apiService;
      },
      get context() {
        return _this.localContext;
      },
    };
  },
  async mounted() {
    if (this.initialContext.petOptions.length === 0) {
      await this.refreshContext();
    } else {
      this.localContext = this.initialContext;
    }
  },
  methods: {
    disableActionEvent(disabledState) {
      this.disableAction = disabledState;
      this.$emit('disable-action', disabledState);
    },
    toggleForm(formState) {
      this.isFormOpen = formState;
      if (formState === true) this.$emit('form-open');
      if (formState === false) this.$emit('form-closed');
    },
    addItemToBasket($event) {
      this.toggleForm(false);
      this.localContext = $event;
      this.disableActionEvent(false);
      this.$emit('item-added-to-cart');
    },
    removeItemFromBasket($event) {
      this.$emit('item-removed-from-cart');
      this.localContext = $event;
    },
    addAnotherDeviceClick() {
      this.$emit('add-another-device-click');
      this.toggleForm(true);
    },
    continueButtonClick() {
      this.$emit('continue-button-click');
      this.toggleForm(true);
    },
    async refreshContext() {
      try {
        this.requestState.start();
        this.localContext = await this.apiService.queryPetTrackingDeviceAddToOrderContext();
      } catch (e) {
        this.requestState.error(e.message);
      } finally {
        this.requestState.success(this.localContext);
      }
    },
  },
};
</script>

<style scoped>
.panel {
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.basket-enter-active,
.basket-leave-active {
  transition: all 300ms;
}

.basket-enter {
  opacity: 0;
  transform: translatey(0.5rem);
}

.basket-leave-to /* .basket-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(-0.5rem);
}
</style>
