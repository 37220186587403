<template>
  <ValidationObserver ref="addressForm" v-slot="{ invalid }">
    <b-row>
      <b-col cols="12">
        <b-row v-show="showPOBoxError">
          <b-col>
            <b-card border-variant="warning" class="mb-4">
              <b-card-text class="text-warning" role="alert">{{ $t('dp.errorMessages.poBoxOnly') }} </b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <label class="sr-only" :for="`suggested-address-input-${id}`">
              {{ $t('dp.input.addressSearch.label', { selectedType: selectedTypeToLowerCase }) }}
            </label>
            <vue-simple-suggest
              :id="`suggested-address-input-${id}`"
              ref="addressSuggester"
              v-model="chosen"
              display-attribute="Text"
              value-attribute="Id"
              type="text"
              :name="`suggested-address-${id}`"
              :list="getSuggestionList"
              :min-length="4"
              :styles="autoCompleteStyle"
              :placeholder="$t('dp.input.addressSearch.placeholder', { selectedType: selectedTypeToLowerCase })"
              :debounce="350"
              :nullable-select="true"
              @select="onSuggestClick"
            >
              <div slot="suggestion-item" slot-scope="{ suggestion }" :title="suggestion.Text">
                <div class="text">
                  <span>{{ suggestionDescription(suggestion) }}</span>
                </div>
              </div>
            </vue-simple-suggest>
          </b-col>
        </b-row>

        <b-row v-if="!addressManual">
          <b-col>
            <ValidationProvider
              ref="validationProvider"
              v-slot="{ errors, valid, validated, dirty }"
              rules="required"
              :name="`address-textarea-${id}-group`"
            >
              <b-form-group
                :id="`address-textarea-${id}`"
                :label="`Read-only ${selectedType}`"
                :label-for="`address-textarea-input-${id}`"
                :label-sr-only="true"
              >
                <b-form-textarea
                  :id="`address-textarea-input-${id}`"
                  v-model="addressLabel"
                  :name="`address-textarea-${id}`"
                  rows="3"
                  readonly
                  max-rows="6"
                  class="mt-3"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :state="dirty || validated ? valid : addressLabel ? true : null"
                  :aria-describedby="`address-textarea-input-${id}-invalid-feedback`"
                />
                <b-form-invalid-feedback :id="`address-textarea-input-${id}-invalid-feedback`" role="alert" v-html="errors[0]" />
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row v-if="!addressManual">
          <b-col cols="8">
            <ValidationObserver ref="manualUnitInputObserver">
              <BaseInput
                :id="`home-unit-input-${id}`"
                v-model="home.unit"
                name="home-unit-input"
                type="text"
                :validation-rules="{ unit: true, required: false, max: 16 }"
                :label="`${selectedType} ${$t('dp.input.unit.label')}`"
                :form-text="$t('dp.generic.optional')"
                @input="debounceManualUnitInput"
              />
            </ValidationObserver>
          </b-col>
        </b-row>

        <div v-if="addressManual">
          <ValidationObserver ref="manualFormValidation">
            <h4 class="text-secondary font-weight-bold mt-4">
              {{ $t(manualAddressHeader) }}
            </h4>
            <b-row class="mt-3">
              <b-col sm="4">
                <BaseInput
                  :id="`address-civic-input-${id}`"
                  v-model="home.civic"
                  type="text"
                  :name="`address-civic-${id}`"
                  :label="`${selectedType} ${$t('dp.input.civic.label')}`"
                  :placeholder="$t('dp.input.civic.placeholder')"
                  :validation-rules="{ required: isStreetRequired, max: 16 }"
                  :invalid-class="submitted"
                  @input="updateForm"
                />
              </b-col>
              <b-col>
                <BaseInput
                  :id="`address-street-input-${id}`"
                  v-model="home.street"
                  type="text"
                  :name="`address-street-${id}`"
                  :label="`${selectedType} ${$t('dp.input.street.label')}`"
                  :placeholder="$t('dp.input.street.placeholder')"
                  :validation-rules="{ required: isStreetRequired, max: 64 }"
                  :invalid-class="submitted"
                  @input="updateForm"
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col sm="8">
                <BaseInput
                  :id="`address-unit-input-${id}`"
                  v-model="home.unit"
                  :force-immediate-validation="true"
                  type="text"
                  :name="`address-unit-${id}`"
                  :validation-rules="{ unit: true, required: false, max: 16 }"
                  :label="`${selectedType} ${$t('dp.input.unit.label')}`"
                  :placeholder="$t('dp.input.unit.placeholder')"
                  @input="updateForm"
                />
              </b-col>
            </b-row>

            <b-row v-if="allowAdditionalField || home.additional">
              <b-col>
                <BaseInput
                  :id="`address-additional-input-${id}`"
                  v-model="home.additional"
                  type="text"
                  :name="`address-additional-${id}`"
                  :label="`${selectedType} ${$t('dp.input.additional.placeholder')}`"
                  :validation-rules="{ max: 100 }"
                  @input="updateForm"
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col sm="8">
                <BaseInput
                  :id="`address-city-input-${id}`"
                  v-model="home.city"
                  type="text"
                  :name="`address-city-${id}`"
                  :label="`${selectedType} ${$t('dp.input.city.label')}`"
                  :placeholder="$t('dp.input.city.placeholder')"
                  :validation-rules="{ required: true, max: 50 }"
                  :invalid-class="submitted"
                  @input="updateForm"
                />
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col sm="8">
                <ValidationProvider
                  v-slot="{ errors, valid, validated, dirty }"
                  ref="validationProvider"
                  :name="country === 'US' ? `address-state-input-${id}` : `address-province-input-${id}`"
                  rules="required"
                >
                  <b-form-group
                    :id="`province-input-group-${id}`"
                    :label="`${selectedType} ${country === 'US' ? $t('dp.input.state.label') : $t('dp.input.province.label')}`"
                    :label-for="`province-input-${id}`"
                  >
                    <b-form-select
                      :id="`province-input-${id}`"
                      v-model="home.province"
                      class="no-halo custom-select"
                      :name="country === 'US' ? `address-state-input-${id}` : `address-province-input-${id}`"
                      :options="provinceOrStateOptions"
                      v-bind="$attrs"
                      :class="errors[0] ? 'is-invalid' : ''"
                      :state="dirty || validated ? valid : home.province ? true : null"
                      :aria-describedby="`province-input-${id}-invalid-feedback`"
                      @input="updateForm"
                    />
                    <b-form-invalid-feedback :id="`province-input-${id}-invalid-feedback`" role="alert" v-html="errors[0]" />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col sm="5">
                <BaseInput
                  :id="country === 'US' ? `address-zip-code-input-${id}` : `address-postal-code-input-${id}`"
                  v-model="home.postalCode"
                  :name="country === 'US' ? `address-zip-code-input-${id}` : `address-postal-code-input-${id}`"
                  type="text"
                  :label="`${selectedType} ${country === 'US' ? $t('dp.input.zipCode.label') : $t('dp.input.postalCode.label')}`"
                  :placeholder="country === 'US' ? $t('dp.input.zipCode.placeholder') : $t('dp.input.postalCode.placeholder')"
                  :validation-rules="country === 'US' ? 'required|zipCode' : 'required|postalCode'"
                  :invalid-class="submitted"
                  @input="updateForm"
                />
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <b-button type="submit" variant="primary" block :disabled="invalid" @click="updateManualAddress">
                  {{ $t('dp.addAddress.button') }}
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
      </b-col>
    </b-row>
  </ValidationObserver>
</template>

<script>
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';
import BaseInput from '../BaseComponents/BaseInput/BaseInput';
import debounce from 'lodash.debounce';
import AddressModel from '../../models/AddressModel';

export default {
  name: 'AddressSearch',
  components: {
    BaseInput,
    VueSimpleSuggest,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    country: {
      type: String,
      required: false,
      default: '',
    },
    manualAddressHeader: {
      type: String,
      default: 'dp.addAddress.manualAddressHeader',
    },
    existingAddressLabel: {
      type: String,
      default: '',
    },
    existingAddress: {
      type: Object,
      default: null,
    },
    prefixCountryWithProvince: {
      type: Boolean,
      default: false,
    },
    allowAdditionalField: {
      type: Boolean,
      default: false,
    },
    selectedType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chosen: '',
      addressId: '',
      addressLabel: this.existingAddressLabel,
      mailingAddressIsSame: true,
      addressManual: false,
      autoCompleteStyle: {
        vueSimpleSuggest: 'position-relative',
        inputWrapper: '',
        defaultInput: 'form-control',
        suggestions: 'position-absolute list-group z-1000',
        suggestItem: 'list-group-item',
      },
      stateOptions: [
        { value: null, text: 'Please select a state' },
        { value: 'US_AL', text: `Alabama` },
        { value: 'US_AK', text: `Alaska` },
        { value: 'US_AZ', text: `Arizona` },
        { value: 'US_AR', text: `Arkansas` },
        { value: 'US_CA', text: `California` },
        { value: 'US_CO', text: `Colorado` },
        { value: 'US_CT', text: `Connecticut` },
        { value: 'US_DE', text: `Delaware` },
        { value: 'US_FL', text: `Florida` },
        { value: 'US_GA', text: `Georgia` },
        { value: 'US_HI', text: `Hawaii` },
        { value: 'US_ID', text: `Idaho` },
        { value: 'US_IL', text: `Illinois` },
        { value: 'US_IN', text: `Indiana` },
        { value: 'US_IA', text: `Iowa` },
        { value: 'US_KS', text: `Kansas` },
        { value: 'US_KY', text: `Kentucky` },
        { value: 'US_LA', text: `Louisiana` },
        { value: 'US_ME', text: `Maine` },
        { value: 'US_MD', text: `Maryland` },
        { value: 'US_MA', text: `Massachusetts` },
        { value: 'US_MI', text: `Michigan` },
        { value: 'US_MN', text: `Minnesota` },
        { value: 'US_MS', text: `Mississippi` },
        { value: 'US_MO', text: `Missouri` },
        { value: 'US_MT', text: `Montana` },
        { value: 'US_NE', text: `Nebraska` },
        { value: 'US_NV', text: `Nevada` },
        { value: 'US_NH', text: `New Hampshire` },
        { value: 'US_NJ', text: `New Jersey` },
        { value: 'US_NM', text: `New Mexico` },
        { value: 'US_NY', text: `New York` },
        { value: 'US_NC', text: `North Carolina` },
        { value: 'US_ND', text: `North Dakota` },
        { value: 'US_OH', text: `Ohio` },
        { value: 'US_OK', text: `Oklahoma` },
        { value: 'US_OR', text: `Oregon` },
        { value: 'US_PA', text: `Pennsylvania` },
        { value: 'US_RI', text: `Rhode Island` },
        { value: 'US_SC', text: `South Carolina` },
        { value: 'US_SD', text: `South Dakota` },
        { value: 'US_TN', text: `Tennessee` },
        { value: 'US_TX', text: `Texas` },
        { value: 'US_UT', text: `Utah` },
        { value: 'US_VT', text: `Vermont` },
        { value: 'US_VA', text: `Virginia` },
        { value: 'US_WA', text: `Washington` },
        { value: 'US_WV', text: `West Virginia` },
        { value: 'US_WI', text: `Wisconsin` },
        { value: 'US_WY', text: `Wyoming` },
      ],
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: 'CA_AB', text: `Alberta` },
        { value: 'CA_BC', text: `British Columbia` },
        { value: 'CA_MB', text: `Manitoba` },
        { value: 'CA_NB', text: `New Brunswick` },
        { value: 'CA_NL', text: `Newfoundland and Labrador` },
        { value: 'CA_NT', text: `Northwest Territories` },
        { value: 'CA_NS', text: `Nova Scotia` },
        { value: 'CA_NU', text: `Nunavut` },
        { value: 'CA_ON', text: `Ontario` },
        { value: 'CA_PE', text: `Prince Edward Island` },
        { value: 'CA_QC', text: `Quebec` },
        { value: 'CA_SK', text: `Saskatchewan` },
        { value: 'CA_YT', text: `Yukon` },
      ],
      submitted: false,
      home: new AddressModel(
        this.existingAddress?.unit || undefined,
        this.existingAddress?.civic || undefined,
        this.existingAddress?.street || undefined,
        this.existingAddress?.additional || undefined,
        this.existingAddress?.cityName || undefined,
        this.existingAddress ? (this.prefixCountryWithProvince ? this.country + '_' : '') + this.existingAddress?.province : undefined,
        undefined,
        this.existingAddress?.postal || undefined
      ),
      showPOBoxError: false,
    };
  },
  computed: {
    provinceOrStateOptions() {
      return this.country === 'US' ? this.stateOptions : this.provinceOptions;
    },
    addressValid() {
      return this.existingAddress !== '' || this.hasAllFields;
    },
    hasAllFields() {
      return this.home.city && this.home.civic && this.home.street && this.home.postalCode && this.home.province && this.addressLabel;
    },
    isStreetRequired() {
      return !this.home.additional || this.home.additional === '' || !this.allowAdditionalField;
    },
    selectedTypeToLowerCase() {
      return this.selectedType?.length ? this.selectedType.toLowerCase() : this.$t('dp.input.addressSearch.placeholderAddress');
    },
  },
  mounted() {
    // Resolve vue-simple-suggest accessibility issues
    const addressSuggesterInput = this.$refs?.addressSuggester?.$refs?.inputSlot;

    addressSuggesterInput?.setAttribute(
      'aria-label',
      `${this.$t('dp.input.addressSearch.placeholder', { selectedType: this.selectedTypeToLowerCase }).toString()} Suggester`
    );
    addressSuggesterInput?.setAttribute('id', 'suggested-address-combobox');
  },
  methods: {
    debounceManualUnitInput: debounce(async function() {
      const manualUnitInputValid = await this.$refs.manualUnitInputObserver?.validate();

      if (!manualUnitInputValid) {
        return;
      }

      this.updateForm();
    }, 700),
    updateAddress(address) {
      this.$emit('updateAddress', address);
      this.handleAddressMissingFields();
    },
    updateForm() {
      if (!this.addressManual) {
        this.updateAddress(this.home);
      }
    },
    async updateManualAddress() {
      const validationResult = await this.$refs.manualFormValidation.validate();

      if (!validationResult) {
        return;
      }

      this.updateAddress(this.home);
      this.addressManual = false;
      this.updateAddressLabel();
      this.$emit('addingManualAddress', this.addressManual);
    },
    suggestionDescription(suggestion) {
      return suggestion.Text + ' ' + suggestion.Description;
    },
    getSuggestionList(inputValue) {
      return new Promise(resolve => {
        let url = `https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?Key=BM74-WJ79-TA45-FW32&Text=${inputValue}&IsMiddleware=true&Origin=${this.country}&Countries=${this.country}`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            let addresses = data.Items.filter(address => {
              return address.Type === 'Address';
            });

            addresses.unshift({
              Text: 'Add Address Manually',
              Description: '',
              Id: 'Manual',
            });

            resolve(addresses);
          });
      });
    },
    showManualAddressForm() {
      this.addressId = 'Manual';
      this.addressManual = true;
    },
    validateAddressForm() {
      this.$nextTick(() => {
        this.$refs?.addressForm?.validate();
      });
    },
    handleAddressMissingFields() {
      if (this.hasAllFields) {
        return;
      }

      this.showManualAddressForm();
      this.validateAddressForm();
    },
    onSuggestClick(suggest) {
      if (!suggest) {
        return;
      }

      this.addressId = suggest.Id;
      this.showPOBoxError = false;
      this.addressManual = false;

      if (this.addressId === 'Manual') {
        this.addressManual = true;

        if (this.home.province && !this.home.province.includes('_')) {
          this.home.province = this.country + '_' + this.home.province;
        }

        this.$emit('addingManualAddress', this.addressManual);

        this.$nextTick(() => {
          this.$refs.addressSuggester.setText('');
        });

        return;
      }

      this.$emit('addingManualAddress', this.addressManual);

      return new Promise(resolve => {
        let url = `https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?Key=BM74-WJ79-TA45-FW32&Id=${this.addressId}`;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            let addressData = data.Items[0];
            this.extractForm(addressData);
            this.updateAddress(this.home);
            resolve(addressData);
          });
      });
    },
    extractForm(addressData) {
      let postal = addressData.CountryIso2 === 'US' ? addressData.PostalCode.substring(0, 5) : addressData.PostalCode;

      this.home.unit = addressData.SubBuilding || addressData.BuildingName;
      this.home.city = addressData.City;
      this.home.civic = addressData.BuildingNumber;
      this.home.street = addressData.Street;
      this.home.postalCode = postal;
      this.home.province = addressData.ProvinceCode ? this.country + '_' + addressData.ProvinceCode : '';
      this.addressLabel = addressData.Label;
      this.home.additional = addressData.POBoxNumber ? addressData.Line1 : '';

      if (addressData.POBoxNumber && !this.allowAdditionalField) {
        this.addressManual = true;
        this.showPOBoxError = true;
      }
    },
    updateAddressLabel() {
      let addressLabel = '';

      if (this.home.civic) {
        addressLabel += `${this.home.civic} `;
      }

      if (this.home.street) {
        addressLabel += `${this.home.street} `;
      }

      if (this.home.unit) {
        addressLabel += `${this.home.unit} `;
      }

      if (addressLabel !== '') {
        addressLabel += `\n`;
      }

      if (this.home.additional) {
        addressLabel += `${this.home.additional} \n`;
      }

      if (this.home.city) {
        addressLabel += `${this.home.city} `;
      }

      if (this.home.province) {
        const provinceCode = this.home.province.split('_').pop();
        addressLabel += `${provinceCode} `;
      }

      if (this.home.postalCode) {
        addressLabel += this.home.postalCode;
      }

      this.addressLabel = addressLabel;
    },
  },
};
</script>

<style scoped></style>
