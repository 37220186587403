<template>
  <b-row :no-gutters="fullWidth">
    <b-col v-bind="columnsAndOffsetsFromProps ? columnsAndOffsetsFromProps : columnsAndOffsetsDefault" class="panel-wrapper" :class="{'px-0' : fullWidth}">

      <b-row v-if="logoUrl !== null">
        <b-col cols="4" offset="4">
          <b-img center block fluid :src="logoUrl" alt="" class="mb-4" />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <slot name="header"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <slot name="content"/>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'DpPanel',
  props: {
    logoUrl: {
      type: String,
      required: false,
      default: null,
    },
    columnsAndOffsetsFromProps: {
      type: Object,
      required: false,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      columnsAndOffsetsDefault: {
        cols: 12,
        sm: 12,
        md: 8,
        lg: 6,
        xl: 4,
        offsetMd: 2,
        offsetLg: 3,
        offsetXl: 4
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables';
.panel-wrapper {
  background-color: $dp-white;
  padding: 3rem;
  margin-bottom: 3rem;
  @media screen and (min-width: 768px) {
    border-radius: 8px;
    box-shadow: 1px 2px 3px $dp-shadow-1;
  }
}
</style>