<template>
  <div>
    <b-alert
      :class="notification.class"
      :dismissible="notification.dismissible"
      :show="dismissSecs === -1 || dismissCountDown"
      :variant="notification.type"
      class="mb-0"
      fade
      @dismissed="removeNotification"
      @dismiss-count-down="countDownChanged"
    >
      <fa-icon
        v-if="notification.icon"
        :class="notification.icon.class"
        :icon="notification.icon.icon"
        :size="notification.icon.size"
        :style="notification.icon.style"
      />
      <span :class="notification.text ? notification.text.class : null">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="notification.message && notification.isHtmlMessage" v-html="notification.message" />
        <span v-else>{{ notification.message }}</span>
      </span>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'NotificationBar',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // If dismissSecs = -1 (Infinite)
      dismissSecs: this.notification?.dismissSecs || 5,
      dismissCountDown: 0,
    };
  },
  watch: {
    dismissCountDown: function(value) {
      if (value === 0 && this.notification.callback) this.notification.callback(this.notification.$callback);
    },
  },
  mounted() {
    this.showAlert();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    removeNotification() {
      this.$emit('removeNotification', this.notification.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
