// import en from 'vee-validate/dist/locale/en.json';

export default {
  login: {
    title: "S'identifier",
    subheading: "Vous n'avez pas de compte?",
    buttonText: "S'identifier",
    errorMessages: {
      default: 'Votre e-mail ou votre mot de passe ne correspondent pas. {br} Veuillez réessayer.',
      session_expired: 'Vous avez été inactif pendant un certain temps, votre session a donc expiré. Veuillez vous reconnecter.',
    },
  },
  storeLogin: {
    title: 'Complétez votre commande',
    subheading:
        "C'est plus qu'une simple balise ou un tracker; c'est le billet de retour de votre animal. Pour maximiser les avantages de notre service HomeSafe pour animaux perdus, nous avons besoin de certaines informations essentielles sur vous et votre animal de compagnie. Connectez-vous ou inscrivez-vous pour finaliser votre commande.",
    buttonTextSignUp: 'S\'inscrire',
    buttonTextLogIn: "S'identifier",
  },
  forgotPassword: {
    title: 'Mot de passe oublié?',
    info: 'Aucun problème! Nous vous enverrons par e-mail un lien pour réinitialiser votre mot de passe afin que vous puissiez vous connecter à votre compte.',
    buttonText: 'Réinitialiser le mot de passe',
    onSuccess: {
      title: 'Presque fini!',
      info: 'Si vous avez un compte, nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.',
    },
  },
  resetPassword: {
    title: 'Réinitialisez votre mot de passe',
    subheading: 'Presque terminé, créez un nouveau mot de passe ci-dessous.',
    buttonText: 'Réinitialiser le mot de passe',
    password: {
      title: 'Nouveau mot de passe',
    },
    passwordConfirm: {
      placeholder: 'Retaper le mot de passe',
      title: 'Confirmer le nouveau mot de passe',
    },
    onSuccess: {
      title: 'Succès!',
      infoText: 'Votre mot de passe a été mis à jour.',
      buttonText: 'Revenir à la connexion',
    },
    errorMessages: {
      default: 'Un problème est survenu lors de la réinitialisation du mot de passe. {br} Veuillez réessayer.',
      currentPassword: 'Le nouveau mot de passe ne peut pas être identique au mot de passe actuel',
      mismatch: 'La confirmation du mot de passe ne correspond pas',
    },
  },
  completeActivation: {
    loading: {
      title: 'Récupérer votre dossier',
      subheading: 'Vous pourrez créer votre compte en ligne très prochainement',
    },
    title: 'Salut {firstName}!',
    subheading: "Nous avons votre dossier! Il ne vous reste plus qu'à créer votre compte en ligne.",
    legalText: {
      prepend: "En vous inscrivant, vous acceptez DocuPet's",
      links: { termsOfService: "Conditions d'utilisation", conjunction: 'et', privacyPolicy: 'Politique de confidentialité' },
    },
    buttonText: 'Inscription complète',
  },
  fetchMyRecord: {
    title: 'Récupérer mon dossier',
    buttonText: {
      primary: 'Récupérer mon dossier',
    },
    byAccessCode: {
      title: "J'ai un code d'accès de DocuPet",
      subheading: "J'ai un code d'accès provenant d'une lettre ou d'un e-mail",
      buttonText: {
        secondary: "J'utiliserai le numéro d'étiquette de mon animal",
      },
      errorMessages: {
        invalid_access_code:
          "Code d'accès ou code postal incorrect. Veuillez vérifier votre courrier électronique ou votre lettre pour confirmer les informations correctes.",
        access_code_used: 'On dirait que vous vous êtes déjà inscrit! {br} {action}',
        default: 'Un problème est survenu lors de la récupération de votre enregistrement. Veuillez réessayer.',
        action: {
          access_code_used: 'Cliquez ici pour vous identifier',
        },
      },
    },
    byTagNumber: {
      title: "J'utiliserai le numéro de licence actuel de mon animal",
      subheading: "J'ai le numéro d'étiquette de mon animal",
      buttonText: {
        secondary: "J'utiliserai mon code d'accès",
      },
      errorMessages: {
        invalid_tag_number:
          "Numéro d'étiquette ou nom de l'animal ou code postal ou numéro de téléphone incorrect. Veuillez vérifier votre courrier électronique ou votre lettre pour confirmer les informations correctes.",
        access_code_used: 'On dirait que vous vous êtes déjà inscrit! {br} {action}',
        default: 'Un problème est survenu lors de la récupération de votre enregistrement. Veuillez réessayer.',
        action: {
          access_code_used: 'Cliquez ici pour vous identifier',
        },
      },
    },
  },
  requestAccessCode: {
    title: "Demander un code d'accès",
    subheading: "Nous pourrons peut-être vous envoyer un nouveau code d'accès par e-mail. Entrez votre adresse e-mail ci-dessous.",
    buttonText: 'Continuer',
    onSuccess: {
      title: 'Presque fini!',
      infoText:
        "Nous avons envoyé un code d'accès à {email} avec des instructions pour accéder à votre compte. Si l'e-mail n'arrive pas bientôt, veuillez vérifier votre dossier spam",
      buttonText: 'Retourner pour récupérer mon dossier',
    },
    errorMessages: {
      invalid_email:
        'Nous ne pouvons pas trouver cette adresse e-mail dans notre système. Veuillez vérifier que vous entrez la bonne adresse e-mail.',
      access_code_used: 'On dirait que vous vous êtes déjà inscrit! {action}',
      default: "Un problème est survenu lors de l'envoi d'un e-mail. Veuillez réessayer.",
      action: {
        access_code_used: 'Cliquez ici pour vous identifier',
      },
    },
  },
  input: {
    email: {
      label: 'Adresse électronique',
      placeholder: "Entrer l'adresse e-mail",
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Tapez votre mot de passe',
      buttonText: {
        show: 'Montrer',
        hide: 'Cacher',
      },
    },
    newPassword: {
      label: 'Nouveau mot de passe',
      placeholder: 'Créer un nouveau mot de passe',
    },
    passwordConfirm: {
      label: 'Confirmer le nouveau mot de passe',
      placeholder: 'Ré-entrez le nouveau mot de passe',
    },
    accessCode: {
      label: "Code d'accès",
      placeholder: "Entrez le code d'accès (par exemple FAB12345)",
    },
    postalCode: {
      label: 'Code Postal',
      placeholder: 'Entrez le code postal',
    },
    tagNumber: {
      label: "Numéro d'étiquette",
      placeholder: 'Entrez le numéro de tag (par exemple A1B27890)',
      aboveLabel:
        "Le numéro d'étiquette et deux des trois champs suivants doivent correspondre pour pouvoir accéder à votre dossier de licence.",
      errorMessages: {
        tag_number_required: "Veuillez entrer exactement comme indiqué sur l'étiquette.",
      },
    },
    petName: {
      label: "Nom d'animal domestique",
      placeholder: "Entrez le nom de l'animal",
    },
    phoneNumber: {
      label: 'Numéro de téléphone',
      placeholder: 'Entrez le numéro de téléphone',
      customErrorMessage: 'Please enter your ten digit phone number',
    },
  },
  checkbox: {
    eula: "Je suis d'accord",
  },
  link: {
    accessCode: {
      dontHave: "Vous n'avez pas de code d'accès?",
    },
    signUp: "S'inscrire",
    forgotPassword: 'Mot de passe oublié?',
    login: {
      return: 'Revenir à la connexion',
    },
    fetchMyRecord: {
      return: 'Retourner pour récupérer mon dossier',
    },
  },
  validations: {
    messages: {
      required: 'req',
    },
  },
  footer: {
    copyright: 'DocuPet Corp. Tous droits réservés.',
    links: {
      about: 'About DocuPet',
      tos: "Conditions d'utilisation",
      privacy: 'Politique de confidentialité',
    },
  },
  meta: {
    title: {
      LogIn: "S'identifier",
      ForgotPassword: 'Mot de passe oublié?',
      CompleteSignup: 'Complétez votre inscription',
      RequestAccessCode: "Demander un code d'accès",
      ResetPassword: 'Réinitialisez votre mot de passe',
      FetchMyRecordByPetTag: 'Récupère mon dossier',
      FetchMyRecordByAccessCode: 'Récupère mon dossier',
    },
  },
};
