<template>
  <b-row>
    <b-col>
      <dp-loading v-if="removeProofLoading" />

      <div v-show="!removeProofLoading">
        <!-- Rabies Uploader -->
        <b-row v-show="requiresRabiesDoc">
          <b-col>
            <ValidationObserver v-show="!rabiesUploadSuccess || !rabiesUploadAttempted" ref="form">
              <dp-date-input
                v-model="form.rabiesExp"
                date-type="rabies"
                :original-date="pet.rabiesExpireDate"
                :validate-on-load="true"
                :date-required="true"
                :allow-past-rabies="allowPastRabies"
                :timezone="timezone"
                :label="$t('dp.rabiesProofUploader.label', { petName: pet.name })"
                :sub-label="$t('dp.rabiesProofUploader.subLabelNew', { pronoun: petPossessivePronoun, new: 'new' })"
                @existingDateError="existingDateError"
              />

              <dp-base-file-upload
                :label="$t('dp.rabiesProofUploader.helpText')"
                :class="'upload-button ' + (rabiesUploadAttempted ? 'upload-complete' : '')"
                :disabled="disableUpload"
                :validation-errors="rabiesUploadError"
                @upload="uploadRabies"
              >
                <template #button>
                  {{ $t('dp.buttons.uploadProof') }}
                  <div v-show="rabiesUploadClicked || loadingRabiesProof" role="status" class="fa-pulse float-right">
                    <fa-icon :icon="['fal', 'spinner']" />
                    <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
                  </div>
                </template>
              </dp-base-file-upload>
            </ValidationObserver>
          </b-col>
        </b-row>

        <!-- Rabies Document Preview -->
        <div v-if="showUploadedRabies && !requiresRabiesDoc">
          <b-row v-if="rabiesVaccinationDate">
            <b-col>
              <p>
                <span class="font-weight-bold text-primary">Rabies Vaccination Expiration Date:</span>
                {{ $d(rabiesVaccinationDate, 'short') }}
              </p>
            </b-col>
          </b-row>

          <b-row v-if="latestUploadedRabiesProof.document">
            <b-col v-if="isExistingProofPdf">
              <pdf-viewer :pdf-path="getImageUtil(latestUploadedRabiesProof.document.webPath)" />
            </b-col>
            <b-col v-else>
              <b-card no-body>
                <b-card-img :src="getImageUtil(latestUploadedRabiesProof.document.webPath)" alt=""> </b-card-img>
              </b-card>
            </b-col>
          </b-row>

          <div v-if="removeUploadedRabies">
            <b-row>
              <b-col>
                <p>
                  <b-button variant="link" class="p-0" @click="removeRabies">
                    {{ $t('dp.buttons.remove') }}
                  </b-button>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="mb-2">{{ $t('uploadDocs.proofUploadVerification.verifyWarning') }}</p>
                <ul>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyPoName') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyPetName') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyBreed') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyGiven') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyDate') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyManu') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyLot') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyGivenDate') }}</li>
                  <li class="mb-1">{{ $t('uploadDocs.proofUploadVerification.verifyVet') }}</li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import DpBaseFileUpload from '../BaseComponents/BaseFileUpload/BaseFileUpload';
import DpDateInput from '../DateInput/DateInput';
import DpLoading from '../Loading/Loading';
import { getImageUtil } from '../../utils/getImageUtil';
import PdfViewer from '../PDFViewer/PdfViewer';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faCheckCircle, faFilePdf } from '@fortawesome/pro-light-svg-icons';

library.add(faSpinner, faCheckCircle, faFilePdf);

import { DateTime } from 'luxon';

export default {
  name: 'DpRabiesUploader',
  components: {
    DpBaseFileUpload,
    DpDateInput,
    DpLoading,
    PdfViewer,
  },
  props: {
    pet: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    loadingRabiesProof: {
      type: Boolean,
      default: false,
    },
    removeProofLoading: {
      type: Boolean,
      default: false,
    },
    rabiesUploadSuccess: {
      type: Boolean,
      default: false,
    },
    rabiesUploadError: {
      type: Object,
      default: null,
    },
    noProofRequired: {
      type: Boolean,
      default: false,
    },
    requiresRabiesDoc: {
      type: Boolean,
      default: true,
    },
    allowPastRabies: {
      type: Boolean,
      default: false,
    },
    showUploadedRabies: {
      type: Boolean,
      default: false,
    },
    removeUploadedRabies: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rabiesUploadAttempted: false,
      rabiesUploadClicked: false,
      form: {
        rabiesExp: '',
      },
      dateInvalid: false,
    };
  },
  computed: {
    today() {
      return new DateTime.now().setZone(this.timezone);
    },
    baseUrl() {
      return global.BASE_URL || process.env.VUE_APP_BASE_URL;
    },
    petPossessivePronoun() {
      return this.pet?.gender === 'FEMALE' ? 'her' : 'his';
    },
    latestUploadedRabiesProof() {
      return this.pet?.mostRecentRabiesProof ?? null;
    },
    isExistingProofPdf() {
      return this.latestUploadedRabiesProof?.document?.webPath?.includes('.pdf') ?? false;
    },
    disableUpload() {
      return (
        !this.form.rabiesExp ||
        new DateTime.fromFormat(`${this.form.rabiesExp} ${this.timezone}`, 'yyyy-M-d z') < this.today ||
        this.loadingRabiesProof
      );
    },
    rabiesVaccinationDate() {
      return this.latestUploadedRabiesProof
        ? new DateTime.fromISO(this.latestUploadedRabiesProof.expirationDate, { zone: this.timezone })
        : null;
    },
  },
  methods: {
    getImageUtil,
    existingDateError(val) {
      this.dateInvalid = val;
    },
    uploadRabies(file) {
      if (this.dateInvalid) {
        return;
      }
      this.rabiesUploadClicked = true;

      let datePayload =
        this.form.rabiesExp !== ''
          ? DateTime.fromFormat(`${this.form.rabiesExp} ${this.timezone}`, 'yyyy-M-d z')
          : DateTime.fromISO(this.pet.rabiesExpireDate).setZone(this.timezone);

      this.$emit('uploadRabies', { file, datePayload });

      this.rabiesUploadAttempted = true;
      this.rabiesUploadClicked = false;
    },
    removeRabies() {
      this.$emit('removePetProof');
      this.rabiesUploadAttempted = false;
    },
  },
};
</script>

<style scoped></style>
