<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <BaseFetchMyRecord current-screen="ByTagNumber">
        <b-row v-if="getOrganizationOldTagImage" class="mb-4">
          <b-col>
            <b-img fluid center class="pet-tag-img" alt="HomeSafe Pet Tag ID containing the address and phone number to report the pet as well as the ID number of the pet" :src="require('@/assets/DocuPetTag.png')" />
          </b-col>
          <b-col>
            <b-img
              fluid
              center
              class="pet-tag-img"
              alt=""
              :src="getOrganizationOldTagImage"
            />
          </b-col>
        </b-row>

        <p class="font-weight-bold">{{ $t('input.tagNumber.aboveLabel') }}</p>

        <DpBaseInput
          id="tag-number-input"
          v-model.trim="tagNumber"
          name="tag-number"
          type="text"
          autocomplete="off"
          validation-rules="required"
          class="sentry-mask"
          :autofocus="true"
          :formatter="_.toUpper"
          :label="'input.tagNumber.label' | translate"
          :placeholder="'input.tagNumber.placeholder' | translate"
        >
          <template #label="{ props: { label } }">
            <label class="input-label w-100" for="tag-number-input">
              {{ label }}
              <b-link v-b-modal.modal-pet-tag-number-info title="What is my pet's current license tag number?">
                <font-awesome-icon :icon="['fas', 'info-circle']" class="ml-1 text-primary" size="lg" />
              </b-link>
              <b-modal
                id="modal-pet-tag-number-info"
                title="What is my pet's current license tag number?"
                ok-only
                ok-variant="outline-primary"
                ok-title="Close"
              >
                We typically have previous licensing records that include pet license tag numbers. If your pet is wearing a tag, use the
                number found on it here. If this information along with the pet's name, your ZIP code and your phone number match one of
                your records, we can match your record! You can then create a DocuPet account and you will see your license information in
                your online profile.
              </b-modal>
            </label>
          </template>

          <template #belowField>
            <p v-if="getOrganizationOldTagNumberFormatDescription" class="mt-2 small">
              {{ getOrganizationOldTagNumberFormatDescription }}
            </p>
          </template>
        </DpBaseInput>

        <DpBaseInput
          id="pet-name-input"
          v-model.trim="petName"
          name="pet-name"
          type="text"
          autocomplete="name"
          validation-rules="required"
          :label="'input.petName.label' | translate"
          :placeholder="'input.petName.placeholder' | translate"
        />

        <DpBaseInput
          id="zip-code-input"
          v-model.trim="postalCode"
          name="zip-code"
          type="text"
          :formatter="_.toUpper"
          autocomplete="postal-code"
          validation-rules="required"
          :label="'input.postalCode.label' | translate"
          :placeholder="'input.postalCode.placeholder' | translate"
        />

        <DpBaseInput
          id="phone-number-input"
          v-model.trim="phoneNumber"
          name="phone-number"
          type="tel"
          inputmode="numeric"
          autocomplete="tel-national"
          validation-rules="required|phoneNumber"
          pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
          class="sentry-mask"
          :formatter="phoneNumberFormatter"
          :label="'input.phoneNumber.label' | translate"
          :placeholder="'input.phoneNumber.placeholder' | translate"
        />

        <template #footerMessage>
          <div v-if="hasErrors" class="bg-info mt-n3 mb-4 p-3 py-4">
            <p><BaseDisplayMessage v-if="getErrorMessage" class="mt-3 text-dark" :message="getErrorMessage" role="alert" /></p>
            <p v-if="getErrors != 'access_code_used'">
              <strong>If you have received an Access Code</strong> from us, you can enter it here. Or if you think that
              {{ getCityOrganization }} would have an email address associated with your previous license record, you can request an Access
              code <b-link :to="{ name: 'FetchMyRecordByAccessCode', query: $router.currentRoute.query }" class="text-decoration-underline">here</b-link>.
            </p>
            <p v-html="getCustomCareSupportText" />
            <p>
              Learn more about how we source existing records <b-link v-b-modal.modal-how-we-source-existing-records-info class="text-decoration-underline">here</b-link>.
            </p>
          </div>
        </template>

        <template #footer>
          <b-button class="mt-0 w-100" type="submit" variant="primary" :disabled="!valid || !isButtonEnabled || isLoading">
            {{ 'fetchMyRecord.buttonText.primary' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>
        </template>
      </BaseFetchMyRecord>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput } from '@docupet/component-library';

export default {
  name: 'FetchMyRecordByTagNumber',
  components: {
    DpBaseInput,
  },
  data: () => ({
    tagNumber: '',
    petName: '',
    postalCode: '',
    phoneNumber: '',
    isButtonEnabled: true,
  }),
  computed: {
    ...mapGetters('city', ['getCityOrganization', 'getOrganizationOldTagImage', 'getOrganizationOldTagNumberFormatDescription', 'getCityCustomerSupportContext']),
    ...mapGetters('fetchMyRecord', ['isLoading', 'isSuccess', 'hasErrors', 'getErrors']),
    contentsBlank: ({ tagNumber, petName, postalCode, phoneNumber } = this) =>
      tagNumber == '' || petName == '' || postalCode == '' || phoneNumber == '',
    getErrorMessage: function () {
      if (this.getErrors == 'access_code_used') {
        return {
          path: 'fetchMyRecord.byTagNumber.errorMessages.access_code_used',
          action: {
            to: { name: 'LogIn' },
            text: this.$t('fetchMyRecord.byTagNumber.errorMessages.action.access_code_used'),
          },
        };
      } else {
        return 'We are unable to find an exact match for these entries within our records. Please ensure that you have entered the information correctly, and consider trying alternate phone numbers.';
      }
    },
    getCustomCareSupportText: function () {
      const context = this.getCityCustomerSupportContext;
      if (context?.customCareSupportText?.text) {
        return `If these options don't work for you, we're sorry for the inconvenience. ${context.customCareSupportText.text}`;
      }
      return '';
    },
  },
  created: function () {
    this.$store.commit('fetchMyRecord/RESET_ERROR');
  },
  methods: {
    ...mapActions('fetchMyRecord', ['tryLoginByTagNumber']),
    async onSubmit({ tagNumber, petName, postalCode, phoneNumber } = this) {
      this.isButtonEnabled = false;
      this.tryLoginByTagNumber({ tagNumber, petName, postalCode, phoneNumber: phoneNumber.replace(/[^\d]/g, '') })
        .then(() => {
          this.$router.push({ name: 'CompleteSignup', query: this.$router.currentRoute.query });
          window.dataLayer?.push({ event: 'activateByTagNumber' });
        })
        .catch(() => {
          requestAnimationFrame(() => {
            this.$refs.form.reset();
          });
        })
        .finally(() => {
          this.isButtonEnabled = true;
        });
    },
    phoneNumberFormatter: function (phone) {
      let x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
  },
};
</script>

<style scoped>
.pet-tag-img {
  max-height: 180px;
  width: auto;
}
</style>
