import BaseModel from './BaseModel';

export default class PetTrackingDeviceBasketItemModel extends BaseModel {
  constructor({ petName, orderItemId, displayPrice, productLabel }) {
    super();
    /** @type {string} **/
    this.petName = petName;
    /** @type {number} **/
    this.orderItemId = orderItemId;
    /** @type {string} **/
    this.displayPrice = displayPrice;
    /** @type {string} **/
    this.productLabel = productLabel;
  }
}
