<template>
  <ValidationProvider
    v-slot="validationContext"
    ref="validationProvider"
    :name="name || label.toLowerCase()"
    :rules="validationRules"
    :bails="bails"
    :vid="vid"
  >
    <b-form-group :label-for="$attrs.id">
      <b-form-checkbox
        :id="`${$attrs.id}-checkbox`"
        v-model="checked"
        :name="name"
        :size="size"
        v-bind="$attrs"
        :class="{ checked: checked }"
        :unchecked-value="uncheckedValue"
        :aria-describedby="`${$attrs.id}-invalid-feedback`"
        autocomplete="off"
      >
        {{ label }} <slot name="tooltip" />
      </b-form-checkbox>
      <b-form-invalid-feedback :id="`${$attrs.id}-invalid-feedback`" :state="getValidationState(validationContext)" role="alert">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
    value: {
      type: Boolean,
      default: null,
    },
    uncheckedValue: {
      type: Boolean,
      default: null,
    },
    bails: {
      type: Boolean,
      default: true,
    },
    validationState: {
      type: Boolean,
      default: true,
    },
    validationRules: {
      type: [String, Object],
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
  },
  computed: {
    checked: {
      get: ({ value } = this) => value,
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, errors }) {
      return dirty || validated ? !errors.length > 0 : null;
    },
  },
};
</script>
