import camelCase from 'lodash/camelCase';
const requireModule = require.context('.', false, /\.js$/);
const modules = {};

requireModule.keys().forEach((fileName) => {
  if (fileName === './_index.js') return;

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));
  modules[moduleName] = {
    namespaces: true,
    ...requireModule(fileName),
  };
});

export default modules;
