<template>
  <div></div>
</template>

<script>
import { createElement } from '../../api/stripe';

export default {
  name: 'StripeElement',
  props: {
    elements: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: () => 'card',
    },
    options: {
      type: [Object, undefined],
      default: () => undefined,
    },
  },
  data() {
    return {
      stripeElement: undefined,
      domElement: document.createElement('div'),
    };
  },
  computed: {
    elementsUsable() {
      if (!this.elements) {
        return false;
      }
      return Object.keys(this.elements).length > 0;
    },
  },
  watch: {
    options: {
      handler(opts) {
        if (opts && this.stripeElement) {
          this.stripeElement.update(opts);
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.stripeElement) {
      this.stripeElement.unmount();
      this.stripeElement.destroy();
    }
  },
  mounted() {
    if (this.elementsUsable) {
      this.mountElement();
      this.handleEvents();
    }
  },
  methods: {
    mountElement() {
      this.stripeElement = createElement(this.elements, this.type, this.options);
      this.stripeElement.mount(this.domElement);
      this.$el.appendChild(this.domElement);
      return this.stripeElement;
    },
    handleEvents() {
      ['change', 'ready', 'focus', 'blur', 'escape'].forEach((type) =>
          this.stripeElement.on(type, this.eventHandler.bind(this, type)));
    },
    eventHandler(type, e) {
      return this.$emit(type, e);
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/_variables';
.StripeElement {
  border: 1px solid #ced4da;
  height: calc(1.5em + 1.5rem + 2px);
  border-radius: 0.25rem;
  color: #495057;
  padding: 0.75rem 0.75rem;
}

.StripeElement--focus {
  border-color: $dp-navy !important;
  box-shadow: 0 0 0 0.2rem rgba(12, 76, 133, 0.6) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
</style>
