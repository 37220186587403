<template>
  <div class="device-order-item p-1 bg-white rounded">
    <header>
      <b-alert v-for="(error, key) in requestState.errors" :key="`form-errors-${key}`" show variant="warning" fade>
        <span>{{ error }}</span>
      </b-alert>
      <div class="d-flex justify-content-between align-items-center">
        <span class="p-1 d-inline-block">{{ basketItem.petName }}</span>
        <b-button
          :disabled="requestState.loading || disableState"
          class="text-decoration-underline p-1 pl-0"
          variant="link"
          @click="removeItem"
        >
          <b-spinner v-if="requestState.loading" small/>
          <b-icon icon="bug" v-if="requestState.errors.length" variant="danger"/>
          {{ removeButtonText }}
        </b-button>
      </div>
    </header>
    <div class="d-flex justify-content-between align-items-center p-1">
      <span>
        <b-icon-check-circle class="text-success" font-scale="1"/>
        {{ basketItem.productLabel }}
      </span>
      <span>{{ basketItem.displayPrice }}</span>
    </div>
  </div>
</template>

<script>
import PetTrackingDeviceBasketItemModel from '../../models/PetTrackingDeviceBasketItemModel';
import RequestState from '../../utils/requestState';

export default {
  name: 'DeviceOrderItem',
  props: {
    basketItem: {
      type: PetTrackingDeviceBasketItemModel,
      required: true,
    },
    disableState: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      requestState: new RequestState(),
    };
  },
  computed: {
    removeButtonText() {
      return 'Remove';
    },
  },
  inject: ['apiService'],
  methods: {
    async removeItem() {
      try {
        this.requestState.start();
        this.$emit('remove-basket-item-started');
        const response = await this.apiService.mutationRemovePetTrackingDeviceFromOrder(this.basketItem.orderItemId);
        this.requestState.success(response);
        this.$emit('remove-basket-item-success', response);
      } catch (e) {
        this.requestState.error(e);
        this.$emit('remove-basket-item-error', this.requestState.errors);
      } finally {
        this.$emit('remove-basket-item-complete');
      }
    },
  },
};
</script>
