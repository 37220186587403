import { isEmpty } from 'lodash';
import LoginService from '@/api/services/LoginService';

export const namespaced = true;

export const state = {
  token: null,
  error: null,
  loading: false,
  _attempts: {
    count: 1,
  },
};

export const getters = {
  getErrors: ({ error }) => error,
  isLoading: ({ loading }) => loading,
  _attemptCount: ({ _attempts }) => _attempts.count,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isSuccess: ({ hasErrors, token }) => !hasErrors && !isEmpty(token),
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  RESET_ERROR(state) {
    state.error = null;
  },
  SUCCESS(state, token) {
    state.error = null;
    state.token = token;
    state.loading = false;
    state._attempts.count = 1;
  },
  FAILURE(state, error) {
    state.token = null;
    state.error = error;
    state.loading = false;
    state._attempts.count++;
  },
};

export const actions = {
  tryLogin: ({ commit }, credentials) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      LoginService.tryLogin(credentials)
        .then(({ token }) => {
          commit('SUCCESS', token);
          !isEmpty(token) ? resolve(token) : reject();
        })
        .catch(({ error }) => {
          commit('FAILURE', error.message);
          reject(error);
        });
    }),
  tryLoginByLink({ commit }, token) {
    commit('LOADING', true);
    LoginService.tryLoginByLink(token)
      .then((response) => {
        commit('SUCCESS', response);
      })
      .catch(({ error }) => {
        commit('FAILURE', error.message);
      });
  },
};
