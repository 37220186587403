<template>
  <b-col class="note">
    <b-row :class="rowClass" no-gutters>
      <!-- User Info -->
      <b-col class="d-inline-flex align-items-start" cols="auto" style="user-select: none">
        <span class="note-user mr-3" :style="getUserColorStyle">
          {{ getUserCode }}
        </span>
      </b-col>
      <!-- Note -->
      <b-col v-if="!note || !note.id">
        <label for="new-note" :class="labelSrOnly ? 'sr-only' : ''">{{ newNoteLabel }}</label>
        <b-textarea id="new-note" ref="textarea" v-model="noteText" :placeholder="newNotePlaceholder" class="new-note" spellcheck />
        <b-button
          v-if="showSaveButton"
          variant="primary"
          class="mt-2 py-1 px-3"
          :disabled="disabled || !noteText"
          @click="$emit('createNote', noteText)"
        >
          Save
        </b-button>
      </b-col>
      <b-col v-else>
        <div class="note-details" style="user-select: none">
          <span class="mr-2 text-dark font-weight-bold">{{ note.administrator ? note.administrator.displayName : 'Unknown' }}</span>
          <small :key="lastUpdated" class="text-muted" data-toggle="tooltip" :title="new Date(note.createDt)">
            {{ diffForHumans(note.createDt, locale) }}
            <span v-if="note.isEdited">(edited)</span>
          </small>
          <b-link
            v-if="(note.administrator ? user.id === note.administrator.id : true) && !inEditMode"
            variant="secondary"
            class="note-action small"
            @click="$emit('editNote', note.id)"
          >
            Edit
          </b-link>
        </div>
        <div v-if="!inEditMode" class="note-text" style="user-select: text">{{ note.text }}</div>
        <b-textarea
          v-else
          id="edit-note"
          ref="textarea"
          :value="note.text"
          :placeholder="note.text"
          :disabled="disabled"
          class="edit-note"
          spellcheck
          @input="updateNoteText"
        />
        <b-button v-if="inEditMode" variant="primary" class="mt-2 py-1" @click="$emit('updateNote', note)"> Save </b-button>
        <b-button v-if="inEditMode" variant="outline-danger" class="mt-2 py-1 ml-2" @click="$emit('cancelEditNote', note.id)">
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import Vue from 'vue';
import { RelativeDateTimeMixin } from '../../mixins/RelativeDateTimeMixin';

export default {
  name: 'OrderNoteEditor',
  mixins: [RelativeDateTimeMixin],
  props: {
    note: {
      type: Object,
      default: null,
      required: false,
    },
    inEditMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    lastUpdated: {
      type: [String, Number],
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    newNotePlaceholder: {
      type: String,
      default: '',
      required: false,
    },
    newNoteLabel: {
      type: String,
      default: 'Note',
      required: false,
    },
    labelSrOnly: {
      type: Boolean,
      default: true,
      required: false,
    },
    rowClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      noteText: '',
    };
  },
  computed: {
    getUserCode() {
      return (this.note?.administrator?.displayName || this.user?.displayName)
        ?.split(' ')
        ?.splice(0, 2)
        ?.map(word => word[0])
        ?.join('');
    },
    getUserColorStyle() {
      const multiplier = 21;
      const userId = this.note?.administrator?.id || this.user?.id;
      return {
        backgroundColor: userId ? `hsl(${userId * multiplier}, 100%, 44%)` : '#003c71',
        color: 'white',
      };
    },
  },
  methods: {
    updateNoteText(text) {
      Vue.set(this.note, 'text', text);
    },
    focus() {
      this.$refs?.textarea?.focus();
    },
    clearText() {
      this.noteText = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.new-note,
.edit-note {
  max-height: none;
  min-height: 80px;

  &:focus {
    overflow-y: visible;
    min-height: 120px;
  }

  transition: min-height 0.15s ease-out;
}

.note-user {
  display: flex;
  padding: 0.5rem;
  min-width: 42px;
  min-height: 42px;
  font-weight: bold;
  aspect-ratio: 1/1;
  align-items: center;
  border-radius: 100%;
  max-width: max-content;
  max-height: max-content;
  justify-content: center;
}

.note {
  .note-action {
    border-radius: 20%;
    position: absolute;
    right: 0;
    top: 0;
    transition: visibility 0.05s ease-out;

    &:has(.show) {
      visibility: visible;
    }
  }

  &:hover {
    .note-action {
      visibility: visible;
    }
  }
}

.note-text {
  white-space: pre-wrap;
}

.new-note:focus,
.edit-note:focus,
.note-action:focus {
  box-shadow: none !important;
}
</style>
