<template>
  <b-row>
    <b-col>
      <b-row v-if="label">
        <b-col>
          <p :class="subLabel ? 'mb-3' : ''" class="h3">{{ label }}</p>
          <p v-if="subLabel" class="h3">{{ subLabel }}</p>
          <p v-if="info">{{ info }}</p>
        </b-col>
      </b-row>

      <b-row v-show="warningMessage">
        <b-col>
          <div class="text-danger mb-1" role="alert">
            <small>{{ warningMessage }}</small>
          </div>
        </b-col>
      </b-row>

      <b-row v-show="dateError">
        <b-col>
          <div class="text-danger mb-1" role="alert">
            <small>{{ $t(dateError) }}</small>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="4">
          <validation-provider v-slot="validationContext" ref="month" name="month" :rules="monthRules">
            <b-form-group id="month-input-group" label="Month" label-for="month-input" label-class="text-dark">
              <b-form-select
                id="month-input"
                v-model="month"
                name="month-input"
                :options="
                  monthOptions.map(opt => {
                    return { value: opt.value, text: $t(opt.text) };
                  })
                "
                autocomplete="off"
                aria-labelledby="month-format"
                aria-describedby="month-feedback"
                :state="getValidationState(validationContext, month)"
                @input="updateDate"
              ></b-form-select>
              <span id="month-format" class="sr-only">Select a month</span>
              <b-form-invalid-feedback id="month-feedback" role="alert">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" sm="4">
          <validation-provider v-slot="validationContext" ref="day" name="day" :rules="dayRules">
            <b-form-group id="day-input-group" label="Day" label-for="day-input" label-class="text-dark">
              <b-form-input
                id="day-input"
                v-model="day"
                type="text"
                pattern="[0-9]*"
                name="day-input"
                placeholder="dd"
                autocomplete="off"
                aria-labelledby="day-format"
                aria-describedby="day-feedback"
                :state="getValidationState(validationContext, day)"
                @input="updateDate"
              >
              </b-form-input>
              <span id="day-format" class="sr-only">Enter a day in format dd</span>
              <b-form-invalid-feedback id="day-feedback" role="alert">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" sm="4">
          <validation-provider v-slot="validationContext" ref="year" name="year" :rules="yearRules">
            <b-form-group id="year-input-group" label="Year" label-for="year-input" label-class="text-dark">
              <b-form-input
                id="year-input"
                v-model="year"
                type="text"
                pattern="[0-9]*"
                name="year-input"
                placeholder="yyyy"
                autocomplete="off"
                aria-labelledby="year-format"
                aria-describedby="year-feedback"
                :state="getValidationState(validationContext, year)"
                @input="updateDate"
              >
              </b-form-input>
              <span id="year-format" class="sr-only">Enter a year in format yyyy</span>
              <b-form-invalid-feedback id="year-feedback" role="alert">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  name: 'DpDateInput',
  props: {
    value: {
      type: [String, Number, Date, Object],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    originalDate: {
      type: [String, Object],
      default: null,
    },
    dateType: {
      type: String,
      default: '',
    },
    dateRequired: {
      type: Boolean,
      default: false,
    },
    allowPastRabies: {
      type: Boolean,
      default: false,
    },
    validateOnLoad: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      required: false,
      default: null,
    },
    warningMessage: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      minDayLength: 0,
      maxDayLength: 2,
      minYearsLength: 4,
      maxYearsLength: 4,
      dateError: '',
      monthOptions: [
        { value: null, text: 'dp.input.dates.monthOptions.default' },
        { value: 0, text: 'dp.input.dates.monthOptions.jan' },
        { value: 1, text: 'dp.input.dates.monthOptions.feb' },
        { value: 2, text: 'dp.input.dates.monthOptions.mar' },
        { value: 3, text: 'dp.input.dates.monthOptions.apr' },
        { value: 4, text: 'dp.input.dates.monthOptions.may' },
        { value: 5, text: 'dp.input.dates.monthOptions.jun' },
        { value: 6, text: 'dp.input.dates.monthOptions.jul' },
        { value: 7, text: 'dp.input.dates.monthOptions.aug' },
        { value: 8, text: 'dp.input.dates.monthOptions.sept' },
        { value: 9, text: 'dp.input.dates.monthOptions.oct' },
        { value: 10, text: 'dp.input.dates.monthOptions.nov' },
        { value: 11, text: 'dp.input.dates.monthOptions.dec' },
      ],
    };
  },
  computed: {
    returnedDate: {
      get: ({ value } = this) => value,
      set(value) {
        this.$emit('input', value);
      },
    },
    monthRules() {
      return this.dateRequired ? 'required' : '';
    },
    dayRules() {
      let month = this.month;
      //Ensure year is valid for lastDate calculation
      let year = !isNaN(this.year) && this.year?.length == this.maxYearsLength ? this.year : 2008;
      let d = new Date(year, month + 1, 0);
      let lastDate = d.getDate();
      return `${this.dateRequired ? 'required' : ''}|minMaxNumeric: ${this.minDayLength},${this.maxDayLength}|between:1,${lastDate}`;
    },
    yearRules() {
      return `${this.dateRequired ? 'required' : ''}|minMaxNumeric: ${this.minYearsLength},${this.maxYearsLength}`;
    },
  },
  watch: {
    dateError: function(val) {
      this.$emit('existingDateError', !!val);
    },
  },
  created() {
    let date;

    if (typeof this.originalDate === 'string' && this.timezone) {
      date = DateTime.fromISO(this.originalDate).setZone(this.timezone);
    }

    if (typeof this.originalDate === 'object') {
      date = this.originalDate;
    }

    this.day = date ? date.day : null;
    this.year = date ? date.year : null;
    this.month = date ? date.month - 1 : null;

    if (this.validateOnLoad) {
      this.updateDate();
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }, value) {
      if (this.dateError) {
        return false;
      }

      return dirty || validated ? valid && (value === null || value.length == 0) ? null : valid : null;
    },
    async passesDateRules(date) {
      await this.$nextTick();
      const [month, day, year] = await Promise.all([this.$refs.month.validate(), this.$refs.day.validate(), this.$refs.year.validate()]);

      if (!(month.valid && day.valid && year.valid)) {
        return false;
      }

      const inputDate = DateTime.fromJSDate(new Date(date));
      const now = DateTime.now();
      const yearsDiff = now.diff(inputDate, 'years').years;

      if (this.dateType === 'rabies') {
        if (inputDate.startOf('day') <= now.startOf('day') && !this.allowPastRabies) {
          this.dateError = 'dp.input.dates.errors.rabiesVaccineExpirMustBeFuture';
          return false;
        }

        if (yearsDiff <= -20) {
          this.dateError = 'dp.input.dates.errors.twentyYears';
          return false;
        }
      }

      if (this.dateType.toLowerCase().includes('birthday') && inputDate.startOf('day') > now.startOf('day')) {
        this.dateError = 'dp.input.dates.errors.birthdateMustBeInPast';
        return false;
      }

      if (this.dateType === 'petOwnerBirthday' && yearsDiff < 18) {
        this.dateError = 'dp.input.dates.errors.mustBeEighteen';
        return false;
      }

      return true;
    },
    async updateDate() {
      this.dateError = '';

      if (this.day !== null && this.month !== null && this.year !== null) {
        // Have to do a conversion on the Date because month values start at 0
        let dateFromInput = new Date();
        dateFromInput.setMonth(this.month);
        dateFromInput.setDate(this.day);
        dateFromInput.setFullYear(this.year);

        if (await this.passesDateRules(dateFromInput)) {
          if (this.dateType === 'rabies') {
            this.returnedDate = dateFromInput.toISOString().split('T')[0];
          }

          if (this.dateType.toLowerCase().includes('birthday')) {
            this.returnedDate = {
              day: Number(this.day),
              month: Number(this.month + 1),
              year: Number(this.year),
            };
          }
          return this.returnedDate;
        }

        this.returnedDate = null;
      }
    },
  },
};
</script>

<style scoped></style>
