import BaseModel from './BaseModel';

export default class JurisdictionBoundary extends BaseModel {
  constructor(name, externalSourceId, externalSource, externalSourceLayer, fillColor, strokeColor, jurisdictionId) {
    super();

    /** @type {string} **/
    this.name = name;

    /** @type {string} **/
    this.externalSourceId = externalSourceId;

    /** @type {string} **/
    this.externalSource = externalSource;

    /** @type {string} **/
    this.externalSourceLayer = externalSourceLayer;

    /** @type {string} **/
    this.fillColor = fillColor;

    /** @type {string} **/
    this.strokeColor = strokeColor;

    /** @type {int} **/
    this.jurisdictionId = jurisdictionId;
  }
}
