import { render, staticRenderFns } from "./DateInput.vue?vue&type=template&id=97e9c0e4&scoped=true&"
import script from "./DateInput.vue?vue&type=script&lang=js&"
export * from "./DateInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../log-in-vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e9c0e4",
  null
  
)

export default component.exports