import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async fetchPetOwner() {
    try {
      const query = gql`
        query petOwner {
          petOwner {
            email
            locale
            online
            firstName
            city {
              logo {
                original
              }
            }
          }
        }
      `;
      const { petOwner: results } = await axios.post('/', {
        query,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
  async completeSignup(payload) {
    try {
      const query = gql`
        mutation completeSignup($email: String!, $password: Password!, $eula: Boolean!, $marketingOptIn: Boolean) {
          completeSignup(email: $email, password: $password, eula: $eula, marketingOptIn: $marketingOptIn) {
            token
          }
        }
      `;
      const { completeSignup: results } = await axios.post('/', {
        query,
        variables: payload,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
