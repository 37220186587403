import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async fetchCity(id) {
    try {
      const query = gql`
        query city($id: ID!) {
          city(id: $id) {
            id
            baseUrl
            organization
            organizationOldTagImage {
              original
            }
            organizationOldTagNumberFormatDescription
            letterLogo {
              original
              imageAltText
            }
            noLicensing
            customerCareContext {
              customCareSupportText {
                  color
                  text
              }
            }
          }
        }
      `;
      const { city: results } = await axios.post('/', { query, variables: { id } });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
