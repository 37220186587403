// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/Paw.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "ul li[data-v-87d8ad50]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0;padding-left:2.5em;padding-top:2px;padding-bottom:3px}.separator[data-v-87d8ad50]{color:#6b6b6b}.separator[data-v-87d8ad50]:after,.separator[data-v-87d8ad50]:before{flex:1;content:\"\";margin-bottom:.65rem;border-bottom:.15rem solid #dcdcdc}.separator[data-v-87d8ad50]:after{margin-left:1rem}.separator[data-v-87d8ad50]:before{margin-right:1rem}", ""]);
// Exports
module.exports = exports;
