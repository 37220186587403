import Vue from 'vue';
import VueI18n from 'vue-i18n';
const requireLocales = require.context('@/locales', false, /\.js$/);

Vue.use(VueI18n);

Vue.filter('translate', (key) => vuei18n.t(key));

const messages = {};

requireLocales.keys().forEach((locale) => {
  messages[locale.replace(/(\.\/|\.js)/g, '')] = {
    ...requireLocales(locale)?.default,
  };
});

const vuei18n = new VueI18n({
  locale: window.__INITIAL_STATE__?.locale || process.env.VUE_APP_I18N_LOCALE || 'en_CA',
  fallbackLocale: window.__INITIAL_STATE__?.fallback_locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_CA',
  dateTimeFormats: {
    en_CA: {
      short: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en_US: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  numberFormats: {
    en_CA: {
      currency: {
        style: 'currency',
        currency: 'CAD',
      },
    },
    en_US: {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
  },
  messages,
  silentTranslationWarn: true,
});

export default vuei18n;
