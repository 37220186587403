import axios from 'axios';
import store from '@/store/';

const getBaseUrl = () => {
  const url = global.API_URL || process.env.VUE_APP_API_URL;

  if (url) {
    return `${url}graphql`;
  }
};

const getRESTBaseUrl = () => {
  const url = global.API_URL || process.env.VUE_APP_API_URL;

  if (url) {
    return `${url}api`;
  }
};

const config = {
  baseURL: getBaseUrl(),
  timeout: 3 * 6000, // 3 minutes
};
const instance = axios.create(config);

export const anonymous = axios.create(config);
export const rest = axios.create({
  ...config,
  baseURL: getRESTBaseUrl(),
});
export default instance;

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
anonymous.CancelToken = axios.CancelToken;
anonymous.isCancel = axios.isCancel;
rest.CancelToken = axios.CancelToken;
rest.isCancel = axios.isCancel;

function authenticateRequest(config) {
  const token = store.getters['getToken'] || process.env.VUE_APP_TOKEN;

  if (token) {
    config.headers['Authorization'] = token;
  }

  return config;
}

/**
 * Receives GraphQL response and rejects if there is no data and there are errors.
 *
 * @param response
 */
function handleGraphQLResponse({ data: results }) {
  const { data, errors } = results;
  const success = Object.keys(data).filter((key) => data[key] !== null).length;

  // We did not get any results, and there were errors so reject the response.
  if (!success && errors && errors.length) {
    return Promise.reject({
      message: errors,
    });
  } else {
    return data;
  }
}

function handleError({ response, request, message }) {
  if (response) {
    const { status } = response;

    return Promise.reject({
      error: status,
    });
  } else if (request) {
    return Promise.reject({
      error: request.status,
    });
  } else {
    return Promise.reject({
      error: message,
    });
  }
}

instance.interceptors.request.use(authenticateRequest, (error) => Promise.reject(error));

instance.interceptors.response.use(handleGraphQLResponse, handleError);
anonymous.interceptors.response.use(handleGraphQLResponse, ({ response, request, message }) => {
  if (response) {
    return Promise.reject({
      error: status,
    });
  } else if (request) {
    return Promise.reject({
      error: request.status,
    });
  } else {
    return Promise.reject({
      error: message,
    });
  }
});
