import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async tryLogin(credentials) {
    try {
      const query = gql`
        mutation login($credentials: LoginCredentials!) {
          login(credentials: $credentials) {
            token
          }
        }
      `;
      const variables = {
        credentials: {
          username: credentials.email,
          password: credentials.password,
        },
      };
      const { login: results } = await axios.post('/', { query, variables });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
  async tryLoginByLink(token) {
    try {
      const query = gql`
        mutation loginByLink($token: String!) {
          loginByLink(token: $token) {
            id
            firstName
          }
        }
      `;
      const variables = {
        token,
      };
      const { loginByLink: results } = await axios.post('/', {
        query,
        variables,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
