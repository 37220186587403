<template>
  <b-row align-h="center" class="text-center">
    <b-col v-show="!hideFront" col class="mb-4" :class="colStyling">
      <h2 :class="labelStyling">{{ card.frontLabel }}</h2>
      <div class="bg-white shadow-sm svg-rounded mt-2">
        <span id="wallet-card-front-label" class="sr-only">{{ frontTitle }}</span>
        <div ref="frontDraw" />
      </div>
    </b-col>
    <b-col v-show="!hideBack" col class="mb-4" :class="colStyling">
      <h2 :class="labelStyling" class="mt-md-3">{{ card.backLabel }}</h2>
      <div class="bg-white shadow-sm svg-rounded mt-2">
        <span id="wallet-card-back-label" class="sr-only">{{ backTitle }}</span>
        <div ref="backDraw" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js';

export default {
  name: 'WalletCard',
  props: {
    frontTitle: {
      type: String,
      required: false,
      default: '',
    },
    backTitle: {
      type: String,
      required: false,
      default: '',
    },
    hideFront: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideBack: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelStyling: {
      type: String,
      required: false,
      default: 'mb-0 pb-1',
    },
    colStyling: {
      type: String,
      required: false,
      default: '',
    },
    card: {
      type: Object,
      default: null,
      required: false,
    },
    frontDesign: {
      type: String,
      default: '',
      required: true,
    },
    backDesign: {
      type: String,
      default: '',
      required: true,
    },
    placeholders: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      svgFrontDesign: null,
      svgBackDesign: null,
      black: '#000000',
      white: '#ffffff',
      valuePlaceholders: null,
      FRONT: 'FRONT',
      BACK: 'BACK',
      fontWeightMapping: {
        light: 300,
        normal: 400,
        bold: 700,
        bolder: 900,
      },
      fontWeights: [],
      labelLineDY: 10,
    };
  },
  mounted() {
    this.loadDesigns();
  },
  methods: {
    async loadDesigns() {
      // Fetch SVG and load into DOM
      const svgFrontResponse = await fetch(this.frontDesign);
      const svgFront = await svgFrontResponse.text();

      const svgBackResponse = await fetch(this.backDesign);
      const svgBack = await svgBackResponse.text();
      await this.$nextTick();

      if (svgFront && svgBack) {
        const tempFront = this.$refs.frontDraw;
        const tempBack = this.$refs.backDraw;

        // Load SVG into drawer
        const frontDraw = SVG(tempFront)
          .clear()
          .svg(svgFront);
        const backDraw = SVG(tempBack)
          .clear()
          .svg(svgBack);

        // Imported SVG as base
        this.svgFrontDesign = frontDraw.findOne('svg');
        this.svgBackDesign = backDraw.findOne('svg');

        if (this.svgFrontDesign && this.svgBackDesign) {
          this.fillColorElements(this.card.primaryColor);
          this.$emit('designLoaded', true);
        }
      }
    },
    fillColorElements(primaryColor, secondaryColor) {
      if (!this.svgFrontDesign && !this.svgBackDesign) return;

      this.svgFrontDesign
        .find('#DocuPetBranding')
        .children()
        .fill({ color: primaryColor });

      this.svgFrontDesign
        .find('#Paws')
        .children()
        .fill({ color: primaryColor })
        .opacity(0.25);

      this.svgFrontDesign.find('#HouseIcon').fill({ color: primaryColor });

      this.svgBackDesign
        .find('#InCaseofEmergency')
        .children()
        .fill({ color: this.black });

      this.svgFrontDesign
        .find('.labels')
        .children()
        .fill({ color: secondaryColor });

      this.svgFrontDesign
        .find('.values')
        .children()
        .fill({ color: secondaryColor });

      this.svgFrontDesign.find('#HEADER').fill({ color: this.white });
    },
    async fillPlaceholders(placeholders) {
      this.valuePlaceholders = placeholders;
      this.fontWeights = placeholders
        .map(item => item.valueFontWeight || item.labelFontWeight)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(item => this.fontWeightMapping[item]);

      if (!this.card.fontFamily || !this.fontWeights.length) {
        return;
      }

      const fontsLoaded = await this.setCustomFont(this.card.fontFamily, this.fontWeights);
      if (fontsLoaded) {
        this.fillLabels(this.svgFrontDesign, this.FRONT);
        this.fillLabels(this.svgBackDesign, this.BACK);
      }
    },
    fillLabels(svgDesign, side) {
      // Fill Labels and values
      if (!svgDesign) return;

      const labels = this.valuePlaceholders.filter(label => label.side === side);
      const color = side === this.FRONT ? this.card.secondaryColor : this.black;

      svgDesign.find('.labels').remove();
      svgDesign.find('.values').remove();
      const labelGroup = svgDesign.group().attr('class', 'labels');
      const valueGroup = svgDesign.group().attr('class', 'values');

      labels.forEach(element => {
        // Labels
        if (element.labelName) {
          // Text Elements
          this.createTextElement('label', labelGroup, element.labelName, element, color);
        }

        // Values, print empty strings if no value property is present
        if (!element.value) {
          element.value = '';
        }
        const string = element.valueCharacterLimit ? this.setWordLimit(element.value, element.valueCharacterLimit) : element.value;
        if (element.valueType === 'PET_TEMPERAMENT' || element.valueType === 'PET_COLOR') {
          // Multiline Elements
          this.createTextElement('value', valueGroup, string, element, color, element.valueType);
        } else if (element.valueType === 'PET_IMAGE' || element.valueType === 'PET_QR_CODE') {
          // Image Elements
          this.createImageElement('value', valueGroup, element);
        } else {
          // Text Elements
          this.createTextElement('value', valueGroup, string, element, color, null);
        }
      });
    },
    encodeSpecialCharacters(str) {
      return String(str).replace(/[\u00A0-\u2666]/g, c => {
        const char = c.charCodeAt(0);
        return String(`&#${char};`);
      });
    },
    createTextElement(position, group, string, element, color, valueType = null) {
      const isLabel = position === 'label';
      const fontSize = isLabel ? element.labelFontSize : element.valueFontSize;
      const fontWeight = isLabel ? element.labelFontWeight : element.valueFontWeight;
      const valueX = isLabel ? element.labelX : element.valueX;
      const valueY = isLabel ? element.labelY : element.valueY;

      group.find(isLabel ? `#${element.valueType}_LABEL` : `#${element.valueType}`).remove();
      const textElement = group
        .text((add) => string.split("\n").forEach((t, i) => add.tspan(t).dy(i ? this.labelLineDY : 0).attr('x', Number(valueX))))
        .font({
          family: `${this.card.fontFamily}, sans-serif`,
          size: Number(fontSize),
          weight: fontWeight,
        })
        .fill(element.valueType === 'HEADER' ? this.white : color)
        .leading(1.1)
        .attr({
          id: isLabel ? `${element.valueType}_LABEL` : element.valueType,
          x: Number(valueX),
          y: Number(valueY),
          'text-anchor': isLabel ? this.anchorAlignment(element.labelTextAlignment) : 'start',
        });

      if (element.valueType === 'EMERGENCY_CONTACT_TWO_DISPLAY_NAME' || element.valueType === 'EMERGENCY_CONTACT_TWO_PHONE_NUMBER') {
        this.svgBackDesign.find('#EMERGENCY_CONTACT_TWO_DISPLAY_NAME_LABEL').attr({ opacity: 0 });
      }

      if (valueType && textElement) {
        this.createMultiLineText(string, textElement, element.valueWidth, valueType);
      }
    },
    createImageElement(position, group, element) {
      group.find(`#${element.valueType}`).remove();
      group
        .image(element.value)
        .size(Number(element.valueWidth), Number(element.valueHeight))
        .attr({
          id: element.valueType,
          x: Number(element.valueX),
          y: Number(element.valueY),
          alt: '',
        });
    },
    async createMultiLineText(text, textElement, maxWidth, valueType) {
      const label = this.svgFrontDesign.findOne(`#${valueType}_LABEL`);
      const labelX = label.attr('x');
      const labelTextLength = await this.getTextLength(label);

      if (!labelTextLength) {
        return;
      }
      const words = text.split(' ');
      const tspanElement1X = textElement.attr('x');

      // Add first word to the tspan element including label length
      const tspanElement1 = textElement.text(words[0]);
      textElement.attr({
        x: tspanElement1X + labelTextLength,
      });

      const tspanElement2 = SVG('<tspan>')
        .dy(this.labelLineDY)
        .attr({ x: labelX });
      const tspanElement3 = tspanElement2.clone();

      // Loop through rest of words to format text
      let line2 = '';
      let line3 = '';
      for (let i = 1; i < words.length; i++) {
        const firstChild = textElement.node.firstChild.textContent;
        textElement.node.firstChild.textContent += ` ${words[i]}`;

        // Add second line
        if (tspanElement1.length() + labelTextLength > maxWidth) {
          // Remove added word
          textElement.node.firstChild.textContent = firstChild.slice(0, firstChild.length);
          line2 += `${words[i]} `;
          tspanElement2.text(line2);
          tspanElement1.add(tspanElement2);

          // Add third line
          if (tspanElement2.length() > maxWidth) {
            line3 += `${words[i]} `;
            // Remove words from previous line
            tspanElement2.node.firstChild.textContent = tspanElement2.node.firstChild.textContent.replace(line3, '');
            tspanElement3.text(line3);
            tspanElement1.add(tspanElement3);
          }
        }
      }
    },
    fillElementById(valueType, value) {
      if (!this.svgFrontDesign || !this.svgBackDesign || !this.valuePlaceholders) return;

      const element = this.valuePlaceholders.find(valuePlaceholder => valuePlaceholder.valueType === valueType.toUpperCase());
      if (!element) return;

      const trimmedValue = element.valueCharacterLimit ? this.setCharacterLimit(value, element.valueCharacterLimit) : value;

      switch (element.valueType) {
        case 'PET_TEMPERAMENT':
          // Multiline Element
          this.createTextElement(
            'value',
            this.svgFrontDesign.find('.values')[0],
            value,
            element,
            this.card.secondaryColor,
            element.valueType
          );
          break;
        case 'PET_IMAGE':
          this.svgFrontDesign.find(`#${element.valueType}`).load(value);
          break;
        default: {
          element.side === 'FRONT'
            ? this.svgFrontDesign
                .find(`#${element.valueType}`)
                .clear()
                .text(trimmedValue)
            : this.svgBackDesign
                .find(`#${element.valueType}`)
                .clear()
                .text(trimmedValue);

          const optionalNameText = this.svgBackDesign.find(`#EMERGENCY_CONTACT_TWO_DISPLAY_NAME`)?.text()?.[0];
          const optionalNumberText = this.svgBackDesign.find(`#EMERGENCY_CONTACT_TWO_PHONE_NUMBER`)?.text()?.[0];

          // Hide label if optional fields are empty
          this.svgBackDesign.find(`#EMERGENCY_CONTACT_TWO_DISPLAY_NAME_LABEL`).attr({
            opacity: !optionalNameText?.length && !optionalNumberText?.length ? 0 : 1,
          });
          break;
        }
      }
    },
    exportCards() {
      return {
        front: this.encodeSpecialCharacters(this.svgFrontDesign.svg()),
        back: this.encodeSpecialCharacters(this.svgBackDesign.svg()),
      };
    },
    async setCustomFont(fontFamily, fontWeights) {
      // We need to embed the fonts locally to ensure its rendered properly when viewed as a standalone file
      const formattedFontWeights = fontWeights.sort().join(';');
      const font = await this.fetchFont(`https://fonts.googleapis.com/css2?family=${fontFamily}:wght@${formattedFontWeights}&display=swap`);
      if (font) {
        const base64Font = await this.embedFonts(font);
        if (base64Font) {
          [this.svgFrontDesign.defs(), this.svgBackDesign.defs()].forEach(design => {
            const styleElement = document.createElement('style');
            design.node.append(styleElement);
            design.node.firstChild.append(base64Font);
          });
        }
      }
      return true;
    },
    async fetchFont(url) {
      try {
        const font = await fetch(url);
        return await font.text();
      } catch (e) {
        return null;
      }
    },
    async embedFonts(font) {
      const fontLocations = font.match(/https:\/\/[^)]+/g);
      const fontLoadedPromises = fontLocations.map(location => {
        return new Promise(resolve => {
          this.fetchWithRetry(location)
            .then(res => {
              return res.blob();
            })
            .then(blob => {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                font = font.replace(location, reader.result);
                resolve([location, reader.result]);
              });
              reader.readAsDataURL(blob);
            })
            .catch(error => {
              this.$emit('error', error);
            });
        });
      });
      const result = await Promise.all(fontLoadedPromises);
      if (result) return font;
      return null;
    },
    async fetchWithRetry(url, retries = 1, delay = 500) {
      try {
        const response = await fetch(url);
        if (response.status === '200' || response.ok) {
          return response;
        }
      } catch (error) {
        if (retries === 0) {
          throw error?.message;
        } else {
          await new Promise(resolve => setTimeout(resolve, delay));
          return this.fetchWithRetry(url, retries - 1, delay);
        }
      }
    },
    setWordLimit(string, limit) {
      if (!string && !limit) return;

      if (string.length <= limit) return string;

      let trimmedString = string.substr(0, limit);
      //re-trim if limit is middle of the word and remove last comma
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))).replace(/,\s*$/, '');

      return trimmedString;
    },
    setCharacterLimit(string, limit) {
      if (!string && !limit) return;

      return string.substr(0, limit);
    },
    anchorAlignment(alignment) {
      switch (alignment.toLowerCase()) {
        case 'left':
          return 'start';
        case 'center':
          return 'middle';
        case 'right':
          return 'end';
        default:
          return 'start';
      }
    },
    async getTextLength(svgElement) {
      let textLength = Math.floor(svgElement.length());
      while (textLength === 0) {
        await new Promise(resolve => requestAnimationFrame(resolve));
        textLength = Math.floor(svgElement.length());
      }
      return textLength;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/styles/_variables';
.svg-rounded {
  border: 1px solid $dp-gray-3;
  overflow: hidden;
  border-radius: 12px;
}
</style>
