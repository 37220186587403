<template>
  <div>
    <BasePanel
      v-show="(isLoaded || hasErrors) && !isSuccess"
      :title="$t('completeActivation.title', { firstName: getPOFirstName || '' })"
      :subheading="$t('completeActivation.subheading')"
      :subheading-list="$t('completeActivation.subheadingList')"
      subheading-alignment="left"
      class="sentry-mask"
    >
      <template #errors>
        <BaseDisplayMessage v-if="getErrorMessage" class="mt-3 text-danger" :message="getErrorMessage" role="alert"/>
      </template>

      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <DpBaseInput
            id="email-input"
            v-model.trim="email"
            name="email"
            type="email"
            :autofocus="true"
            autocomplete="username"
            :label="'input.email.label' | translate"
            :validation-rules="getEmailRules"
            :placeholder="'input.email.placeholder' | translate"
            vid="email"
          />

          <DpBaseInput
            id="password-input"
            v-model.trim="password"
            name="password"
            type="password"
            use-password-validation-rules
            autocomplete="new-password"
            additional-description="password-check-list"
            :bails="false"
            :validation-state="!hasErrors"
            :label="'input.createPassword.label' | translate"
            :placeholder="'input.createPassword.placeholder' | translate"
          >
            <template #default="{ failedRules, dirty, valid }">
              <dp-secure-password-check-list id="password-check-list" :failed-rules="failedRules" :dirty="dirty" :valid="valid" />
            </template>
          </DpBaseInput>

          <b-row>
            <b-col class="pr-0">
              <ValidationProvider
                  v-slot="{ validated, valid, errors }"
                  :rules="{ required: { allowFalse: false } }"
                  name="terms-of-service-and-privacy-policy"
                  vid="terms-of-service-and-privacy-policy"
              >
                <b-form-group :label-for="'terms-of-service-and-privacy-policy'">
                  <b-form-checkbox
                      v-if="getTermsOfServiceAndPrivacyPolicyOptInCopy"
                      id="terms-of-service-and-privacy-policy"
                      v-model="eula"
                      class="my-3"
                      :state="(!validated || valid) && null"
                      required
                  >
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="getTermsOfServiceAndPrivacyPolicyOptInCopy"></span>
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                      :id="'terms-of-service-and-privacy-policy-invalid-feedback'"
                      :state="(!validated || valid) && null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="1" class="pl-0">
              <b-button
                  v-if="getTermsOfServiceAndPrivacyPolicyOptInCopyTooltip"
                  v-b-tooltip="{
                  title: getTermsOfServiceAndPrivacyPolicyOptInCopyTooltip,
                  placement: 'top',
                  trigger: 'hover click blur',
                  html: true,
                }"
                  class="mt-3 tooltip-btn"
                  :title="`${$t('dp.footer.tos')} and ${$t('dp.footer.privacy')} info`"
              >
                <fa-icon :icon="['fas', 'info-circle']" class="tooltip-info" />
              </b-button>
            </b-col>
          </b-row>
          <b-form-checkbox v-if="getMarketingCommunicationOptInCopy" v-model="marketingOptIn" class="my-3">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="getMarketingCommunicationOptInCopy"></span>
          </b-form-checkbox>

          <b-button type="submit" variant="primary" class="w-100" :disabled="isLoading">
            {{ 'completeActivation.buttonText' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>
        </form>
      </ValidationObserver>
    </BasePanel>
    <BasePanel
      v-show="!isLoaded && !hasErrors"
      :title="$t('completeActivation.loading.title')"
      :subheading="$t('completeActivation.loading.subheading')"
    >
      <BaseLoadingIcon />
    </BasePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput, DpSecurePasswordCheckList } from '@docupet/component-library';

export default {
  name: 'CompleteSignup',
  components: {
    DpSecurePasswordCheckList,
    DpBaseInput,
  },
  beforeRouteEnter(to, from, next) {
    const { getters, commit } = require('@/store').default;

    if (to.query?.token) {
      commit('SET_TOKEN', to.query?.token, { root: true });
    }

    if (to.query?.redirect_path) {
      commit('completeSignup/SET_REDIRECT', to.query.redirect_path);
    }

    if (to.query?.redirect_uri) {
      commit('completeSignup/SET_REDIRECT', to.query.redirect_uri);
      let utmParams = {};
      for (let property in to.query) {
        if (property.toLowerCase().startsWith('utm_')) {
          utmParams[property] = to.query[property];
        }
      }
      if (Object.keys(utmParams).length) {
        commit('completeSignup/SET_REDIRECT_QUERY_PARAMS', utmParams);
      }
    }

    const token = getters['getToken'];

    token
      ? next()
      : to.query?.accessCode
      ? next({ name: 'FetchMyRecordByAccessCode', query: { accessCode: to.query?.accessCode } })
      : next({ name: 'FetchMyRecordByAccessCode' });
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    email: '',
    eula: null,
    marketingOptIn: null,
    password: '',
    emailExistsData: {
      exists: false,
      email: '',
      message: '',
    },
  }),
  computed: {
    ...mapGetters(['getBaseUrl']),
    ...mapGetters('completeSignup', [
      'isLoading',
      'isLoaded',
      'isSuccess',
      'hasErrors',
      '_attemptCount',
      'getErrors',
      'getPOFirstName',
      'getPOEmailAddress',
    ]),
    getEmailRules() {
      let ruleObject = { required: true, email: true };
      if (this.emailExistsData.exists) {
        ruleObject.emailExists = [this.emailExistsData.email, this.emailExistsData.message];
      }
      return ruleObject;
    },
    contentsBlank: (self = this) => self.email === '' || self.password === '' || self.eula !== true,
    getErrorMessage: function () {
      if ((this.hasErrors || (this._attemptCount > 1 && !this.isLoading)) && this._attemptCount <= 3) {
        switch (this.getErrors) {
          case 'duplicate_email':
          case 'access_code_used':
            return {
              path: 'completeActivation.errorMessages.access_code_used',
              action: {
                to: this.email ? { name: 'LogIn', query: { email: this.email } } : { name: 'LogIn' },
                text: this.$t('completeActivation.errorMessages.action.access_code_used'),
              },
            };
          default:
            return this.$t('completeActivation.errorMessages.default');
        }
      }
      if ((this.hasErrors || this._attemptCount > 3) && this._attemptCount > 3) {
        return 'contactCustomerCare';
      }
      return null;
    },
    getScreenContext() {
      return this.context;
    },
    getTermsOfServiceAndPrivacyPolicyOptInCopy() {
      return this.getScreenContext?.termsOfServiceAndPrivacyPolicyOptInCopy;
    },
    getTermsOfServiceAndPrivacyPolicyOptInCopyTooltip() {
      return this.getScreenContext?.termsOfServiceAndPrivacyPolicyOptInCopyTooltip;
    },
    getMarketingCommunicationOptInCopy() {
      return this.getScreenContext?.marketingCommunicationOptInCopy;
    },
  },
  created() {
    this.fetchPetOwner()
      .then(({ online }) => {
        this.email = this.getPOEmailAddress;
        if (online) this.$router.push({ name: 'SyfLoginSuccessRedirect', query: this.$router.query });
      })
      .catch(() => {
        this.accessCode
          ? this.$router.push({ name: 'FetchMyRecordByAccessCode', query: { accessCode: this.accessCode } })
          : this.$router.push({ name: 'FetchMyRecordByAccessCode' });
      });
  },
  methods: {
    ...mapActions('completeSignup', ['fetchPetOwner', 'completeSignup', 'error']),
    async onSubmit({ email, password, eula, marketingOptIn } = this) {
      marketingOptIn = marketingOptIn ?? false;

      this.completeSignup({ email, password, eula, marketingOptIn })
        .then(({ token }) => {
          this.$router.push({
            name: 'SyfLoginSuccessRedirect',
            query: { ...this.$router.currentRoute.query, token, redirectPath: 'licensing' },
          });
        })
        .catch(() => {
          if (this.getErrors === 'duplicate_email') {
            this.emailExistsData.exists = true;
            this.emailExistsData.email = this.email;
            this.emailExistsData.message = this.$t('dp.input.email.asyncErrorMessage.emailAlreadyExists', { loginLink: this.loginLink() });
          }
          this.errorMessage = this.getErrorMessage;
        });
    },
    loginLink: function () {
      return `${this.getBaseUrl}/login`;
    },
  },
};
</script>