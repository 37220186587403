import BaseModel from './BaseModel';

export default class PetTrackingDeviceAddToOrderContext extends BaseModel {
  constructor({
    subscriptionOptions,
    petOptions,
    devicesCurrentlyInBasket,
    startNewOrderItemButtonText,
    continueToCheckoutUrl,
    continueButtonText,
    deleteOrderItemButtonText,
    submitNewOrderItemFormButtonText,
    cancelNewOrderItemButtonText,
    stepOneText,
    stepTwoText,
    selectPetText,
    selectSubscriptionText,
  }) {
    super();
    /** @type {PetTrackingDeviceSubscriptionOption[]} **/
    this.subscriptionOptions = subscriptionOptions;
    /** @type {SelectFieldOption[]} **/
    this.petOptions = petOptions;
    /** @type {PetTrackingDeviceBasketItemModel[]} **/
    this.devicesCurrentlyInBasket = devicesCurrentlyInBasket;
    /** @type {string} **/
    this.startNewOrderItemButtonText = startNewOrderItemButtonText;
    /** @type {string} **/
    this.continueToCheckoutUrl = continueToCheckoutUrl;
    /** @type {string} **/
    this.continueButtonText = continueButtonText;
    /** @type {string} **/
    this.deleteOrderItemButtonText = deleteOrderItemButtonText;
    /** @type {string} **/
    this.submitNewOrderItemFormButtonText = submitNewOrderItemFormButtonText;
    /** @type {string} **/
    this.cancelNewOrderItemButtonText = cancelNewOrderItemButtonText;
    /** @type {string} **/
    this.stepOneText = stepOneText;
    /** @type {string} **/
    this.stepTwoText = stepTwoText;
    /** @type {string} **/
    this.selectPetText = selectPetText;
    /** @type {string} **/
    this.selectSubscriptionText = selectSubscriptionText;
  }
}
