<template>
  <ValidationProvider
    v-slot="{ errors, valid, validated, dirty }"
    ref="validationProvider"
    :name="name || label.toLowerCase()"
    :rules="validationRules"
    :bails="bails"
    :vid="vid"
  >
    <b-form-group :id="`${name}-input-group`" :label="label" :label-for="`${name}-input`">
      <b-form-radio-group
        :id="`${name}-input`"
        :ref="`ref${name}`"
        v-model="selected"
        :name="name"
        v-bind="$attrs"
        :class="errors[0] ? 'is-invalid' : ''"
        :state="dirty || validated ? valid : null"
        :aria-describedby="`${name}-feedback`"
        :aria-label="`${label} Selection`"
        buttons
        button-variant="outline-primary"
        v-on="$listeners"
      >
        <b-form-radio v-for="(option, index) in options" :id="`${name}-radio-input-${index}`" :key="index" :value="option.value">
          {{ option.text }}
        </b-form-radio>
      </b-form-radio-group>
      <b-form-invalid-feedback :id="`${name}-feedback`" role="alert" v-html="errors[0]" />
    </b-form-group>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseRadioSwitch',
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    validationRules: {
      type: [String, Object],
      default: '',
    },
    bails: {
      type: Boolean,
      default: true,
    },
    vid: {
      type: String,
      default: '',
    },
  },
  computed: {
    selected: {
      get: ({ value } = this) => value,
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    //Resolve Accessibility issues when using button styled radio groups
    await this.$nextTick();
    this.$refs?.[`ref${this.name}`]?.$children?.forEach((child, index) => {
      child.$el.setAttribute('for', `${this.name}-radio-input-${index}`);
    });
  },
};
</script>

<style scoped></style>
