<template>
  <b-row align-v="center">
    <b-col cols="6" offset="3">
      <b-img center fluid :src="loadingImage" alt="" />
    </b-col>
  </b-row>
</template>

<script>
import loadingImage from './assets/loading_anim_transparent.gif';

export default {
  name: 'DpLoading',
  data() {
    return {
      loadingImage: loadingImage,
    };
  },
};
</script>

<style lang="scss" scoped></style>
