export const prepareQuery = query => {
  return query
    .replace(/[\t]/gmu, '') // Remove tabs
    .replace(/ {2,}/gmu, '') // Remove spaces
    .replace(/\n/gmu, ' '); // Change newlines into spaces
};

export function gql(strings, ...values) {
  const raw = strings.reduce((result, current, index) => {
    return `${result}${current}${values[index] || ''}`;
  }, '');

  return prepareQuery(raw);
}
