<template>
  <b-container>
    <b-row>
      <b-col class="px-4 col-sm-12 col-md-8 col-lg-12 col-xl-12 offset-md-2 offset-lg-0 offset-xl-0 col-12">
        <notification-bar
          v-for="(notification, index) in notificationsToDisplay"
          :key="notification.id"
          :class="index > 0 ? 'mt-3' : ''"
          :notification="notification"
          @removeNotification="removeNotification"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NotificationBar from './NotificationBar';

export default {
  name: 'NotificationContainer',
  components: {
    NotificationBar,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    inLicensingFlow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notificationsToDisplay() {
      return this.notifications.filter(notification => {
        if (notification.showInLicensing) {
          return this.inLicensingFlow;
        }
        return true;
      });
    },
  },
  methods: {
    removeNotification(id) {
      if (this?.$store?.hasModule('notification')) {
        this.$store.dispatch('notification/remove', id);
      } else {
        this.$emit('removeNotification', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
