<template>
  <b-card no-body class="shadow-sm">
    <template v-if="totalPages > 1" #header>
      <b-button-toolbar key-nav aria-label="Pdf Navigation" justify class="text-center sticky-top">
        <b-button-group class="mx-auto">
          <b-button size="sm" variant="primary" :disabled="onFirstPage" @click="prevPage">
            <fa-icon role="button" :icon="['far', 'angle-left']" size="2x" />
          </b-button>

          <b-button size="sm" variant="primary" :disabled="onLastPage" @click="nextPage">
            <fa-icon :icon="['far', 'angle-right']" size="2x" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </template>
    <b-card-body class="p-0 border-light">
      <div id="viewerContainer" class="mw-100 overflow-scroll rounded">
        <canvas id="viewer" ref="pdfCanvas"></canvas>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import { library } from '@fortawesome/fontawesome-svg-core';
import { icons } from './assets/fontawesome.js';

pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker;
library.add(icons);

export default {
  name: 'PdfViewer',
  props: {
    pdfPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pdfDocument: null,
      pageNum: 1,
      scale: 1,
    };
  },
  computed: {
    onLastPage() {
      if (this.pdfDocument === null) {
        return true;
      }

      return this.pageNum >= this.pdfDocument.numPages;
    },
    onFirstPage() {
      if (this.pdfDocument === null) {
        return true;
      }

      return this.pageNum <= 1;
    },
    totalPages() {
      if (this.pdfDocument === null) {
        return 0;
      }

      return this.pdfDocument.numPages;
    },
  },
  mounted() {
    this.initPdf();
  },
  methods: {
    async initPdf() {
      const loadingTask = pdfjsLib.getDocument(this.pdfPath);

      try {
        this.pdfDocument = await loadingTask.promise;
        await this.renderPage();
      } catch (reason) {
        this.$emit('pdfError', reason);
      }
    },
    async renderPage() {
      try {
        const pdfPage = await this.pdfDocument.getPage(this.pageNum);
        const viewport = pdfPage.getViewport({ scale: this.scale });
        const canvas = this.$refs.pdfCanvas;
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const ctx = canvas.getContext('2d');

        await pdfPage.render({
          canvasContext: ctx,
          viewport,
        });
      } catch (reason) {
        this.$emit('pdfError', reason);
      }
    },
    nextPage() {
      if (this.pdfDocument === null) {
        return;
      }

      if (this.pageNum >= this.pdfDocument.numPages) {
        return;
      }

      this.pageNum++;
      this.renderPage();
    },
    prevPage() {
      if (this.pdfDocument === null || this.pageNum <= 1) {
        return;
      }

      this.pageNum--;
      this.renderPage();
    },
  },
};
</script>

<style scoped>
#viewerContainer {
  max-height: 300px;
  overflow: scroll;
}
</style>
