import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async fetchCompleteSignupScreenContext() {
    try {
      const query = gql`
        query completeSignupScreenContextQuery {
          completeSignupScreenContextQuery {
            termsOfServiceAndPrivacyPolicyOptInCopy
            termsOfServiceAndPrivacyPolicyOptInCopyTooltip
            marketingCommunicationOptInCopy
          }
        }
      `;

      const { completeSignupScreenContextQuery: screenContext } = await axios.post(`/`, { query });

      return screenContext;
    } catch (error) {
      throw new Error(error?.message?.[0]?.message || error?.message || 'There was an unexpected error.');
    }
  },
};
