<template>
  <b-container :fluid="fluid">
    <b-row class="sticky">
      <b-col v-bind="container" class="dp-container-header">
        <slot name="dp-container-header" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-bind="container" class="dp-container" :class="colClass">
        <header v-if="(baseUrl + imagePath).length > 0">
          <img class="full" :src="baseUrl + imagePath" alt="" />
        </header>
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'DpContainer',
  props: {
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
    imagePath: {
      type: String,
      required: false,
      default: '',
    },
    fluid: {
      type: [String, Boolean],
      default: true,
    },
    container: {
      type: Object,
      default: null,
    },
    colClass: {
      type: String,
      default: 'pb-5',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/_variables';
.dp-container,
.dp-container-header {
  background-color: $dp-white;
  max-width: 1480px;
  padding: 0 40px;
  margin: 0 auto;
}
header img.full {
  margin-left: -1.5rem;
  width: calc(100% + 3rem);
  margin-top: -1.9rem;
}
.panel-wrapper {
  border-radius: 8px;
  box-shadow: 1px 2px 3px $dp-shadow-1;
}
@media (min-width: 960px) and (max-width: 1510px) {
  .dp-container,
  .dp-container-header {
    padding: 0 54px;
  }
}
@media screen and (max-width: 960px) {
  .dp-container,
  .dp-container-header {
    padding: 0 0;
    overflow: hidden;
  }
}
@media screen and (max-width: 576px) {
  .dp-container {
    border-radius: 8px;
    box-shadow: 1px 2px 3px $dp-shadow-1;
    overflow: hidden;
  }
  header img {
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
    margin-top: -2.5rem;
  }
}
.sticky {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999;
  border-top: 0;
}
</style>
