<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <BaseFetchMyRecord current-screen="ByAccessCode">
        <div class="my-4">
          <b-link v-b-modal.modal-access-code-example-image aria-labelledby="access-code-example">
            <b-img center fluid thumbnail :src="require('@/assets/access-code-example.png')" alt="" />
          </b-link>
          <p id="access-code-example" class="text-center mt-2">
            <b-link v-b-modal.modal-access-code-example-image class="text-decoration-underline">See an example</b-link> of an "Access Code" <br />
            we sent you by mail or email?
          </p>
          <b-modal
            id="modal-access-code-example-image"
            lazy
            static
            ok-only
            size="md"
            ok-title="Close"
            body-class="bg-dark"
            ok-variant="outline-primary"
            title="Access Code Example"
          >
            <b-img-lazy center fluid :src="require('@/assets/access-code-see-stacked-examples.png')" alt="" />
          </b-modal>
        </div>
        <DpBaseInput
          id="access-code-input"
          v-model.trim="accessCode"
          name="access-code"
          type="text"
          autocomplete="off"
          validation-rules="required|alpha_num"
          class="sentry-mask"
          :autofocus="false"
          :formatter="_.toUpper"
          :label="'input.accessCode.label' | translate"
          :placeholder="'input.accessCode.placeholder' | translate"
        >
          <template #label="{ props: { label } }">
            <label class="input-label w-100" for="access-code-input">
              {{ label }}
              <b-link v-b-modal.modal-access-code-info title="What is an Access Code?">
                <font-awesome-icon :icon="['fas', 'info-circle']" class="ml-1 text-primary" size="lg" />
              </b-link>
              <b-modal id="modal-access-code-info" title="What is an Access Code?" ok-only ok-variant="outline-primary" ok-title="Close">
                {{ $t('fetchMyRecord.byAccessCode.modal.accessCode.content', { getCityOrganization }) }}
              </b-modal>
            </label>
          </template>
          <template #belowField>
            <span class="mt-1 float-left"> <b-link :to="{ name: 'RequestAccessCode' }" v-text="$t('link.accessCode.dontHave')" /></span>
          </template>
        </DpBaseInput>
        <br />
        <DpBaseInput
          id="postal-code-input"
          v-model.trim="postalCode"
          name="postal-code"
          type="text"
          :formatter="_.toUpper"
          autocomplete="postal-code"
          validation-rules="required"
          :label="'input.postalCode.label' | translate"
          :placeholder="'input.postalCode.placeholder' | translate"
        />

        <template #footerMessage>
          <div v-if="hasErrors" class="bg-info mt-n3 mb-4 p-3 py-4">
            <p><BaseDisplayMessage v-if="getErrorMessage" class="mt-3 text-dark" :message="getErrorMessage" role="alert"/></p>
            <p v-if="getErrors != 'access_code_used'">
              If this isn't working, you may
              <b-link :to="{ name: 'FetchMyRecordByTagNumber', query: $router.currentRoute.query }" class="text-decoration-underline">try using your pet's current license tag number to access your record</b-link>.
            </p>
            <p v-html="getCustomCareSupportText" />
            <p>
              Learn more about how we source existing records <b-link v-b-modal.modal-how-we-source-existing-records-info class="text-decoration-underline">here</b-link>.
            </p>
          </div>
        </template>

        <template #footer>
          <b-button class="mt-0 w-100" type="submit" variant="primary" :disabled="!valid || !isButtonEnabled || isLoading">
            {{ 'fetchMyRecord.buttonText.primary' | translate }}
            <div v-if="isLoading" role="status" class="fa-pulse float-right mt-1">
              <font-awesome-icon :icon="['fas', 'spinner']" class="d-flex" />
              <span class="sr-only">{{ $t('dp.buttons.loading') }}</span>
            </div>
          </b-button>
        </template>
      </BaseFetchMyRecord>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DpBaseInput } from '@docupet/component-library';

export default {
  name: 'FetchMyRecordByAccessCode',
  components: {
    DpBaseInput,
  },
  data: () => ({
    accessCode: '',
    postalCode: '',
    isButtonEnabled: true,
  }),
  computed: {
    ...mapGetters('city', ['getCityOrganization', 'getCityBaseURL', 'getCityCustomerSupportContext']),
    ...mapGetters('fetchMyRecord', ['isLoading', 'isSuccess', 'hasErrors', 'getErrors']),
    contentsBlank: ({ accessCode, postalCode } = this) => accessCode == '' || postalCode == '',
    getErrorMessage: function () {
      if (this.getErrors == 'access_code_used') {
        return {
          path: 'fetchMyRecord.byAccessCode.errorMessages.access_code_used',
          action: {
            to: { name: 'LogIn' },
            text: this.$t('fetchMyRecord.byAccessCode.errorMessages.action.access_code_used'),
          },
        };
      } else {
        return "Hmm... we can't find a record that matches these entries. Please ensure that you have entered the information correctly.";
      }
    },
    getCustomCareSupportText: function () {
      return this.getCityCustomerSupportContext?.customCareSupportText?.text;
    },
  },
  created: function () {
    this.$store.commit('fetchMyRecord/RESET_ERROR');
    this.accessCode = this.$route.query.accessCode ? this.$route.query.accessCode : '';
  },
  methods: {
    ...mapActions('fetchMyRecord', ['tryLoginByAccessCode']),
    async onSubmit({ accessCode, postalCode } = this) {
      this.isButtonEnabled = false;
      this.tryLoginByAccessCode({ accessCode, postalCode })
        .then(() => {
          this.$router.push({ name: 'CompleteSignup', query: this.$router.currentRoute.query });
          window.dataLayer?.push({ event: 'activateByAccessCode' });
        })
        .catch(() => {
          requestAnimationFrame(() => {
            this.$refs.form.reset();
          });
        })
        .finally(() => {
          this.isButtonEnabled = true;
        });
    },
  },
};
</script>
