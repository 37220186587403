export default class BaseModel {
  static fromGQL(obj) {
    let instance = new this();
    for (let instanceKey in obj) {
      if (Object.hasOwn(instance, instanceKey) || instanceKey in instance) {
        instance[instanceKey] = obj[instanceKey];
      }
    }
    return instance;
  }

  toGQL() {
    let obj = {};
    for (let instanceKey in this) {
      if (Object.hasOwn(this, instanceKey)) {
        obj[instanceKey] = this[instanceKey];
      }
    }
    return obj;
  }
}
