<template>
  <b-row align-v="center">
    <b-img center fluid :src="require('../assets/_LoadingAnim.gif')" alt=""></b-img>
  </b-row>
</template>

<script>
export default {
  name: 'BaseLoadingIcon',
};
</script>

<style scoped></style>