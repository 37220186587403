<template>
  <BaseContainer>
    <template #base-container-header>
      <slot name="base-container-header" />
    </template>

    <b-row>
      <slot name="container-header" />
    </b-row>

    <b-row>
      <b-col :id="`breadcrumb-${_uid}`" class="blue-text">
        <slot name="breadcrumb">
          <!-- breadcrumb slot -->
        </slot>
      </b-col>

      <b-col :id="`header-${_uid}`" class="panel-header" cols="6">
        <slot name="header">
          {{ header }}
        </slot>
      </b-col>

      <b-col>
        <slot name="top-right">
          <!-- top right corner of panel -->
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :id="`title-${_uid}`" class="panel-title">
        <h1>
          <slot name="title">
            {{ title }}
          </slot>
        </h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col :id="`subtitle-${_uid}`">
        <slot name="subtitle" />
      </b-col>
    </b-row>

    <b-row v-if="subheading || !!$slots['subheading']">
      <b-col :id="`subheading-${_uid}`" :class="`text-${subheadingAlignment}`">
        <hr />
        <slot name="subheading">
          {{ subheading }}
        </slot>
        <slot name="subheadingList">
          <ul v-if="subheadingList" class="pl-0 subheading-list">
            <li v-for="(item, index) in subheadingList" :key="index">{{ item }}</li>
          </ul>
        </slot>
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col :id="`info-${_uid}`" class="text-center">
        <slot name="info">
          {{ info }}
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :id="`errors-${_uid}`" class="text-center">
        <slot name="errors">
          {{ errors }}
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :id="`content-${_uid}`" class="panel-content my-3">
        <slot> -- no content yet -- </slot>
      </b-col>
    </b-row>

    <b-row :id="`buttons-${_uid}`">
      <slot name="buttons">
        <!-- action buttons -->
      </slot>
    </b-row>

    <b-row>
      <slot name="footerMessage">
        {{ footerMessage }}
      </slot>
    </b-row>

    <b-row>
      <b-col :id="`footer-${_uid}`">
        <slot name="footer">
          {{ footer }}
        </slot>
      </b-col>
    </b-row>
  </BaseContainer>
</template>

<script>
export default {
  name: 'BasePanel',
  props: {
    breadcrumbLabel: {
      type: String,
      default: '',
    },
    breadcrumbLink: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      default: '',
    },
    subheadingList: {
      type: Array,
      default: null,
    },
    subheadingAlignment: {
      type: String,
      default: 'center',
    },
    info: {
      type: String,
      default: '',
    },
    errors: {
      type: [String, Object],
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
    buttons: {
      type: Object,
      default: null,
    },
    footer: {
      type: String,
      default: '',
    },
    footerMessage: {
      type: String,
      default: '',
    },
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>

//move these to css repo
<style lang="scss" scoped>
.panel-title {
  font-weight: bolder;
  text-align: center;
}

.panel-header {
  text-align: center;
}

.panel-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.custom-form-element {
  margin-bottom: 1rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.subheading-list {
  list-style-type: none;
  li {
    position: relative;
    padding-left: 10px;
    &:before {
      content: '*';
      position: absolute;
      left: 0;
      bottom: -3px;
    }
  }
}
</style>
