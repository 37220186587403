<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="onSubmit">
      <BaseRadioSwitch
        v-if="!petHasALicense"
        v-model="form.type"
        name="pet-species"
        :label="$t('dp.petPanel.whatKindOfPet')"
        :options="[
          { text: $t('dp.generic.dog'), value: 'D' },
          { text: $t('dp.generic.cat'), value: 'C' },
        ]"
        validation-rules="required"
        @change="onTypeChange"
      />
      <div v-else class="text-dark">
        Type:
        <span class="font-weight-bolder">{{ pet.type === 'DOG' ? $t('dp.generic.dog') : $t('dp.generic.cat') }}</span>
      </div>

      <span v-if="form.type === 'C'">
        <ValidationProvider v-slot="{ invalid: catTypeInvalid }" rules="required">
          <b-form-group id="pet-type-group" :label="$t('dp.petPanel.chooseOne')" label-for="pet-type-input">
            <b-form-radio-group
              id="pet-type-input"
              v-model="form.isIndoor"
              name="pet-type-radios"
              :options="petTypeOptions"
              aria-describedby="pet-type-feedback"
              stacked
            >
              <div id="pet-type-feedback" class="text-danger" role="alert">
                <small>
                  {{ $t(catTypeInvalid ? 'dp.petPanel.petCatOptionsRequired' : '', { catName: form.name || 'your cat' }) }}
                </small>
              </div>
            </b-form-radio-group>
          </b-form-group>
        </ValidationProvider>
      </span>

      <div class="mt-3">
        <DpBaseInput
          id="pet-name"
          v-model.trim="form.name"
          name="pet-name"
          type="text"
          :label="$t('dp.petPanel.petsName')"
          :placeholder="$t('dp.petPanel.namePlaceholder')"
          :validation-rules="{
            required: true,
            allowedSpecialChar: true,
            max: 50,
            petNameExists: { existingPetNames: existingPetsArr },
            inactivePetNames: { inactivePetNames: inactivePetNames, customerSupportPhone: customerSupportPhone },
            duplicatePet: { name: duplicatePetName, message: duplicatePetMessage },
          }"
          @focus="showPetNameEditWarning = true"
        />
        <b-alert v-if="mode === 'edit' && showPetNameWarning"
                 class="mt-3"
                 show
                 variant="info"
        >
          {{ $t('dp.petPanel.petNameEditWarning') }}
        </b-alert>
      </div>

      <div v-if="!petTypeIsRestrictedBreed && !petHasALicense" class="mt-3">
        <vue-simple-suggest
          id="breed-suggestions"
          ref="breedSuggester"
          v-model="form.breed"
          name="pet-breed"
          type="text"
          :filter-by-query="true"
          :list="breeds"
          :styles="autoCompleteStyle"
          :destyled="true"
          @select="selectedBreed"
        >
          <DpBaseInput
            id="pet-breed"
            v-model="form.breed"
            name="pet-breed"
            type="text"
            :label="`${$t('dp.petPanel.whatBreed')}`"
            :placeholder="$t('dp.petPanel.breedPlaceholder')"
            :validation-rules="{ required: true, max: 255 }"
            autocomplete="off"
          />
        </vue-simple-suggest>
      </div>
      <div v-else class="text-dark mt-3">
        Breed:
        <span class="font-weight-bolder">{{ form.breed }}</span>
      </div>

      <div v-if="!petHasALicense" class="mt-3">
        <BaseRadioSwitch
          v-model="form.gender"
          name="pet-sex"
          :label="`${$t('dp.petPanel.whatGender')}`"
          :options="[
            { text: $t('dp.generic.male'), value: 'M' },
            { text: $t('dp.generic.female'), value: 'F' },
          ]"
          validation-rules="required"
        />
      </div>
      <div v-else class="text-dark mt-3">
        Sex:
        <span class="font-weight-bolder">{{ form.gender === 'M' ? $t('dp.generic.male') : $t('dp.generic.female') }}</span>
      </div>

      <!--   Spay/Neutered   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <BaseRadioSwitch
          v-if="!petHasALicense && !petHasNeuterProof"
          v-model="editForm.neutered"
          name="pet-neutered"
          :label="'Spay/Neutered'"
          :options="[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ]"
        />
        <div v-else class="text-dark">
          Spay/Neutered:
          <span class="font-weight-bolder">{{ isNeutered }}</span>
        </div>
      </div>

      <!--   Microchip Number   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseInput
          id="pet-microchip"
          v-model="editForm.microchip"
          name="pet-microchip"
          type="text"
          :label="'Microchip Number'"
          :validation-rules="{ required: false, max: 255 }"
        />
      </div>

      <!--   Tattoo #   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseInput
          id="pet-tattoo"
          v-model="editForm.tattoo"
          name="pet-tattoo"
          type="text"
          :label="'Tattoo #'"
          :validation-rules="{ required: false, max: 255 }"
        />
      </div>

      <div v-if="!petHasALicense" class="mt-3">
        <BaseRadioSwitch
          v-model="form.dobIsApproximate"
          name="pet-age"
          :label="`${$t('dp.petPanel.knowBirthDate')}`"
          :options="[
            { value: false, text: $t('dp.generic.yes') },
            { value: true, text: $t('dp.generic.no') },
          ]"
          validation-rules="required"
        />
      </div>

      <div v-if="form.dobIsApproximate === false">
        <div v-if="!petHasALicense" class="mt-3">
          <dp-date-input
            v-model="form.birthdate"
            :label="`${$t('dp.petPanel.birthdate')}`"
            :original-date="mode === 'edit' ? form.birthdate : null"
            :validate-on-load="mode === 'edit' ? true : null"
            :date-required="form.dobIsApproximate === false"
            date-type="birthday"
            @existingDateError="setExistingError"
          />
        </div>
        <div v-else-if="pet && pet.birthday" class="text-dark">
          Birthdate:
          <span class="font-weight-bolder">{{ `${$t(displayBirthday.month)} ${displayBirthday.day}, ${displayBirthday.year}` }}</span>
        </div>
      </div>
      <div v-if="form.dobIsApproximate">
        <div v-if="!petHasALicense" class="mt-3">
          <ValidationProvider
            v-slot="{ errors, valid, validated, dirty }"
            ref="validationProvider"
            name="birthdate-approximate"
            :rules="form.dobIsApproximate ? 'required' : null"
          >
            <b-form-group id="birthdate-approximate-group" :label="$t('dp.petPanel.approximateAge')" label-for="birthdate-approximate">
              <b-form-select
                id="birthdate-approximate"
                v-model="age"
                name="birthdate-approximate"
                :options="[
                  { value: null, text: '' },
                  { value: 3, text: $t('dp.ageOptions.underSixMonths') },
                  { value: 9, text: $t('dp.ageOptions.sixMonthsToOneYear') },
                  { value: 12, text: $t('dp.ageOptions.oneYear') },
                  ...Array.from({ length: 15 }, (_, i) => {
                    return { value: (i + 2) * 12, text: $t('dp.ageOptions.timesYears', { yearNumber: i + 2 }) };
                  }),
                  { value: 17 * 12, text: $t('dp.ageOptions.seventeenPlusYears') },
                ]"
                v-bind="$attrs"
                :class="errors[0] ? 'is-invalid' : ''"
                :state="dirty || validated ? valid : null"
                aria-describedby="birthdate-invalid-feedback"
                autocomplete="off"
                v-on="$listeners"
              />
              <b-form-invalid-feedback id="birthdate-invalid-feedback" role="alert" v-html="errors[0]" />
            </b-form-group>
          </ValidationProvider>
        </div>
        <div v-else class="text-dark">
          Approximate Age:
          <span class="font-weight-bolder">
            <span v-if="age === 3">{{ $t('dp.ageOptions.underSixMonths') }}</span>
            <span v-else-if="age === 9">{{ $t('dp.ageOptions.sixMonthsToOneYear') }}</span>
            <span v-else-if="age === 12">{{ $t('dp.ageOptions.oneYear') }}</span>
            <span v-else-if="age === 17 * 12">{{ $t('dp.ageOptions.seventeenPlusYears') }}</span>
            <span v-else>{{ $t('dp.ageOptions.timesYears', { yearNumber: Math.floor(age / 12) }) }}</span>
          </span>
        </div>
      </div>

      <!--   Identifying Markings #   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseInput
          id="pet-markings"
          v-model="editForm.markings"
          name="pet-markings"
          type="text"
          :label="'Identifying Markings'"
          :validation-rules="{ required: false, max: 255 }"
        />
      </div>

      <!--   Coat   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseInput
          id="pet-coat"
          v-model="editForm.coat"
          name="pet-coat"
          type="text"
          :label="'Coat'"
          :validation-rules="{ required: false, max: 255 }"
        />
      </div>

      <div class="mt-3">
        <ValidationProvider v-slot="{ errors, valid, validated, dirty }" ref="validationProvider" name="color-picker" rules="required">
          <color-picker
            ref="colorPicker"
            v-model="form.colors"
            aria-describedby="color-invalid-feedback"
            :label="`${$t('dp.petPanel.whatColor')}`"
            :class="errors[0] ? 'is-invalid' : ''"
            :state="dirty || validated ? valid : null"
            :colors="[
              { id: 1, name: $t('dp.colors.white'), hex: '#FFFBF5' },
              { id: 2, name: $t('dp.colors.lightGrey'), hex: '#CCCCCC' },
              { id: 3, name: $t('dp.colors.darkGrey'), hex: '#888888' },
              { id: 4, name: $t('dp.colors.black'), hex: '#1A1A1A' },
              { id: 5, name: $t('dp.colors.orange'), hex: '#DE8F43' },
              { id: 6, name: $t('dp.colors.red'), hex: '#B56839' },
              { id: 7, name: $t('dp.colors.cream'), hex: '#F7EEDB' },
              { id: 8, name: $t('dp.colors.apricot'), hex: '#F0D6A0' },
              { id: 9, name: $t('dp.colors.golden'), hex: '#EABE66' },
              { id: 10, name: $t('dp.colors.tan'), hex: '#C39956' },
              { id: 11, name: $t('dp.colors.brown'), hex: '#805A26' },
              { id: 12, name: $t('dp.colors.darkBrown'), hex: '#5C411D' },
            ]"
          />
          <b-form-invalid-feedback id="color-invalid-feedback" role="alert" v-html="errors[0]" />
        </ValidationProvider>
      </div>

      <!--   Biography   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseTextArea
          v-model="editForm.biography"
          name="pet-biography"
          :rows="5"
          :max-rows="10"
          :label="'Pet Biography'"
          :validation-rules="{ required: false, max: 65535 }"
        />
      </div>

      <!--   Additional Notes   -->
      <div v-if="mode === 'edit'" class="mt-3">
        <DpBaseTextArea
          v-model="editForm.notes"
          name="pet-notes"
          :rows="5"
          :max-rows="10"
          :label="'Additional Notes'"
          :validation-rules="{ required: false, max: 65535 }"
        />
      </div>

      <br />
      <slot></slot>
      <b-button block type="submit" :disabled="isDisabled" variant="primary">
        {{ continueButtonLabel ? continueButtonLabel : $t('dp.generic.continue') }}
      </b-button>
    </form>
  </ValidationObserver>
</template>

<script>
import ColorPicker from './SubComponents/ColorPicker';
import VueSimpleSuggest from 'vue-simple-suggest';
import DpBaseInput from '../BaseComponents/BaseInput/BaseInput';
import DpBaseTextArea from '../BaseComponents/BaseTextArea/BaseTextArea';
import BaseRadioSwitch from './SubComponents/BaseRadioSwitch';
import DpDateInput from '../DateInput/DateInput';
import { FocusableElementMixin } from '../../mixins/FocusableElementMixin';

export default {
  name: 'AddAPet',
  components: {
    ColorPicker,
    VueSimpleSuggest,
    DpBaseInput,
    BaseRadioSwitch,
    DpDateInput,
    DpBaseTextArea,
  },
  mixins: [FocusableElementMixin],
  props: {
    pet: {
      type: Object,
      required: false,
      default: null,
    },
    duplicatePetName: {
      type: String,
      required: false,
      default: '',
    },
    duplicatePetMessage: {
      type: String,
      required: false,
      default: '',
    },
    existingName: {
      type: String,
      required: false,
      default: '',
    },
    existingPetNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    inactivePetNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    allBreeds: {
      type: Object,
      required: true,
      default: null,
    },
    continueButtonLabel: {
      type: String,
      required: false,
      default: '',
    },
    customerSupportPhone: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      mode: 'add',
      form: {
        type: '',
        isIndoor: null,
        name: '',
        breed: '',
        gender: '',
        birthdate: {
          day: null,
          month: null,
          year: null,
        },
        dobIsApproximate: null,
        colors: [],
      },
      editForm: {
        neutered: null,
        microchip: '',
        tattoo: '',
        markings: '',
        coat: '',
        biography: '',
        notes: '',
      },
      breedPickedFromDropdown: null,
      age: '',
      autoCompleteStyle: {
        suggestions: 'custom-suggestions',
        suggestItem: 'custom-suggestion-item',
      },
      showPetNameWarning: false,
      genericErrorExists: false,
      petTypeOptions: [
        { text: this.$i18n.t('dp.petPanel.indoorCat'), value: true },
        { text: this.$i18n.t('dp.petPanel.outdoorCat'), value: false },
      ],
    };
  },
  computed: {
    showPetNameEditWarning: {
      get: function () {
        return this.showPetNameWarning
      },
      set: function (newValue) {
        if (this.mode === 'edit') {
          this.showPetNameWarning = newValue
        }
      }
    },
    displayBirthday() {
      if (this.pet && this.pet.birthday) {
        let day = this.pet.birthday.day;
        let year = this.pet.birthday.year;
        let monthOptions = [
          'dp.input.dates.monthOptions.jan',
          'dp.input.dates.monthOptions.feb',
          'dp.input.dates.monthOptions.mar',
          'dp.input.dates.monthOptions.apr',
          'dp.input.dates.monthOptions.may',
          'dp.input.dates.monthOptions.jun',
          'dp.input.dates.monthOptions.jul',
          'dp.input.dates.monthOptions.aug',
          'dp.input.dates.monthOptions.sept',
          'dp.input.dates.monthOptions.oct',
          'dp.input.dates.monthOptions.nov',
          'dp.input.dates.monthOptions.dec',
        ];
        return { month: monthOptions[this.pet.birthday.month - 1], day: day, year: year };
      }
      return null;
    },
    existingPetsArr() {
      return this.existingPetNames ? [this.existingName, ...this.existingPetNames] : [this.existingName];
    },
    breeds() {
      if (this.form.type === 'D' && this.allBreeds.dogBreeds) {
        return this.allBreeds.dogBreeds;
      } else if (this.form.type === 'C' && this.allBreeds.catBreeds) {
        return this.allBreeds.catBreeds;
      }
      return [...this.allBreeds.dogBreeds, ...this.allBreeds.catBreeds];
    },
    petHasALicense() {
      return this.pet && this.pet.licenses && this.pet.licenses.length;
    },
    petTypeIsRestrictedBreed() {
      return this.pet && this.pet.restrictedBreed;
    },
    petHasNeuterProof() {
      return this.pet && this.pet.hasValidNeuterProof;
    },
    isNeutered() {
      return this.pet?.details?.neutered ? this.$t('dp.generic.yes') : this.$t('dp.generic.no');
    },
    isDisabled() {
      return this.form.name
        ? [this.existingName, ...this.existingPetNames, ...this.inactivePetNames]
            .map(name => name.toLowerCase())
            .includes(this.form.name.toLowerCase()) || this.isDuplicatePet
        : false;
    },
    isDuplicatePet() {
      return this.duplicatePetName.toLowerCase() === this.form.name.toLowerCase();
    },
  },
  watch: {
    'form.breed': function(val) {
      if (!this.breedPickedFromDropdown || val.toLowerCase() !== this.breedPickedFromDropdown.toLowerCase()) {
        this.$nextTick(() => {
          this.$refs.breedSuggester.getSuggestions();
          this.$refs.breedSuggester.showList();
        });
      }
    },
    'form.type': function(val, old) {
      if (val === 'C' && old === 'D') {
        this.form.isIndoor = null;
      } else {
        this.form.isIndoor = this.pet?.indoorAnimal ?? null;
      }
    },
  },
  mounted() {
    // Resolve vue-simple-suggest accessibility issues
    const breedSuggesterInput = this.$refs?.breedSuggester?.$refs?.inputSlot;

    breedSuggesterInput?.setAttribute('aria-label', `${this.$t('dp.petPanel.whatBreed').toString()} Suggester`);
    breedSuggesterInput?.setAttribute('id', 'suggested-breed-combobox');
    // If pet is passed as a prop we are editing a pet.
    this.hydratePetForm();
  },
  methods: {
    setExistingError(val) {
      this.genericErrorExists = val;
    },
    onSubmit() {
      //If a generic error exists don't submit the form.
      if (this.genericErrorExists) {
        return;
      }

      this.$refs.form.validate().then(async success => {
        if (this.form.dobIsApproximate) {
          const subtractAgeFromNow = new Date();
          subtractAgeFromNow.setMonth(subtractAgeFromNow.getMonth() - this.age);

          this.form.birthdate = {
            day: subtractAgeFromNow.getUTCDate(),
            month: subtractAgeFromNow.getUTCMonth() + 1,
            year: subtractAgeFromNow.getUTCFullYear(),
          };
        }

        if (success) {
          if (this.mode === 'add') {
            let formattedForm = JSON.parse(JSON.stringify(this.form));
            formattedForm.isIndoor = Boolean(formattedForm.isIndoor);
            formattedForm.birthday = formattedForm.birthdate;
            delete formattedForm.birthdate;
            this.$emit('addPet', formattedForm);
          } else {
            let formToSubmit = { ...this.form, ...this.editForm };

            //EDIT PET API TAKES SLIGHTLY DIFFERENT ATTRIBUTE NAMES
            if (formToSubmit.type === 'D') {
              formToSubmit.type = 'DOG';
            } else if (formToSubmit.type === 'C') {
              formToSubmit.type = 'CAT';
            } else {
              formToSubmit.type = 'UNKNOWN';
            }
            if (formToSubmit.gender === 'M') {
              formToSubmit.gender = 'MALE';
            } else {
              formToSubmit.gender = 'FEMALE';
            }
            formToSubmit.color = formToSubmit.colors;
            delete formToSubmit.colors;
            formToSubmit.indoorAnimal = Boolean(formToSubmit.isIndoor);
            delete formToSubmit.isIndoor;
            formToSubmit.birthday = formToSubmit.birthdate;
            delete formToSubmit.birthdate;
            if (formToSubmit.neutered === null) {
              delete formToSubmit.neutered;
            }

            this.$emit('editPet', {
              petFormData: formToSubmit,
              petId: this.pet.id,
            });
          }
        } else {
          this.scrollToError(this.$refs.form);
        }
      })
      .catch(() => {
         this.scrollToError(this.$refs.form);
      });
    },
    onTypeChange() {
      this.form.breed = '';
    },
    selectedBreed(selected) {
      this.breedPickedFromDropdown = selected;
    },
    hydratePetForm() {
      if (!this.pet) {
        return;
      }

      this.mode = 'edit';
      this.form.name = this.pet.name ?? '';
      this.form.isIndoor = this.pet.indoorAnimal ?? null;
      this.form.dobIsApproximate = this.pet.dobIsApproximate ?? null;

      if (!this.pet.details) {
        return;
      }

      this.form.breed = this.pet.details.breed ?? '';
      this.form.type = this.pet.details.type?.[0]?.toUpperCase() ?? '';
      this.form.gender = this.pet.details.gender?.[0]?.toUpperCase() ?? '';
      this.form.birthdate = this.pet.details.birthday ?? {};
      if (this.pet.dobIsApproximate) {
        this.age = this.pet.details.age?.months ?? null;
      }
      this.form.colors = this.pet.details.color;
      this.pet.details.color?.forEach(color => {
        this.$nextTick(() => {
          this.$refs.colorPicker.$refs?.[color]?.[0]?.$el?.click();
        });
      });
      this.editForm.neutered = this.pet.details.neutered ?? null;
      this.editForm.microchip = this.pet.details.microchip ? this.pet.details.microchip : '';
      this.editForm.tattoo = this.pet.details.tattoo ? this.pet.details.tattoo : '';
      this.editForm.markings = this.pet.details.markings ? this.pet.details.markings : '';
      this.editForm.coat = this.pet.details.coat ? this.pet.details.coat : '';
      this.editForm.biography = this.pet.details.biography ? this.pet.details.biography : '';
      this.editForm.notes = this.pet.details.notes ? this.pet.details.notes : '';
    },
  },
};
</script>

<style lang="scss">
@import '../../assets/styles/_variables';
//SimpleSuggest styles override Bootstrap validation styles. In order to get the two to work together we need to block existing and add new simple suggest styles...
.custom-suggestions {
  border-radius: 5px;
  border: 1px solid #aaa;
  box-shadow: 8px 8px 8px grey;
  z-index: 1000;
}
.custom-suggestion-item {
  border-bottom: 1px solid #aaa;
  padding: 0.5em;
  cursor: pointer;
}
.custom-suggestion-item.selected {
  background-color: $dp-navy;
  color: $dp-white;
}
.custom-suggestion-item.hover {
  background-color: $dp-blue;
  color: $dp-white;
}
//End simple suggest custom styles.

.age-select {
  width: 70px;
}
.custom-radio {
  margin-left: 20px;
}
</style>
