import { DpAxiosREST } from '../../index';

const IMAGE_CONVERSION_ENDPOINT = '/api/image-conversions';

export const WHITELIST = ['image/bmp', 'image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml', 'image/webp'];
export const GREYLIST = ['image/heic', 'image/heif', 'image/tiff', 'image/tif']; // needs backend image conversion service
export const MAX_SIZE = 10000000; // 10MB

export const ImageConversionMixin = {
  data() {
    return {
      imageConversion: {
        settings: {
          whitelist: WHITELIST,
          greylist: GREYLIST,
          maxSize: MAX_SIZE,
        },
        loading: false,
        error: false,
        errorMessage: 'dp.imageConversion.errors.default',
      },
    };
  },
  methods: {
    async urlToBlob(url) {
      try {
        const response = await fetch(url);
        return await response.blob();
      } catch (error) {
        this.imageConversion.error = true;
        return null;
      }
    },
    responseToBlob(httpResponse) {
      if (!httpResponse || !httpResponse.data || !httpResponse.data.type || !httpResponse.data.content) {
        throw new Error('invalid httpResponse');
      }

      const contentType = httpResponse.data.type;
      const base64Content = httpResponse.data.content;

      const binaryContent = atob(base64Content);
      const uint8Content = new Uint8Array(binaryContent.length);
      for (let i = 0; i < binaryContent.length; i++) {
        uint8Content[i] = binaryContent.charCodeAt(i);
      }

      return new Blob([uint8Content], { type: contentType });
    },
    blobToObjectURL(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      return URL.createObjectURL(blob);
    },
    blobNeedsConversion(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      return this.imageConversion.settings.greylist.includes(blob.type);
    },
    blobIsTooLarge(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      return blob.size > this.imageConversion.settings.maxSize;
    },
    blobIsInvalidType(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      return ![...this.imageConversion.settings.whitelist, ...this.imageConversion.settings.greylist].includes(blob.type);
    },
    validateBlob(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      if (this.blobIsTooLarge(blob)) {
        this.imageConversion.error = true;
        this.imageConversion.errorMessage = 'dp.imageConversion.errors.tooLarge';
        return false;
      }
      if (this.blobIsInvalidType(blob)) {
        this.imageConversion.error = true;
        this.imageConversion.errorMessage = 'dp.imageConversion.errors.invalidType';
        return false;
      }
      return true;
    },
    async convertBlob(blob) {
      if (!(blob instanceof Blob)) {
        throw new Error('expected instance of Blob');
      }

      if (!this.validateBlob(blob) || !this.blobNeedsConversion(blob)) {
        throw new Error('invalid blob');
      }

      this.imageConversion.loading = true;
      const formData = new FormData();
      formData.append('image', blob);

      try {
        const response = await DpAxiosREST.post(IMAGE_CONVERSION_ENDPOINT, formData);
        this.imageConversion.error = false;
        if (response.data && response.data.type !== 'image/jpeg') {
          this.imageConversion.error = true;
          this.imageConversion.errorMessage = 'dp.imageConversion.errors.inconvertible';
          return response;
        }
        return this.blobToObjectURL(this.responseToBlob(response));
      } catch (error) {
        this.imageConversion.error = true;
        this.imageConversion.errorMessage = 'dp.imageConversion.errors.default';
        throw error;
      } finally {
        this.imageConversion.loading = false;
      }
    },
  },
};
