<template>
  <div class="bg-light-blue-alt px-3 pt-2 pb-2 mb-3 rounded">
    <h3 class="m-0 d-flex">
      <div class="pt-1">
        <slot name="icon"></slot>
      </div>
      <div class="mx-2">
        <slot name="message"></slot>
      </div>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'DpProductMessaging',
}
</script>

<style lang="scss" scoped>
div {
  .bg-light-blue-alt {
    background-color: #F0F4FF;
  }
}
</style>