import { gql } from '../../utils/GraphQLUtil';
import { petTrackingDeviceAddToOrderContext } from '../gqlFields/petTrackingDeviceAddToOrder.fields';

export const addPetTrackingDeviceToOrder = gql`
    mutation AddPetTrackingDeviceToOrder($input: AddPetTrackingDeviceToOrderInput!) {
        addPetTrackingDeviceToOrder(input: $input) {
            ${petTrackingDeviceAddToOrderContext}
        }
    }
`;

export const removePetTrackingDeviceToOrder = gql`
  mutation removePetTrackingDeviceToOrder($itemId: ID!) {
    removePetTrackingDeviceToOrder(orderItemId: $itemId) {
        ${petTrackingDeviceAddToOrderContext}
    }
  }
`;
