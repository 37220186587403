import axios from 'axios';
import { DEFAULT_TIMEOUT } from '../axios';
import getPetTrackingDeviceAddToOrderContextQuery from './../query/getPetTrackingDeviceAddToOrderContext.query';
import PetTrackingDeviceAddToOrderContext from './../../models/PetTrackingDeviceAddToOrderContext';
import { addPetTrackingDeviceToOrder, removePetTrackingDeviceToOrder } from './../../api/mutation/petTrackingDeviceToOrder.mutation';

export default class PetTrackingDeviceAddToOrderApiService {
  constructor(apiUrl, apiKey) {
    this.axios = new axios.create({
      baseURL: apiUrl,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        common: {
          Authorization: apiKey,
        },
      },
    });
  }

  async queryPetTrackingDeviceAddToOrderContext() {
    const response = await this.axios.post('/graphql/', {
      query: getPetTrackingDeviceAddToOrderContextQuery,
    });
    if (response.data && response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else if (!response.data || !response.data.data) {
      return new PetTrackingDeviceAddToOrderContext({}); // Consider returning null or a default value
    }

    const petTrackingDeviceAddToOrderContextData = response.data.data.petTrackingDeviceAddToOrderContext;

    return new PetTrackingDeviceAddToOrderContext({
      ...(petTrackingDeviceAddToOrderContextData || {}),
    });
  }

  async mutationAddPetTrackingDeviceToOrder(petId, productId) {
    const response = await this.axios.post('/graphql/', {
      query: addPetTrackingDeviceToOrder,
      variables: {
        input: {
          products: [
            {
              productId: productId,
              petId: petId,
            },
          ],
        },
      },
    });

    if (response.data && response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else if (!response.data || !response.data.data) {
      return new PetTrackingDeviceAddToOrderContext({}); // Consider returning null or a default value
    }

    const addPetTrackingDeviceToOrderData = response.data.data.addPetTrackingDeviceToOrder;

    return new PetTrackingDeviceAddToOrderContext({
      ...(addPetTrackingDeviceToOrderData || {}),
    });
  }

  async mutationRemovePetTrackingDeviceFromOrder(ItemId) {
    const response = await this.axios.post('/graphql/', {
      query: removePetTrackingDeviceToOrder,
      variables: {
        itemId: ItemId,
      },
    });
    if (response.data && response.data.errors) {
      throw new Error(response.data.errors[0].message);
    } else if (!response.data || !response.data.data) {
      return new PetTrackingDeviceAddToOrderContext({}); // Consider returning null or a default value
    }

    const removePetTrackingDeviceToOrderData = response.data.data.removePetTrackingDeviceToOrder;

    return new PetTrackingDeviceAddToOrderContext({
      ...(removePetTrackingDeviceToOrderData || {}),
    });
  }
}
