import { isEmpty } from 'lodash';
import PasswordService from '@/api/services/PasswordService';

export const namespaced = true;

export const state = {
  error: null,
  loading: false,
  response: null,
  _attempts: {
    count: 1,
  },
};

export const getters = {
  getErrors: ({ error }) => error,
  isLoading: ({ loading }) => loading,
  _attemptCount: (state) => state._attempts.count,
  hasErrors: ({ loading, error }) => !loading && !isEmpty(error),
  isSuccess: ({ response, hasErrors }) => !hasErrors && !isEmpty(response),
};

export const mutations = {
  LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SUCCESS(state, response) {
    state.error = null;
    state.loading = false;
    state.response = response;
    state._attempts.count = 1;
  },
  FAILURE(state, error) {
    state.error = error;
    state.loading = false;
    state._attempts.count++;
  },
  CLEAR(state) {
    state.error = null;
    state.loading = false;
    state.response = null;
    state._attempts.count = 1;
  },
};

export const actions = {
  doForgotPassword: ({ commit }, email) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      PasswordService.doForgotPassword(email)
        .then((response) => {
          commit('SUCCESS', response);
          resolve();
        })
        .catch((error) => {
          commit('FAILURE', error);
          reject(error);
        });
    }),
  doPasswordChange: ({ commit }, payload) =>
    new Promise((resolve, reject) => {
      commit('LOADING', true);
      PasswordService.doPasswordChange(payload)
        .then((response) => {
          commit('SUCCESS', response);
          resolve(response?.email);
        })
        .catch((error) => {
          commit('FAILURE', error);
          reject(error);
        });
    }),
};
