import { render, staticRenderFns } from "./BaseFileUpload.vue?vue&type=template&id=366e1844&"
import script from "./BaseFileUpload.vue?vue&type=script&lang=js&"
export * from "./BaseFileUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../log-in-vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports