<template>
  <transition name="slide-fade">
    <i18n
      v-if="getMesssage.path || noPathTranslate"
      :path="noPathTranslate ? getMesssage : getMesssage.path"
      tag="div"
      v-bind="$attrs"
      :class="cssClass"
    >
      <template #br>
        <br />
      </template>
      <template #action>
        <b-link :to="getMesssage.action.to" class="text-decoration-underline">{{ getMesssage.action.text }}</b-link>
      </template>
      <template #DocuPet>
        <strong class="text-secondary">{{ 'text.bold.DocuPet' | translate }}</strong>
      </template>
      <template #HomeSafe>
        <span>Home<span class="font-italic">Safe<sup>&reg;</sup></span></span>
      </template>
      <template #getCityOrganization>
        {{ getMesssage.getCityOrganization }}
      </template>
    </i18n>
    <span v-else :class="cssClass" v-html="getMesssage" />
  </transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'BaseDisplayMessage',
  inheritAttrs: false,
  props: {
    message: {
      type: [String, Object],
      required: true,
    },
    noPathTranslate: {
      type: Boolean,
      default: false,
    },
    cssClass: {
      type: [String, Object],
      default: 'error-message',
    },
  },
  computed: {
    ...mapGetters('city', ['getCityCustomerSupportContext']),
    getMesssage: function () {
      if (this.message === 'contactCustomerCare') {
        return this.getCityCustomerSupportContext?.customCareSupportText?.text;
      }
      return this.message;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
