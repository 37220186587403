<template>
  <BasePanel :title="'storeLogin.title' | translate">
    <template #subtitle>
      <div class="text-center my-2 mb-n3">
        {{ 'storeLogin.subheading' | translate }}
      </div>
    </template>

    <b-link class="btn btn-primary w-100 mt-3" :to="{ name: 'SyfSignUp', query: { targetPath: getTargetPath, redirectPath: getRedirectPath, noLicensing: !!noLicensing } }">
      {{ 'storeLogin.buttonTextSignUp' | translate }}
    </b-link>

    <div class="separator d-flex justify-content-center mt-3">OR</div>

    <b-link class="btn btn-primary w-100 mt-3" :to="{ name: 'LogIn', query: { targetPath: getTargetPath, redirectPath: getRedirectPath } }">
      {{ 'storeLogin.buttonTextLogIn' | translate }}
    </b-link>

    <template #footer>
      <b-row align-h="center">
        <b-link :to="{ name: 'ForgotPassword', query: { redirect_path: getRedirectPath } }" v-text="$t('link.forgotPassword')" />
      </b-row>

      <div>
        <div>
          <b-card no-body class="mt-4 border-0">
            <div class="text-dark py-3 pr-3">
              <BaseDisplayMessage
                message="It's more than just a tag or tracker!"
                :no-path-translate="true"
                css-class="text-primary-navy font-weight-bold text-center h2"
              />
            </div>
          </b-card>
        </div>

        <div class="mb-n5">
          <b-card no-body class="border-0">
            <div class="text-dark py-3 pr-3" style="line-height: 1.3rem">
              <BaseDisplayMessage
                message="onlineAccount.heading"
                :no-path-translate="true"
                css-class="text-primary-navy font-weight-bold"
              />
              <ul class="mt-3 list-unstyled">
                <li v-for="(li, index) in $t('onlineAccount.content')" :key="`onlineAccount-${index}`" class="my-2 bg-white">
                  <BaseDisplayMessage :message="`onlineAccount.content[${index}]`" :no-path-translate="true" css-class="text-dark" />
                </li>
              </ul>
            </div>
          </b-card>
        </div>
      </div>
    </template>
  </BasePanel>
</template>

<script>

export default {
  name: 'StoreLogin',
  computed: {
    getTargetPath: function () {
      return this.$router?.currentRoute?.query?._target_path || '';
    },
    getRedirectPath: function () {
      return this.$router?.currentRoute?.query?.redirect_path || '';
    },
    noLicensing() {
      return this.$store.getters['city/noLicensing'];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@docupet/component-library/src/assets/styles/_variables';

ul li {
  background: url('~@/assets/Paw.png') no-repeat left center;
  padding-left: 2.5em;
  padding-top: 2px;
  padding-bottom: 3px;
}

.separator {
  color: $dp-gray-6;
  &::after,
  &::before {
    flex: 1;
    content: '';
    margin-bottom: 0.65rem;
    border-bottom: 0.15rem solid $dp-gray-3;
  }
  &::after {
    margin-left: 1rem;
  }
  &::before {
    margin-right: 1rem;
  }
}
</style>
