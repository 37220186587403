<template>
  <div id="app">
    <header>
      <DpNavbar :base-url="getBaseUrl || fallbackBaseUrl" :is-image-only-header="true" />
    </header>
    <main id="main" class="pt-4 pb-0">
      <router-view />
    </main>
    <DpFooter :base-url="getBaseUrl || fallbackBaseUrl" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DpNavbar, DpFooter } from '@docupet/component-library';

export default {
  name: 'App',
  components: {
    DpNavbar,
    DpFooter,
  },
  computed: {
    ...mapGetters(['getBaseUrl']),
    fallbackBaseUrl() {
      return window.location.origin;
    },
  },
  created() {
    this.$store.dispatch('city/fetchCity');
  },
};
</script>

<style lang="scss">
@import '~@docupet/component-library/src/assets/styles/main';

// Styles below needed in addition to global styles. Eventually we should do away with these and everything should live in global styles.
@media screen and (min-width: 768px) {
  body {
    background: linear-gradient(0deg, rgba(6, 3, 16, 0.4), rgba(0, 159, 223, 0.3)), url('~@/assets/background-3500px.jpg');
    background-size: 184%;
    background-position-y: 60px;
    background-position-x: center;
    background-repeat-x: no-repeat;
    overflow-x: hidden;
  }
}
.sub-header {
  margin: 20px 0;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid $dp-gray-3;
  border-bottom: 1px solid $dp-gray-3;
  p {
    color: $dp-gray-6;
    margin-bottom: 0;
  }
}
</style>
