// import en from 'vee-validate/dist/locale/en.json';
import { en_US } from '@docupet/component-library';

export default {
  ...en_US,
  login: {
    title: 'Log In',
    subheading: "Don't have an account?",
    buttonText: 'Log In',
    errorMessages: {
      default: 'Your email or password do not match. {br}Please try again.',
      session_expired: 'You were inactive for a while so your session has expired. Please log in again.',
    },
  },
  storeLogin: {
    title: 'Complete your order',
    subheading:
        "It's more than just a tag or tracker; it's your pet's ticket home. To maximize the benefits of our HomeSafe lost pet service, we need some essential information about you and your pet. Log In or Sign Up to complete your order.",
    buttonTextSignUp: 'Sign Up',
    buttonTextLogIn: 'Log In',
  },
  forgotPassword: {
    title: 'Forgot your password?',
    info: "No problem! We'll email you a link to reset your password so you can log into your account.",
    buttonText: 'Reset Password',
    onSuccess: {
      title: 'Almost Done!',
      info: "If you have an account, we'll send an email with instructions to reset your password.",
    },
  },
  resetPassword: {
    title: 'Reset Your Password',
    subheading: 'Almost done, create a new password below.',
    buttonText: 'Reset Password',
    password: {
      title: 'New Password',
    },
    passwordConfirm: {
      placeholder: 'Re-Enter password',
      title: 'Confirm New Password',
    },
    onSuccess: {
      title: 'Success!',
      infoText: 'Your password has been updated.',
      buttonText: 'Return to Log In',
    },
    errorMessages: {
      default: 'There was a problem with password reset.{br}Please try again.',
      currentPassword: 'New password cannot be same as current password',
      mismatch: 'Password confirmation does not match',
    },
  },
  completeActivation: {
    loading: {
      title: 'Fetching your Record',
      subheading: "You'll be able to create your online account very shortly",
    },
    title: 'Hi {firstName}!',
    subheading: 'Your secure online account can be used to…',
    subheadingList: [
      'License any pets',
      'Update the ownership status of any pets',
      'Update your current address and contact information',
      'And so much more!',
    ],
    legalText: {
      prepend: "By signing up, you agree to DocuPet's",
      links: { termsOfService: 'Terms of Service', conjunction: 'and', privacyPolicy: 'Privacy Policy' },
    },
    buttonText: 'Complete Sign Up',
    errorMessages: {
      access_code_used: "Looks like you've already signed up! {br} {action}",
      default: 'There was a problem with your complete sign-up.{br}Please try again.',
      action: {
        access_code_used: 'Click here to Log In',
      },
    },
  },
  fetchMyRecord: {
    title: 'Access my license record',
    subheading:
      'If you’ve licensed a pet with {getCityOrganization} before, we likely have your record on file — let’s look it up! If you’ve never licensed your pet with {getCityOrganization} before {action}.',
    buttonText: {
      primary: 'Search',
    },
    byAccessCode: {
      title: 'I received an access code via mail, phone, or email',
      subheading: 'I have an access code from a letter or email',
      buttonText: {
        secondary: "I will use my pet's tag number",
      },
      modal: {
        accessCode: {
          content:
            "When we get records from the {getCityOrganization} we send license renewal reminder with Access Codes via mail and email (if an email address has been provided). These unique codes can be used only if the contact information provided matches that of the original record. Once your access code has been used to successfully make a match with your account, you will proceed with creating credentials for your online account where you will find all details pertaining to your pet's license. ",
        },
      },
      errorMessages: {
        invalid_access_code: 'Incorrect Access code or Zip code. Please check your email or letter to confirm the correct information.',
        access_code_used: "Looks like you've already signed up! {br} {action}",
        default: 'There was problem fetching your record. Please try again.',
        action: {
          access_code_used: 'Click here to Log In',
        },
      },
    },
    byTagNumber: {
      title: 'Enter my pet’s tag number instead',
      subheading: "I have my pet's tag number",
      buttonText: {
        secondary: 'I will use my access code',
      },
      errorMessages: {
        invalid_tag_number:
          'Incorrect Tag number or Pet Name or Zip code or Phone number. Please check your email or letter to confirm the correct information.',
        access_code_used: "Looks like you've already signed up! {br} {action}",
        default: 'There was problem fetching your record. Please try again.',
        action: {
          access_code_used: 'Click here to Log In',
        },
      },
    },
  },
  requestAccessCode: {
    title: 'Request Access Code',
    subheading: 'We may be able to email a new access code to you. Enter your Email Address below.',
    buttonText: 'Continue',
    onSuccess: {
      title: 'Almost Done!',
      infoText:
        "We've sent an access code to {email} with instructions to access your account. If the email doesn't arrive soon, please check your spam folder",
      buttonText: 'Return to Fetch My Record',
    },
    errorMessages: {
      invalid_email: "We can't find this email address in our system. Please check to see that you're entering the correct email address.",
      access_code_used: "Looks like you've already signed up! {action}",
      default: 'There was problem sending an email. Please try again.',
      action: {
        access_code_used: 'Click here to Log In',
      },
    },
  },
  input: {
    email: {
      label: 'Email Address',
      placeholder: 'Enter email address',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
      buttonText: {
        show: 'Show',
        hide: 'Hide',
      },
    },
    createPassword: {
      label: 'Create a password',
      placeholder: 'Create a password',
    },
    newPassword: {
      label: 'New Password',
      placeholder: 'Create new password',
    },
    passwordConfirm: {
      label: 'Confirm New Password',
      placeholder: 'Re-enter new password',
    },
    accessCode: {
      label: 'Enter my 8 or 9 digit access code',
      placeholder: 'Enter access code (e.g. FAB12345)',
    },
    postalCode: {
      label: 'Zip Code Associated with Record',
      placeholder: 'Enter Zip code',
      errorMessages: 'The ZIP code is required',
    },
    tagNumber: {
      label: 'Tag Number',
      placeholder: 'Enter tag number (A1B27890)',
      aboveLabel: 'The tag number and two of the three following fields must match to be able access your license record.',
      errorMessages: {
        tag_number_required: 'Please enter exactly as seen on tag.',
      },
    },
    petName: {
      label: 'Pet Name',
      placeholder: 'Enter pet name',
    },
    phoneNumber: {
      label: 'Phone Number Associated with Record',
      placeholder: 'Enter phone number',
    },
  },
  checkbox: {
    eula: 'I agree',
  },
  link: {
    accessCode: {
      dontHave: "Don't have an access code?",
    },
    signUp: 'Sign Up',
    forgotPassword: 'Forgot your password?',
    login: {
      return: 'Return to Log In',
    },
    fetchMyRecord: {
      return: 'Return to Fetch My Record',
    },
  },
  validations: {
    messages: {
      required: 'req',
    },
  },
  footer: {
    copyright: 'DocuPet Corp. All rights reserved.',
    links: {
      about: 'About DocuPet',
      tos: 'Terms of Service',
      privacy: 'Privacy Policy',
    },
  },
  meta: {
    title: {
      LogIn: 'Log In',
      ResetPassword: 'Reset your password',
      ForgotPassword: 'Forgot your password?',
      CompleteSignup: 'Complete your sign-up',
      RequestAccessCode: 'Request access code',
      FetchMyRecordByPetTag: 'Fetch my record',
      FetchMyRecordByAccessCode: 'Fetch my record',
      FetchMyRecordByTagNumber: 'Fetch my record',
      StoreLogIn: 'Store Log In',
    },
  },
  CUSTOMER_CARE_PHONE: '1-877-239-6072',
};
