export const petTrackingDeviceAddToOrderContext = `  
    startNewOrderItemButtonText
    continueToCheckoutUrl
    continueButtonText
    deleteOrderItemButtonText
    submitNewOrderItemFormButtonText
    cancelNewOrderItemButtonText
    subscriptionOptions {
        label
        productId
        billed
        extras {
            text
            color
            backgroundColor
        }
    }
    petOptions {
        label
        value
    }
    devicesCurrentlyInBasket {
        petName
        orderItemId
        displayPrice
        productLabel
    }
    stepOneText
    stepTwoText
    selectPetText
    selectSubscriptionText
`;
