<template>
  <b-row v-if="!valid" class="mt-2">
    <b-col cols="12" class="my-0">
      <ul :id="id" class="list-unstyled my-0">
        <li v-for="rule in Object.keys(validationRules)" :key="rule" class="text-nowrap" :class="[getClassForRule(rule)]">
          <fa-icon :icon="[...getIconForRule(rule)]" />
          {{ getDisplayText(rule) }}
        </li>
      </ul>
    </b-col>
  </b-row>
  <b-row v-else class="my-2 mx-0 text-success">
    <b-col :id="id" cols="12" class="my-0 p-0">
      <fa-icon :icon="['far', 'check-circle']" size="lg" />
      {{ $t('dp.securePasswordCheckList.isSecure') }}
    </b-col>
  </b-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faGenderless, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

library.add(faCheckCircle, faGenderless, faTimesCircle);

export default {
  name: 'SecurePasswordCheckList',
  props: {
    id: {
      type: String,
      required: true,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    dirty: {
      type: Boolean,
      default: false,
    },
    failedRules: {
      type: Object,
      required: true,
    },
    additionalRules: {
      type: Object,
      default: null,
    },
  },
  computed: {
    validationRules() {
      const additionalRules = this.additionalRules || {};
      return {
        min: { displayText: this.$t('dp.securePasswordCheckList.min') },
        oneSpecialChar: { displayText: this.$t('dp.securePasswordCheckList.oneSpecialChar') },
        oneUpperChar: { displayText: this.$t('dp.securePasswordCheckList.oneUpperChar') },
        oneDigitChar: { displayText: this.$t('dp.securePasswordCheckList.oneDigitChar') },
        oneLowerChar: { displayText: this.$t('dp.securePasswordCheckList.oneLowerChar') },
        ...additionalRules,
      };
    },
  },
  methods: {
    getDisplayText(rule) {
      return this.validationRules[rule]?.displayText || '';
    },
    isRuleFailed(rule) {
      return this.dirty ? Object.prototype.hasOwnProperty.call(this.failedRules, rule) : null;
    },
    getClassForRule(rule) {
      const failed = this.isRuleFailed(rule);
      return failed === null ? 'text-dp-gray-6' : failed ? 'text-danger' : 'text-success';
    },
    getIconForRule(rule) {
      const failed = this.isRuleFailed(rule);
      return failed === null ? ['far', 'genderless'] : ['far', failed ? 'times-circle' : 'check-circle'];
    },
    chunkArray(array, size) {
      const chunkedArray = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArray.push(array.slice(i, i + size));
      }
      return chunkedArray;
    },
  },
};
</script>
