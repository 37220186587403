export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const inSentryTestingMode = () => {
  const queryParams = getQueryParams();
  if (queryParams && 'sentry-qa' in queryParams) {
    return queryParams['sentry-qa'];
  }
  return false;
};
