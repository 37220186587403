import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async sendAccessCodeEmail(email) {
    try {
      const query = gql`
        mutation emailAccessCode($email: String!) {
          emailAccessCode(email: $email)
        }
      `;
      const variables = {
        email,
      };
      const { emailAccessCode: results } = await axios.post('/', {
        query,
        variables,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
