import BaseModel from './BaseModel';

export default class AddressModel extends BaseModel {
  constructor(unit = '', civic = '', street = '', additional = '', cityName = '', province = '', country = '', postalCode = '', id = null) {
    super();

    /** @type {string} **/
    this.unit = unit;

    /** @type {string} **/
    this.civic = civic;

    /** @type {string} **/
    this.street = street;

    /** @type {string} **/
    this.additional = additional;

    /** @type {string} **/
    this.cityName = cityName;

    /** @type {string} **/
    this.province = province;

    if (province.length === 2 && country) {
      this.province = `${country}_${province}`;
    }

    if (country) {
      /** @type {string} **/
      this.country = country;
    } else if (province.length === 4) {
      /** @type {string} **/
      this.country = province.substring(0, 2);
    }

    /** @type {string} **/
    this.postalCode = postalCode;

    if (id) {
      /** @type {string|null} **/
      this.id = id;
    }
  }

  // Getter and setter for compatibility (will be removed in the future)
  /**
   * @deprecated will be removed soon in the future (use cityName instead)
   * @see cityName
   * @returns {string}
   */
  get city() {
    return this.cityName;
  }

  /**
   * @deprecated will be removed soon in the future (use cityName instead)
   * @see cityName
   */
  set city(value) {
    this.cityName = value;
  }

  /**
   * @deprecated will be removed soon in the future (use postalCode instead)
   * @see postalCode
   * @returns {string}
   */
  get postal() {
    return this.postalCode;
  }

  /**
   * @deprecated will be removed soon in the future (use postalCode instead)
   * @see postalCode
   */
  set postal(value) {
    this.postalCode = value;
  }
}
