import axios from '@/api/axios';
import { gql } from '@/api/utils';

export default {
  async doForgotPassword(email) {
    try {
      const query = gql`
        mutation forgotPassword($email: String!) {
          forgotPassword(email: $email)
        }
      `;
      const variables = {
        email,
      };
      const { forgotPassword: results } = await axios.post('/', {
        query,
        variables,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
  async doPasswordChange(payload) {
    try {
      const query = gql`
        mutation resetPasswordAndLogin($firstPassword: Password!, $secondPassword: Password!) {
          resetPasswordAndLogin(firstPassword: $firstPassword, secondPassword: $secondPassword) {
            email
          }
        }
      `;
      const { resetPasswordAndLogin: results } = await axios.post('/', {
        query,
        variables: payload,
      });
      return results;
    } catch (error) {
      return Promise.reject({ error });
    }
  },
};
